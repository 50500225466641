import React from 'react';
import { Flex, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { ChevronDownIcon, DeleteIcon } from '@chakra-ui/icons';
import { AppButtonAs, AppButton, AppColors, AppText } from '@backlinkit/shared';

export interface TagsActionsMenuProps {
  isDisabled: boolean;
  darkTheme?: boolean;
  hasBorder?: boolean;
  onDeleteTags?: () => void;
}

const TagsActionsMenu: React.FC<TagsActionsMenuProps> = ({
  isDisabled,
  hasBorder = false,
  darkTheme,
  onDeleteTags,
}) => {
  const handleTagsDelete = () => {
    onDeleteTags && onDeleteTags();
  };

  return (
    <Menu>
      <MenuButton
        as={AppButtonAs}
        rightIcon={<ChevronDownIcon color={darkTheme ? 'white' : AppColors.secondary2} />}
        size="md"
        borderRadius={'full'}
        bgColor={'rgba(3, 125, 252, 0.16)'}
        border={hasBorder ? '1px solid white' : 'none'}
      >
        <AppText color={darkTheme ? 'white' : AppColors.secondary2} fontWeight={'500'}>
          Actions
        </AppText>
      </MenuButton>
      <MenuList>
        <Flex flexDirection={'column'} px={4} py={2} gap={2}>
          <AppButton isDisabled={isDisabled} onClick={handleTagsDelete} leftIcon={<DeleteIcon />}>
            Delete
          </AppButton>
        </Flex>
      </MenuList>
    </Menu>
  );
};

export default TagsActionsMenu;
