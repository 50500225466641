import { Box, Flex } from '@chakra-ui/react';
import { AppColors, AppText, BackLinkListItem, LinkCheckerData } from '@backlinkit/shared';
import CheckerAnchorCell from '../components/app-link-anchor-cell';
import CheckerUrlCell from '../components/app-link-url-cell';
import CheckerGoogleIndexCell from '../components/app-link-gi-cell';
import CheckerLinkStatusCell from '../components/app-checker-link-status-cell';
import CheckerLandinPageCell from '../components/checker-landing-cell';
import { selectDomain } from '../../../../store/slices/domain.slice';
import CheckerRelCell from '../components/checker-rel-cell';
import { useAppSelector } from '../../../../store/store';

type LinksDetailsProps = {
  selectedLink?: LinkCheckerData;
  links?: BackLinkListItem[];
};

const CheckerLinkCard: React.FC<LinksDetailsProps> = ({ selectedLink, links }) => {
  const domain = useAppSelector(selectDomain);

  return (
    <Box py={4} w={'100%'}>
      {links?.map((link, index) => (
        <Box
          border={selectedLink ? '1px solid' : ''}
          borderColor={selectedLink ? AppColors.appBorder : ''}
          borderRadius={'5px'}
          padding={selectedLink ? 6 : 0}
          mb={4}
          minH={'60px'}
          width={'100%'}
        >
          <Flex
            flexDir={'column'}
            width={'100%'}
            key={`${link.url}-${index}`}
            borderBottom={`1px solid ${AppColors.appBorder}`}
          >
            <Flex
              width={'100%'}
              align={'center'}
              justify={'space-between'}
              borderBottom={`1px solid ${AppColors.appBorder}`}
            >
              <Box
                pb={4}
                height={'65px'}
                minW={'200px'}
                w={'100%'}
                display={'flex'}
                alignItems={'center'}
              >
                <AppText fontWeight={'600'} fontSize={'16px'}>
                  Status
                </AppText>
              </Box>

              <Flex pb={4} align={'center'} gap={2} width={'100%'}>
                <CheckerLinkStatusCell
                  isManual={link?.isManual}
                  linkStatus={link?.backlinkMeta?.linkStatus}
                />
              </Flex>
            </Flex>

            <Flex
              width={'100%'}
              align={'center'}
              justify={'space-between'}
              borderBottom={`1px solid ${AppColors.appBorder}`}
            >
              <Box height={'65px'} minW={'200px'} w={'100%'} display={'flex'} alignItems={'center'}>
                <Flex alignItems={'center'} gap={2}>
                  <AppText fontWeight={'600'} fontSize={'16px'}>
                    URL
                  </AppText>
                </Flex>
              </Box>
              <Flex align={'center'} gap={2} w={'100%'}>
                <CheckerUrlCell url={link?.url ?? ''} />
              </Flex>
            </Flex>

            <Flex
              width={'100%'}
              justify={'space-between'}
              align={'center'}
              borderBottom={`1px solid ${AppColors.appBorder}`}
            >
              <Box height={'65px'} display={'flex'} minW={'200px'} w={'100%'} alignItems={'center'}>
                <AppText fontWeight={'600'} fontSize={'16px'}>
                  Landing
                </AppText>
              </Box>
              <Flex align={'center'} gap={2} w={'100%'}>
                <CheckerLandinPageCell
                  url={link?.url}
                  landingPage={link?.landingPage}
                  backlinkType={link?.backlinkType}
                  linkStatus={link?.backlinkMeta?.linkStatus}
                  domainUrl={domain?.url ?? ''}
                />
              </Flex>
            </Flex>

            <Flex
              justify={'space-between'}
              align={'center'}
              w={'100%'}
              borderBottom={`1px solid ${AppColors.appBorder}`}
            >
              <Box height={'65px'} display={'flex'} minW={'200px'} w={'100%'} alignItems={'center'}>
                <AppText fontWeight={'600'} fontSize={'16px'}>
                  Anchor
                </AppText>
              </Box>
              <Flex w={'100%'} align={'center'} gap={2}>
                <CheckerAnchorCell item={link} />
              </Flex>
            </Flex>

            <Flex
              width="100%"
              justify={'space-between'}
              align={'center'}
              borderBottom={`1px solid ${AppColors.appBorder}`}
            >
              <Box height={'65px'} display={'flex'} minW={'200px'} w={'100%'} alignItems={'center'}>
                <AppText fontWeight={'600'} fontSize={'16px'}>
                  GI
                </AppText>
              </Box>
              <Flex align={'center'} gap={2} w={'100%'}>
                <CheckerGoogleIndexCell
                  url={link?.url}
                  hasDomainIndex={link?.backlinkGoogle?.hasDomainIndex}
                  hasPageIndex={link?.backlinkGoogle?.hasPageIndex}
                  googleCheckDate={link?.backlinkGoogle?.lastGoogleCheckDate}
                />
              </Flex>
            </Flex>

            <Flex
              width={'100%'}
              align={'center'}
              justify={'space-between'}
              borderBottom={`1px solid ${AppColors.appBorder}`}
            >
              <Box height={'65px'} display={'flex'} minW={'200px'} w={'100%'} alignItems={'center'}>
                <AppText fontWeight={'600'} fontSize={'16px'}>
                  REL
                </AppText>
              </Box>
              <Flex align={'center'} gap={2} w={'100%'}>
                <CheckerRelCell
                  relStatus={link?.backlinkMeta?.relStatus}
                  relValue={link?.relValue}
                />
              </Flex>
            </Flex>
          </Flex>
        </Box>
      ))}
    </Box>
  );
};

export default CheckerLinkCard;
