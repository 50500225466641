import React from 'react';
import { Flex, Icon, IconButton, Tooltip, useColorModeValue, useToast } from '@chakra-ui/react';
import {
  AppColors,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  useDataTable,
  useLoading,
  AppDataTable,
  AppPaginator,
  PageChangeEvent,
  AppInput,
  AppButton,
  LoadingComponent,
  OrganizationCompetitor,
} from '@backlinkit/shared';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaSave } from 'react-icons/fa';
import { MdModeEdit } from 'react-icons/md';
import { selectUser } from '../../store/slices/authentication.slice';
import { useAppSelector } from '../../store/store';
import { FaEdit } from 'react-icons/fa';
import {
  useCreateOrganizationCompetitorMutation,
  useEditOrganizationCompetitorMutation,
  useFetchAllOrganizationCompetitorsQuery,
  useLazyFetchAllOrganizationCompetitorsQuery,
} from '../../store/api/organization-competitorsApi';
import { iconHelper } from '../../utils/iconHelper';
import { Can } from '../../providers/permissions-provider';

export interface CompetitorFormData {
  id?: string;
  domain: string;
}

const defaultValues: CompetitorFormData = {
  domain: '',
};

const dataSchema = yup.object({
  domain: yup.string().required('Field is required'),
});

export const CompetitorManagement: React.FC = () => {
  const user = useAppSelector(selectUser);
  const toast = useToast();

  const {
    control,
    formState: { isValid, errors },
    setValue,
    getValues,
    reset,
  } = useForm<CompetitorFormData>({
    defaultValues: defaultValues,
    resolver: yupResolver(dataSchema),
    mode: 'onChange',
  });

  const [createTrigger] = useCreateOrganizationCompetitorMutation();
  const [updateTrigger] = useEditOrganizationCompetitorMutation();
  const [getAllCompetitors] = useLazyFetchAllOrganizationCompetitorsQuery();
  const { setLoading, loading } = useLoading();

  const { handleOnPageChange, pagination } = useDataTable<OrganizationCompetitor>({});

  const {
    data: competitors,
    refetch,
    isLoading,
  } = useFetchAllOrganizationCompetitorsQuery(user?.organizationId!, {
    skip: !user,
  });

  const handleEdit = (item: OrganizationCompetitor) => {
    setValue('id', item.id);
    setValue('domain', item.competitorDomain);
  };

  const competitorColumns: ColumnDefinitionType<OrganizationCompetitor>[] = [
    {
      key: 'dateCreated',
      type: ColumnSpecialTypes.date,
      header: 'Date Added',
    },
    {
      key: 'competitorDomain',
      header: 'Domain',
    },
    {
      key: 'competitorName',
      header: 'Name',
    },
    {
      key: 'custom',
      header: 'Actions',
      render: (item) => (
        <Can permission={'OrganizationCompetitors'} permissionLevel="Write">
          <Flex>
            <IconButton
              backgroundColor={'transparent'}
              aria-label="edit-competitor"
              isRound
              onClick={() => handleEdit(item)}
            >
              <Icon as={iconHelper.secondaryEditIcon} boxSize={6} />
            </IconButton>
          </Flex>
        </Can>
      ),
    },
  ];

  const handleSave = async () => {
    try {
      const formData = getValues();
      setLoading(true);
      if (formData.id) {
        const competitor = competitors?.find((x) => x.id === formData.id);
        if (competitor) {
          await updateTrigger({
            id: competitor.id,
            competitorDomain: formData.domain,
            competitorName: formData.domain,
            organizationId: competitor.organizationId,
          });
        }
        toast({
          title: 'Competitor saved',
          description: "We've saved your Competitor successfully!",
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      } else {
        await createTrigger({
          competitorName: formData.domain,
          competitorDomain: formData.domain,
          organizationId: user?.organizationId ?? '',
        });
        toast({
          title: 'Competitor saved',
          description: "We've saved your Competitor successfully!",
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }

      reset();
      refetch();

      if (user) {
        getAllCompetitors(user.organizationId);
      }
      setLoading(false);
    } catch (error) {
      toast({
        title: 'Competitor error',
        description: "We've run into a problem saving your competitor, Contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <Flex flexDir={'column'}>
      {(loading || isLoading) && <LoadingComponent />}
      <Can permission={'OrganizationCompetitors'} permissionLevel="Write">
        <Flex
          w={'full'}
          bg={useColorModeValue('white', 'gray.800')}
          justify={'center'}
          flexWrap={'nowrap'}
        >
          <AppInput<CompetitorFormData>
            mr={2}
            flex={1}
            name="domain"
            control={control}
            error={errors.domain}
            placeHolder="Add / Edit Competitor Domain"
            variant="grayed"
          />
          <Tooltip shouldWrapChildren label={'Save Competitor'} aria-label="save competitor">
            <AppButton
              px={3}
              flex={1}
              bgColor={AppColors.appBackground}
              color={'gray.500'}
              boxSize={'60px'}
              borderRadius={'2xl'}
              onClick={() => handleSave()}
              isDisabled={!isValid}
            >
              <Icon as={iconHelper.editIcon} boxSize={'35px'} />
            </AppButton>
          </Tooltip>
        </Flex>
      </Can>

      <AppDataTable
        searchBar={false}
        columns={competitorColumns}
        data={competitors || []}
        selectableRows={false}
        showColumnVisibility={false}
        noDataMessage="No Competitors added yet, start by adding some!"
      />
      <AppPaginator
        pagination={pagination}
        onPageChange={(event: PageChangeEvent) => handleOnPageChange(event)}
      ></AppPaginator>
    </Flex>
  );
};

export default CompetitorManagement;
