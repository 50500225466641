import { Alert, AlertIcon, AlertTitle, Flex } from '@chakra-ui/react';
import BaseLayout from '../../components/layouts/base-layout';
import { AppColors, AppText, ProductDto, ProductType } from '@backlinkit/shared';
import { useFetchProductsQuery } from '../../store/api/productApi';
import { AppPriceBannerCards } from '../../components/app/app-product-banner/app-product-banner';
import { useEffect, useState } from 'react';
import { Can } from '../../providers/permissions-provider';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/authentication.slice';
import usePayments from '../../hooks/usePayments';

export const ProductsPage: React.FC = () => {
  const { upgradePackage, updateSubscriptionApi } = usePayments();
  const user = useSelector(selectUser);

  const { data: plans } = useFetchProductsQuery({
    refetchOnMountArgChange: true,
  });

  const [products, setProducts] = useState<ProductDto[]>();

  useEffect(() => {
    if (plans) {
      const filteredPlans = plans?.filter(
        (x) => x.type === ProductType.SUBSCRIPTION || x.type === ProductType.PAYG
      );

      setProducts(filteredPlans);
    }
  }, [plans]);

  const setupPlan = async (product: ProductDto) => {
    try {
      if (product.type === ProductType.SUBSCRIPTION) {
        await upgradePackage(product);
      } else if (product.type === ProductType.PAYG) {
        await updateSubscriptionApi({ userId: user?.id ?? '', productId: product.id });
      }
    } catch (error) {}
  };

  return (
    <BaseLayout>
      <Flex flexDir={'column'} mt={2} p={4} gap={6} w={'100%'}>
        <Flex px={6}>
          <Alert status="warning">
            <AlertIcon />
            <AlertTitle>
              Your subscription trial has ended. Please set up a subscription below or select a new
              product to subscribe to.
            </AlertTitle>
          </Alert>
        </Flex>

        <Can permission={'OrganizationBilling'} permissionLevel="Write">
          <Flex flexDir={'column'} w={'full'} px={6}>
            <Flex
              justifyContent={'space-between'}
              align={'center'}
              borderTopRadius={'2xl'}
              bgColor={AppColors.secondary2}
              border={`1px solid ${AppColors.secondary2}`}
              p={8}
            >
              <AppText color={'white'} fontWeight="bold" fontSize="2xl">
                Please select a plan
              </AppText>
            </Flex>
            <Flex
              w={'100%'}
              p={4}
              border={`1px solid ${AppColors.secondary2}`}
              borderBottomRadius={'2xl'}
            >
              {products && user?.organization?.product && (
                <AppPriceBannerCards
                  products={products ?? []}
                  organizationProductId={user?.organization?.product.id}
                  onPlanSelect={(product) => setupPlan(product)}
                />
              )}
            </Flex>
          </Flex>
        </Can>
      </Flex>
    </BaseLayout>
  );
};
