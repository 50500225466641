import { Flex, Image } from '@chakra-ui/react';
import { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectDomain, selectDomains, setSelectedDomain } from '../../store/slices/domain.slice';
import { useLazyFetchBatchesByDomainQuery } from '../../store/api/backlinkBatchesApi';
import { useAppSelector } from '../../store/store';
import { AppButton, AppColors, AppText, getSumOfNumbers } from '@backlinkit/shared';
import { RoutesList } from '../../router/router';
import { DomainStats } from '../../pages/dashboard/dashboard';
import { useLocation, useNavigate } from 'react-router-dom';
import hrefIcon from '../../assets/ahrefs.svg';
import { Can } from '../../providers/permissions-provider';

export type DomainLayoutProps = {
  children?: ReactNode | ReactNode[];
};
const DomainLayout: React.FC<DomainLayoutProps> = ({ children }) => {
  const domain = useAppSelector(selectDomain);
  const domains = useAppSelector(selectDomains);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = useLocation();

  const [domainStats, setDomainStats] = useState<DomainStats>();

  useEffect(() => {
    if (domains) {
      const backLinkCountList = domains.map((x) => x.backlinkCount);
      const errorCountList = domains.map((x) => x.backlinkErrorCount);
      const domainStatsData = {
        domainCount: domains.length,
        linkCount: getSumOfNumbers(backLinkCountList),
        errorCount: getSumOfNumbers(errorCountList),
      };

      setDomainStats(domainStatsData);
    }
  }, [domains]);

  const [getBatchesByDomain] = useLazyFetchBatchesByDomainQuery();

  useEffect(() => {
    if (!domain && domains && domains.length > 0) {
      dispatch(setSelectedDomain(domains[0]));
      getBatchesByDomain(domains[0].id);
    }
  }, [domains]);

  return (
    <>
      <Flex direction={'column'} w={'100%'} h={'100%'} maxW={'100%'} p={4}>
        {domain ? (
          <>
            <Flex
              flexDir={'column'}
              border={`1px solid ${AppColors.secondary2}`}
              borderRadius={'2xl'}
            >
              <Flex
                width={'100%'}
                bgColor={AppColors.secondary2}
                align={'center'}
                justify={'space-between'}
                p={8}
                borderTopRadius={'2xl'}
              >
                <Flex align={'center'} gap={5}>
                  <Flex>
                    <Flex align={'center'} gap={4}>
                      <Flex bgColor={'rgba(255, 255, 255, 0.1)'} borderRadius={'lg'}>
                        <Image src={hrefIcon} boxSize={'25px'} />
                      </Flex>
                      <AppText color={'white'}>Ahrefs</AppText>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Flex align={'center'} gap={4}>
                      <AppText color={'white'}>Backlinks</AppText>
                      <Flex bgColor={'rgba(255, 255, 255, 0.1)'} p={2} borderRadius={'lg'}>
                        <AppText color={'white'} fontWeight={'600'} fontSize={'20px'}>
                          {domainStats && domainStats.linkCount}
                        </AppText>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Flex align={'center'} gap={4}>
                      <AppText color={'white'}>Errors</AppText>
                      <Flex bgColor={'rgba(255, 255, 255, 0.1)'} p={2} borderRadius={'lg'}>
                        <AppText color={'white'} fontWeight={'600'} fontSize={'20px'}>
                          {domainStats && domainStats.errorCount}
                        </AppText>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex gap={4} pr={4} width={{ lg: '50%', xl: 'auto' }}>
                  <Can permission={'Domains'} permissionLevel="Read">
                    <AppButton
                      bgColor={
                        url.pathname === RoutesList.DomainProfile
                          ? AppColors.ctaColor
                          : 'transparent'
                      }
                      color={'white'}
                      border={`1px solid white`}
                      onClick={() => navigate(RoutesList.DomainProfile)}
                    >
                      Domain Profile
                    </AppButton>
                  </Can>
                  <Can permission={'Backlinks'} permissionLevel="Read">
                    <AppButton
                      bgColor={
                        url.pathname === RoutesList.Links ? AppColors.ctaColor : 'transparent'
                      }
                      color={'white'}
                      border={`1px solid white`}
                      onClick={() => navigate(RoutesList.Links)}
                    >
                      All Links
                    </AppButton>
                    <AppButton
                      bgColor={
                        url.pathname === RoutesList.DomainLinkChecker
                          ? AppColors.ctaColor
                          : 'transparent'
                      }
                      color={'white'}
                      border={`1px solid white`}
                      onClick={() => navigate(RoutesList.DomainLinkChecker)}
                    >
                      Link Database
                    </AppButton>
                  </Can>
                </Flex>
              </Flex>
              <Flex
                id="domain-layout-container"
                w={'100%'}
                h={'100%'}
                py={6}
                maxW={'100%'}
                overflow={'auto'}
              >
                {children}
              </Flex>
            </Flex>
          </>
        ) : (
          <></>
        )}
      </Flex>
    </>
  );
};

export default DomainLayout;
