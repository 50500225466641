import { Stack } from '@chakra-ui/react';
import { AppButton, AppColors, AppInput, OrganizationExludeLinkForm } from '@backlinkit/shared';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/authentication.slice';
import { useForm } from 'react-hook-form';

interface OrganizationExcludeLinkFormData {
  id?: string;
  link: string;
  organizationId: string;
}

const defaultValues: OrganizationExcludeLinkFormData = {
  link: '',
  organizationId: '',
};

const excludeLinkDataSchema = yup.object({
  link: yup.string().required('Field is required'),
  organizationId: yup.string().required('Field is required'),
});

type ExcludeLinkForm<T> = {
  form?: OrganizationExludeLinkForm;
} & BaseFormProps<T>;

export const ExcludeLinkForm: React.FC<ExcludeLinkForm<OrganizationExcludeLinkFormData>> = ({
  form,
  onSubmit,
}) => {
  const user = useSelector(selectUser);
  const {
    control,
    formState: { isValid, errors },
    getValues,
    handleSubmit,
  } = useForm<OrganizationExcludeLinkFormData>({
    defaultValues: form || defaultValues,
    resolver: yupResolver(excludeLinkDataSchema),
    mode: 'onChange',
  });

  const handleFormSubmit = () => {
    const formData = getValues();
    const submitData: OrganizationExcludeLinkFormData = {
      id: formData.id ?? '',
      link: formData.link,
      organizationId: user?.organizationId ?? '',
    };
    onSubmit(submitData);
  };

  return (
    <Stack spacing={4} borderRadius={'2xl'}>
      <AppInput<OrganizationExcludeLinkFormData>
        name="link"
        control={control}
        error={errors.link}
        label="Link"
        variant='grayed'
      />
      <AppButton onClick={handleFormSubmit} bgColor={AppColors.ctaColor} color={'white'} mt={4}>
        Save
      </AppButton>
    </Stack>
  );
};
