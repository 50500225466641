import { Tooltip, Icon } from '@chakra-ui/react';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { BaseBacklinkCell } from './base-backlink-cell';
import { BsGlobeAmericas } from "react-icons/bs";


type TldCellProps = BaseBacklinkCell & {};

const TldCell: React.FC<TldCellProps> = ({ item }) => {
  if (!item.backlinkMeta) {
    return <></>;
  }

  return (
    <Tooltip
      label={
        item?.backlinkMeta?.tldCountry
          ? item?.backlinkMeta?.tldCountry
          : item?.backlinkMeta?.tld
      }
    >
      {item?.backlinkMeta?.tldCountry &&
      item?.backlinkMeta?.tldCountry.length > 0 ? (
        <span>
          <ReactCountryFlag countryCode={item?.backlinkMeta?.tld ?? ''} />
        </span>
      ) : (
        <span>
          <Icon as={BsGlobeAmericas} boxSize={'25px'} color={'gray'} />
        </span>
      )}
    </Tooltip>
  );
};

export default TldCell;
