import { ChakraProvider } from '@chakra-ui/react';
import Router from './router/router';
import './App.css';
import {
  DialogServiceProvider,
  LoadingProvider,
  PanelServiceProvider,
  theme,
} from '@backlinkit/shared';
import { ClientDialogServiceProvider } from './providers/client-dialog/client-dialog-provider';
import { PermissionsContextProvider } from './providers/permissions-provider';

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <PermissionsContextProvider>
        <LoadingProvider>
          <ClientDialogServiceProvider>
            <DialogServiceProvider>
              <PanelServiceProvider>
                <Router></Router>
              </PanelServiceProvider>
            </DialogServiceProvider>
          </ClientDialogServiceProvider>
        </LoadingProvider>
      </PermissionsContextProvider>
    </ChakraProvider>
  );
};
