import React from 'react';
import { Flex, Alert, Box } from '@chakra-ui/react';
import { AppText } from '../app-text/app-text';
import { CloseIcon } from '@chakra-ui/icons';
import { Notification } from '../../models';
import { AppColors } from '../../theme';

export type AppUserNotificationProps = {
  item: Notification;
  showName?: boolean;
  showType?: boolean;
  itemsClearable?: boolean;
  onItemClearClick: (item: Notification) => void;
  onItemClick?: (item: Notification) => void;
};

export const AppUserNotification: React.FC<AppUserNotificationProps> = ({
  item,
  showName = true,
  showType = true,
  itemsClearable = false,
  onItemClearClick,
  onItemClick,
}) => {
  const handleClearAlertClick = (item: Notification) => {
    itemsClearable && onItemClearClick && onItemClearClick(item);
  };

  const getAlertType = (item: Notification) => {
    switch (item.notificationType) {
      case 0:
        return 'info';
      case 1:
        return 'warning';
      case 2:
        return 'error';
      default:
        return 'info';
    }
  };

  return (
    <Flex
      justifyContent="space-between"
      alignItems="start"
      mb={2}
      bgColor={AppColors.appBorder}
      onClick={() => onItemClick && onItemClick(item)}
      p={4}
      borderRadius={'2xl'}
      gap={4}
    >
      <Flex
        boxSize={'40px'}
        minW={'40px'}
        borderRadius={'full'}
        bgColor={AppColors.appBackground}
        justify={'center'}
        align={'center'}
      >
        <AppText color={'white'}></AppText>
      </Flex>
      <Flex direction={'column'}>
        {showName && (
          <Box>
            <Flex align={'center'} gap={4}>
              <Flex justifyContent={'start'} alignItems="center">
                <AppText mr={2} fontWeight={'bold'}>
                  {item.title}
                </AppText>
              </Flex>
            </Flex>
          </Box>
        )}

        {showType && (
          <Flex mt={showName ? 2 : 0} align={'center'} gap={4}>
            <Flex direction={'row'} justifyContent={'start'} alignItems="center" ml={2}>
              <AppText>{item.description}</AppText>
            </Flex>
          </Flex>
        )}

        {/* {item.title && (
          <Box mt={showName ? 2 : 0}>
            <Flex direction={'row'} justifyContent={'start'} alignItems="center">
              <AppText>{item.title}</AppText>
            </Flex>
          </Box>
        )} */}

        {item.dateCreated && (
          <Box mt={showName ? 2 : 0}>
            <Flex direction={'row'} justify={'start'} align={'center'}>
              <AppText>{item.dateCreated.toString().slice(0, 10)}</AppText>
            </Flex>
          </Box>
        )}
      </Flex>
      {itemsClearable && (
        <Flex>
          <CloseIcon
            onClick={() => handleClearAlertClick(item)}
            width={'10px'}
            height={'10px'}
            _hover={{
              cursor: 'pointer',
            }}
          />
        </Flex>
      )}
    </Flex>
  );
};
