import linkCheckerIcon from '../assets/link-checker-icon.svg';
import externalReportIcon from '../assets/external-report-icon.svg';
import dashboardIcon from '../assets/dashboard-icon.svg';
import linkBatchIcon from '../assets/link-batch-icon.svg';
import accountsIcon from '../assets/accounts-icon.svg';
import tagsIcon from '../assets/tags-icon.svg';
import { LinkItemProps } from '../models/linkItem';

export const HeaderNavItems: LinkItemProps[] = [
  {
    label: 'Dashboard',
    href: '/dashboard',
    customLinkIcon: dashboardIcon,
  },
  {
    label: 'Link Batches',
    href: '/batches',
    customLinkIcon: linkBatchIcon,
    permission: 'Backlinks',
  },
  {
    label: 'Link Checker',
    href: '/google-checker',
    customLinkIcon: linkCheckerIcon,
    permission: 'GoogleChecker',
  },
  {
    label: 'External Reports',
    href: '/links/reports',
    customLinkIcon: externalReportIcon,
    permission: 'ExternalReports',
  },
  {
    label: 'Tags',
    href: '/tags',
    customLinkIcon: tagsIcon,
    permission: 'Backlinks',
  },
  {
    label: 'Account',
    href: '/account',
    customLinkIcon: accountsIcon,
    permission: [
      'OrganizationBilling',
      'OrganizationUsers',
      'OrganizationCompetitors',
      'OrganizationEflBlacklist',
    ],
  },
];
