import {
  Box,
  Card,
  Flex,
  FlexProps,
  Icon,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
  useColorModeValue,
  Text,
  Button,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import GoogleIndexCell from '../../components/backlinks/components/cells/google-index-cell';
import LinkDomainAgeCell from '../../components/backlinks/components/cells/last-domain-age-cell';
import {
  AppButton,
  AppCard,
  AppColors,
  AppConfirm,
  AppCsvFileUploadReader,
  AppDataTable,
  AppInput,
  AppPaginator,
  AppText,
  BacklinkCSVImport,
  ColumnDefinitionType,
  CsvFileUploadReaderResult,
  DomainAgeStatus,
  Organization,
  PageChangeEvent,
  PaginatedData,
  Pagination,
  SortParam,
  UserCheckerLinkBulkCreateRequest,
  UserCheckerLinkReprocessRequest,
  UserGoogleCheckerLink,
  UserGoogleCheckerLinkCreate,
  calculateAge,
  sortingColumnsToSortingArray,
  useDialog,
  useLoading,
} from '@backlinkit/shared';
import {
  useLazyUserCheckerLinkBulkCreateQuery,
  useLazyFetchGoogleCheckerItemsByOptionsQuery,
  useLazyUserCheckerLinkBulkDeleteQuery,
  useLazyUserCheckerReprocessQuery,
} from '../../store/api/googleCheckerApi';
import { selectAvailableCredits, selectUser } from '../../store/slices/authentication.slice';
import {
  selectTempGoogleCheckerList,
  resetTempGoogleCheckerList,
} from '../../store/slices/domain.slice';
import {
  validateMultiBacklinkFormData,
  backlinkImportFileValidation,
} from '../../utils/sharedFormHelpers';
import * as yup from 'yup';
import { backlinkURLRegex } from '../../constants/regex';
import {
  useEditOrganizationCreditByProductIdMutation,
  useLazyFetchOrganizationByIdQuery,
} from '../../store/api/organizationApi';
import { useLazyFetchUserByIdQuery } from '../../store/api/userApi';
import CheckOutForm from '../../components/forms/checkout-form';
import { RepeatIcon } from '@chakra-ui/icons';
import GoogleCheckerReprocessForm from '../../components/forms/google-checker-reprocess';
import { useAppSelector } from '../../store/store';
import { iconHelper } from '../../utils/iconHelper';
import { Can } from '../../providers/permissions-provider';
const { JSONtoCSVConverter } = require('react-json-csv-convert');

export interface AppLinkGoogleInputCheckerData {
  url: string;
  checkGoogleIndex: boolean;
  checkGoogleCache: boolean;
  checkDomainAge: boolean;
}

export interface AppLinkGoogleCheckerData {
  url: string;
  hasDomainIndex?: boolean;
  hasPageIndex?: boolean;
  cacheState?: boolean;
  cacheCreatedDate?: Date;
  originalDomainCreationDate?: string;
}

export type AppLinkGoogleCheckerProps = {} & FlexProps;

export const appLinkCheckerDefaultValues: AppLinkGoogleInputCheckerData = {
  url: '',
  checkDomainAge: true,
  checkGoogleCache: true,
  checkGoogleIndex: true,
};

export interface MultiBacklinkCheckerData {
  urls: string;
  checkGoogleCache: boolean;
  checkGoogleIndex: boolean;
  checkDomainAge: boolean;
}
export const multiDefaultValues: MultiBacklinkCheckerData = {
  urls: '',
  checkDomainAge: true,
  checkGoogleIndex: true,
  checkGoogleCache: false,
};

const appLinkGoogleCheckerDataSchema = yup.object({
  url: yup.string().required('Field is required').matches(backlinkURLRegex, 'Not a valid Url'),
});

const MultiGoogleCheckerDataSchema = yup.object({
  urls: yup.string().required('Field is required').matches(backlinkURLRegex, 'Not a valid Url'),
});

export default function DomainLinkGoogleChecker() {
  const availableCredits = useAppSelector(selectAvailableCredits);

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const [checkedSingleItems, setCheckedSingleItems] = useState([true, false, true]);
  const [checkedMultiItems, setCheckedMultiItems] = useState([true, true, true]);
  const [checkedCsvItems, setCheckedCsvItems] = useState([true, true, true]);
  const today = new Date();
  const [urlList, setUrlList] = useState<PaginatedData<UserGoogleCheckerLink>>();
  const dialog = useDialog();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [organization, setOrganization] = useState<Organization | undefined>();
  const [getOrganization] = useLazyFetchOrganizationByIdQuery();

  const tempGoogleChecks = useSelector(selectTempGoogleCheckerList);
  const { setLoading } = useLoading();

  const [pagination, setPagination] = useState<Pagination>();
  const [currentPage, setCurrentPage] = useState<number>();
  const [currentPageSize, setCurrentPageSize] = useState<number>();

  const [selectedUrls, setSelectedUrls] = useState<UserGoogleCheckerLink[]>([]);

  const [googleIndexCheck, setGoogleIndexCheck] = useState<boolean>(true);
  const [domainAgeCheck, setDomainAgeCheck] = useState<boolean>(true);

  const [multiIndexCheck, setMultiIndexCheck] = useState<boolean>(true);
  const [multiAgeCheck, setMultiAgeCheck] = useState<boolean>(true);

  const [csvIndexCheck, setCsvIndexCheck] = useState<boolean>(true);
  const [csvAgeCheck, setCsvAgeCheck] = useState<boolean>(true);

  const [bulkCreate] = useLazyUserCheckerLinkBulkCreateQuery();
  const [getUserById] = useLazyFetchUserByIdQuery();
  const [getGoogleCheckerItemsByOptions] = useLazyFetchGoogleCheckerItemsByOptionsQuery();

  const [deleteAllGoogleItems] = useLazyUserCheckerLinkBulkDeleteQuery();

  const [editOrganizationCredits] = useEditOrganizationCreditByProductIdMutation();
  const [currentSortList, setCurrentSortList] = useState<SortParam[]>([]);

  const [reprocessCheckerLinks] = useLazyUserCheckerReprocessQuery();

  const screenSize = useBreakpointValue({
    base: '1000px',
    xl: '1440px',
  });
  const variant = useBreakpointValue({
    base: '12px',
    md: '18px',
    lg: '24px',
  });

  const tagColumns: ColumnDefinitionType<UserGoogleCheckerLink>[] = [
    {
      key: 'url',
      header: 'Domain / Url',
      headerSortable: true,
    },
    {
      key: 'hasPageIndex',
      header: 'GI',
      render: (link) => {
        if (link.hasDomainIndex || link.hasPageIndex) {
          return (
            <GoogleIndexCell
              url={link.url}
              hasDomainIndex={link.hasDomainIndex}
              hasPageIndex={link.hasPageIndex}
              googleCheckDate={link.hasPageIndex === null ? undefined : today}
            />
          );
        } else {
          return <AppText>Not Checked</AppText>;
        }
      },
    },
    // {
    //   key: 'cacheState',
    //   header: 'GC',
    //   headerSortable: true,
    //   render: (link) => {
    //     if (link.checkGoogleCache) {
    //       return (
    //         <GoogleCacheCell
    //           url={link.url}
    //           cacheState={link.cacheState}
    //           cacheDate={link.cacheCreatedDate}
    //           googleCheckDate={link.hasPageIndex === null ? undefined : today}
    //           domainMeta={[]}
    //         />
    //       );
    //     } else {
    //       return <AppText>Not Checked</AppText>;
    //     }
    //   },
    // },
    {
      key: 'domainCreatedDate',
      header: 'Domain Age',
      headerSortable: true,
      render: (link) => {
        if (link.domainCreatedDate) {
          return (
            <LinkDomainAgeCell originalDomainCreationDate={link.domainCreatedDate} url={link.url} />
          );
        } else if (link.domainAgeStatus === DomainAgeStatus.NOAGE) {
          return <AppText>No Age detected</AppText>;
        } else {
          return <AppText>Not Checked</AppText>;
        }
      },
    },
  ];

  const [tableColumns, setTableColumns] =
    useState<ColumnDefinitionType<UserGoogleCheckerLink>[]>(tagColumns);

  const {
    control: backLinkControl,
    formState: { isValid, errors },
    getValues,
    reset,
  } = useForm<AppLinkGoogleInputCheckerData>({
    defaultValues: appLinkCheckerDefaultValues,
    resolver: yupResolver(appLinkGoogleCheckerDataSchema),
    mode: 'onChange',
  });

  const {
    control: multiBackLinkControl,
    formState: { isValid: isMultiValid, errors: multiErrors },
    getValues: getMultiFormValues,
    reset: multiFormReset,
  } = useForm<MultiBacklinkCheckerData>({
    defaultValues: multiDefaultValues,
    resolver: yupResolver(MultiGoogleCheckerDataSchema),
    mode: 'all',
  });

  useEffect(() => {
    if (urlList && urlList.pagination) {
      setPagination(urlList?.pagination);
      setCurrentPage(urlList.pagination?.page || 1);
      setCurrentPageSize(urlList.pagination?.pageSize || 10);
    }
  }, [urlList]);

  useEffect(() => {
    if (currentPage && currentPageSize && user) {
      setupListData(currentPage, currentPageSize, currentSortList);
    }
  }, [currentPage, currentPageSize]);

  const setupListData = async (
    page: number | undefined,
    pageSize: number | undefined,
    sortList: SortParam[] | undefined
  ) => {
    setLoading(true);
    await dispatch(resetTempGoogleCheckerList());
    const data = await getGoogleCheckerItemsByOptions({
      userId: user?.id || '',
      pageNumber: page,
      pageSize: pageSize,
      sortParams: sortList,
    }).unwrap();

    setUrlList(data);
    setLoading(false);
  };

  const updateUrlList = async (
    urls: string[],
    checkGoogleIndex: boolean,
    checkGoogleCache: boolean,
    checkDomainAge: boolean
  ) => {
    setLoading(true);

    const items = urls.map((x) => {
      return {
        url: x,
        userId: user?.id || '',
      } as UserGoogleCheckerLinkCreate;
    });

    const urlGoogleUrls: UserCheckerLinkBulkCreateRequest = {
      userCheckerLinks: items,
      checkGoogleIndex,
      checkGoogleCache,
      checkDomainAge,
    };

    await bulkCreate(urlGoogleUrls).unwrap();
    await getUserById(user?.id ?? '').unwrap();

    setLoading(false);

    setupListData(currentPage, currentPageSize, currentSortList);
  };

  const handleConfirm = () => {
    const formData = getValues();
    let credits = 0;
    if (formData.checkDomainAge === true) {
      credits = credits + 15;
    }
    if (formData.checkGoogleIndex === true) {
      credits++;
    }
    if (availableCredits < credits) {
      openBuyCredits('Ooops, it seems you are out of credits! Purchase more to continue.');
    } else {
      dialog({
        title: 'Credit cost',
        size: 'lg',
        render: (onSubmit, onCancel) => {
          return (
            <AppConfirm
              message={`Adding the following link will cost ${credits} credits`}
              onConfirm={async () => {
                onSubmit();
                await handleFormSubmit();
              }}
              onCancel={() => {
                onCancel();
              }}
            />
          );
        },
      });
    }
  };

  const handleFormSubmit = () => {
    const currentForm = getValues();
    updateUrlList(
      [currentForm.url],
      currentForm.checkGoogleIndex,
      currentForm.checkGoogleCache,
      currentForm.checkDomainAge
    );
    reset();
  };

  const clearList = async () => {
    await deleteAllGoogleItems(user?.id || '');
    setupListData(1, currentPageSize, currentSortList);
  };

  const handleMultiFormConfirm = () => {
    const formData = getMultiFormValues();
    const backlinksData = validateMultiBacklinkFormData(formData.urls);
    const validUrlsLength = backlinksData.filter((x) => x.valid === true).length;
    let checkboxes = 0;
    if (formData.checkDomainAge) {
      checkboxes = checkboxes + 15;
    }
    if (formData.checkGoogleIndex) {
      checkboxes++;
    }
    const credits = validUrlsLength * checkboxes;
    if (availableCredits < credits) {
      openBuyCredits('Ooops, it seems you are out of credits! Purchase more to continue.');
    } else {
      dialog({
        title: 'Credit cost',
        size: 'lg',
        render: (onSubmit, onCancel) => {
          return (
            <AppConfirm
              message={`Adding the following links will cost ${credits} credits`}
              onConfirm={async () => {
                onSubmit();
                await handleMultiFormSubmit();
              }}
              onCancel={() => {
                onCancel();
              }}
            />
          );
        },
      });
    }
  };

  const handleMultiFormSubmit = () => {
    const currentForm = getMultiFormValues();
    const backlinksData = validateMultiBacklinkFormData(currentForm.urls);

    const validUrls = backlinksData
      .filter((x) => x.valid === true)
      .map((x) => {
        return x.url;
      });

    updateUrlList(
      validUrls,
      currentForm.checkGoogleIndex,
      currentForm.checkGoogleCache,
      currentForm.checkDomainAge
    );

    multiFormReset();
  };

  const handleImportConfirm = (fileData: CsvFileUploadReaderResult) => {
    const importList = backlinkImportFileValidation(fileData);
    let length = 0;
    let checkboxes = 0;
    if (importList && importList.validbacklinkFormsData.length > 0) {
      length = importList.validbacklinkFormsData.length;
    }
    const formData = getMultiFormValues();
    if (formData.checkDomainAge) {
      checkboxes = checkboxes + 15;
    }
    if (formData.checkGoogleIndex) {
      checkboxes++;
    }
    const credits = length * checkboxes;
    if (availableCredits < credits) {
      openBuyCredits('Ooops, it seems you are out of credits! Purchase more to continue.');
    } else {
      dialog({
        title: 'Credit cost',
        size: 'lg',
        render: (onSubmit, onCancel) => {
          return (
            <AppConfirm
              message={`Adding the following links will cost ${credits} credits`}
              onConfirm={async () => {
                onSubmit();
                await onBacklinkImportFileParsed(fileData);
              }}
              onCancel={() => {
                onCancel();
              }}
            />
          );
        },
      });
    }
  };

  const onBacklinkImportFileParsed = (fileData: CsvFileUploadReaderResult) => {
    const backlinksFormList = backlinkImportFileValidation(fileData);
    if (backlinksFormList && backlinksFormList.validbacklinkFormsData.length > 0) {
      const validUrls = backlinksFormList.validbacklinkFormsData.map((x) => {
        return x.url;
      });

      const currentForm = getMultiFormValues();

      updateUrlList(
        validUrls,
        currentForm.checkGoogleIndex,
        currentForm.checkGoogleCache,
        currentForm.checkDomainAge
      );
    }
  };

  const handleExportCsvClicked = () => {
    const exportData = urlList?.data?.map((item) => {
      return {
        url: item.url || '',
        hasDomainIndex: item.hasDomainIndex || '',
        hasPageIndex: item.hasPageIndex || '',
        // googleCached: item.cacheState || 'false',
        // googleCacheCreatedDate: item.cacheCreatedDate || '',
        domainCreationDate: item.domainCreatedDate || '',
        domainAge: calculateAge(item.domainCreatedDate || '') || '',
      };
    });

    const headers = [
      'URL',
      'HAS DOMAIN INDEX',
      'HAS PAGE INDEX',
      // 'GOOGLE CACHED',
      // 'GOOGLE CACHED DATE',
      'DOMAIN CREATED DATE',
      'DOMAIN AGE',
    ];
    const csvConfig = {
      headers,
      actions: Object.keys(headers).map((x) => null),
      keys: [
        'url',
        'hasDomainIndex',
        'hasPageIndex',
        // 'googleCached',
        // 'googleCacheCreatedDate',
        'domainCreationDate',
        'domainAge',
      ],
    };

    dialog({
      title: 'Export Link Checker list',
      centeredHeading: true,
      showCancel: true,
      size: 'lg',
      render: (onSubmit, onCancel) => {
        return (
          <JSONtoCSVConverter
            csvConfig={csvConfig}
            data={exportData}
            styleProp={{ display: 'inline-block' }}
            fileName={`backlinkit-google-checker-${format(new Date(), 'dd MMM yyyy')}`}
          >
            <Button
              bgColor={'white'}
              borderRadius={'xl !important'}
              variant={'ghost'}
              border={`1px solid ${AppColors.outlineColor}`}
              my={6}
              onClick={() => {
                setupExportLoader();
                onCancel();
              }}
            >
              Download CSV
            </Button>
          </JSONtoCSVConverter>
        );
      },
    });
  };

  const setupExportLoader = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  const getRefreshText = () => {
    return 'Refresh list';
  };

  const handleOnPageChange = (pageChangeEvent: PageChangeEvent) => {
    setCurrentPage(pageChangeEvent.currentPage);
    setCurrentPageSize(pageChangeEvent.currentPageSize);
  };

  const handleSortByColumnClick = (columns: ColumnDefinitionType<UserGoogleCheckerLink>[]) => {
    const columnSortQueryString = sortingColumnsToSortingArray(columns);
    setCurrentSortList(columnSortQueryString);

    setupListData(currentPage, currentPageSize, columnSortQueryString);
  };

  const refresh = async () => {
    setUrlList(undefined);
    await setupListData(currentPage, currentPageSize, currentSortList);
  };

  const upgradeOrganizationCreditByProduct = async (productId: string) => {
    await editOrganizationCredits({
      id: user?.organizationId,
      productId,
    });

    await getUserById(user?.id ?? '').unwrap();
    await getOrganization(user?.organizationId!).unwrap;
  };

  // const updateOrganizationDetails = useCallback(async () => {
  //   const currentOrganization = await getOrganization(user?.organizationId!).unwrap();
  //   setOrganization(currentOrganization);
  // }, [getOrganization, user?.organizationId]);

  const updateOrganizationDetails = async () => {
    const currentOrganization = await getOrganization(user?.organizationId as string).unwrap();
    if (currentOrganization) {
      setOrganization(currentOrganization);
    }
  };

  const openBuyCredits = async (title: string) => {
    dialog({
      title: title,
      size: '2xl',
      showCancel: true,
      render: (onSubmit, onCancel) => {
        return (
          <CheckOutForm
            onSubmit={(item) => {
              upgradeOrganizationCreditByProduct(item.id);
            }}
            onCancelPlan={() => {}}
            onlyCreditProducts={true}
          />
        );
      },
    });
  };

  const handleSelectedRows = (items: UserGoogleCheckerLink[]) => {
    setSelectedUrls(items);
  };

  const handleReprocess = (selectedRows: UserGoogleCheckerLink[]) => {
    let checkerLinkIds: string[] = [];
    selectedRows.forEach((link) => {
      checkerLinkIds.push(link.id);
    });
    dialog({
      title: 'Reprocess Checker Links',
      size: '2xl',
      showCancel: true,
      render: (onSubmit, onCancel) => {
        return (
          <GoogleCheckerReprocessForm
            onSubmit={async (formData) => {
              let i = 0;
              if (formData.checkDomainAge) {
                i = i + 15;
              }
              // if (formData.checkGoogleCache) {
              //   i++;
              // }
              if (formData.checkGoogleIndex) {
                i++;
              }
              const cost = selectedRows.length * i;
              const data: UserCheckerLinkReprocessRequest = {
                checkDomainAge: formData.checkDomainAge,
                checkGoogleCache: formData.checkGoogleCache,
                checkGoogleIndex: formData.checkGoogleIndex,
                userCheckerLinkIds: checkerLinkIds,
              };
              await handleReprocessConfirm(cost, data);
            }}
          />
        );
      },
    });
  };

  const handleReprocessConfirm = (cost: number, data: UserCheckerLinkReprocessRequest) => {
    dialog({
      title: 'Reprocess Links?',
      size: '2xl',
      showCancel: true,
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message={`Reprocessing selected links will cost ${cost} credits, confirm?`}
            onConfirm={async () => {
              setLoading(true);
              onSubmit();
              await reprocessCheckerLinks(data);
              updateOrganizationDetails();
              setLoading(false);
            }}
            onCancel={() => onCancel()}
          />
        );
      },
    });
  };

  useEffect(() => {
    updateOrganizationDetails();
  }, []);

  useEffect(() => {
    console.log('this is the checked single items', checkedSingleItems);
  }, [checkedSingleItems]);

  return (
    <Box p={6} width={'100%'}>
      <Flex
        direction={'column'}
        w={'100%'}
        border={`1px solid ${AppColors.secondary2}`}
        borderRadius={'2xl'}
      >
        <AppCard
          bgColor={`${AppColors.secondary2} !important`}
          borderTopRadius={'2xl'}
          borderBottomRadius={'0'}
        >
          <Flex direction={'row'} justifyContent={'space-between'} align={'center'} px={4}>
            <Flex direction={'row'}>
              <Text color={'white'} fontWeight="bold" fontSize={variant} mr={8}>
                Backlink Google Checker
              </Text>
            </Flex>

            <Flex mr={8} alignItems="center" flexDirection="row">
              <Text mr="4" color={'gray.400'} fontSize="sm" fontWeight="400">
                Available Credit
              </Text>
              <Flex
                bgColor={'rgba(255, 255, 255, 0.1)'}
                p={4}
                borderRadius={'xl'}
                justify={'center'}
                align={'center'}
              >
                <Text color={'white'} fontSize={variant} fontWeight="700">
                  {availableCredits ?? 0}
                </Text>
              </Flex>

              <Can permission={'OrganizationBilling'} permissionLevel="Write">
                <AppButton
                  onClick={() => openBuyCredits('Credit Products')}
                  borderRadius="full"
                  bgColor={AppColors.secondary2}
                  border={`1px solid white`}
                  textColor={'white'}
                  size={'sm'}
                  ml={4}
                  variant={'ghost'}
                >
                  Buy Credits
                </AppButton>
              </Can>
            </Flex>

            <Flex direction={'row'} display={screenSize === '1440px' ? 'flex' : 'none'}>
              <Box pos="relative">
                <AppButton
                  onClick={() => {
                    refresh();
                  }}
                  color={'white'}
                  borderColor={'white'}
                  border={`1px solid white`}
                  borderRadius="full"
                  mr={2}
                  variant={'ghost'}
                >
                  {getRefreshText()}

                  {tempGoogleChecks && tempGoogleChecks.length > 0 ? (
                    <Box className="button__badge">{tempGoogleChecks.length}</Box>
                  ) : null}
                </AppButton>
              </Box>
              <Can permission={'GoogleChecker'} permissionLevel="Write">
                <AppButton
                  variant={'ghost'}
                  mr={2}
                  color={'white'}
                  borderColor={'white'}
                  border={`1px solid white`}
                  borderRadius="full"
                  alignSelf={'flex-end'}
                  onClick={() => {
                    clearList();
                  }}
                >
                  {'Clear Checker'}
                </AppButton>
              </Can>
              <Can permission={'GoogleChecker'} permissionLevel="Read">
                <AppButton
                  variant={'ghost'}
                  mr={2}
                  color={'white'}
                  borderColor={'white'}
                  border={`1px solid white`}
                  borderRadius="full"
                  isDisabled={urlList && urlList.data && urlList.data.length === 0}
                  onClick={() => {
                    handleExportCsvClicked();
                  }}
                >
                  {'Export List'}
                </AppButton>
              </Can>
              <Can permission={'GoogleChecker'} permissionLevel="Write">
                <AppButton
                  bgColor={AppColors.ctaColor}
                  color={'white'}
                  borderRadius="full"
                  isDisabled={selectedUrls.length <= 0}
                  onClick={() => handleReprocess(selectedUrls)}
                  rightIcon={<RepeatIcon />}
                >
                  Reprocess
                </AppButton>
              </Can>
            </Flex>
          </Flex>
        </AppCard>
        <Flex id="google-layout-container" w={'100%'} h={'100%'}>
          <Box h={'100%'} pl={'20px'} pr={'20px'} w="100%">
            <Flex
              flexDirection={'row'}
              flex={1}
              gap={5}
              minH={'450px'}
              justifyContent={'space-between'}
            >
              <Card
                w={'100%'}
                variant="filled"
                bg={'white'}
                rounded={'3xl'}
                mb="20px"
                p={8}
                overflow="auto"
              >
                <form>
                  <Flex flexDir={'column'}>
                    <Can permission={'GoogleChecker'} permissionLevel="Write">
                      <Flex flexDirection={'column'} flex={1}>
                        <Tabs isFitted variant="enclosed">
                          <TabList py={4} display={'flex'} alignItems={'center'} gap={4}>
                            <Tab
                              as={Button}
                              _selected={{
                                bgColor: AppColors.secondary2,
                                color: 'white',
                                fontWeight: '500',
                              }}
                              maxW={'150px'}
                              fontWeight={'400'}
                              borderRadius={'2xl'}
                              height={'55px'}
                            >
                              Single
                            </Tab>
                            <Tab
                              as={Button}
                              _selected={{
                                bgColor: AppColors.secondary2,
                                color: 'white',
                                fontWeight: '400',
                              }}
                              maxW={'150px'}
                              fontWeight={'400'}
                              borderRadius={'2xl'}
                              height={'55px'}
                            >
                              Multi
                            </Tab>
                            <Tab
                              as={Button}
                              _selected={{
                                bgColor: AppColors.secondary2,
                                color: 'white',
                                fontWeight: '400',
                              }}
                              maxW={'150px'}
                              fontWeight={'400'}
                              borderRadius={'2xl'}
                              height={'55px'}
                            >
                              CSV Import
                            </Tab>
                          </TabList>
                          <TabPanels>
                            <TabPanel>
                              <Flex
                                flexDir={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                w={'full'}
                              >
                                <Flex align={'center'} w={'100%'} gap={4}>
                                  <AppText fontWeight={'600'}>Domain / Url</AppText>
                                  <AppInput<AppLinkGoogleInputCheckerData>
                                    control={backLinkControl}
                                    w={'50%'}
                                    height={'60px'}
                                    name="url"
                                    error={errors.url}
                                    placeHolder={'Domain / Url'}
                                    variant="blueSquared"
                                  />
                                  <Flex gap={3} align={'end'} height={'100%'}>
                                    <Button
                                      rightIcon={
                                        <Icon
                                          as={iconHelper.checkIcon}
                                          height={'20px'}
                                          width={'20px'}
                                          color={AppColors.secondary2}
                                        />
                                      }
                                      height={'60px'}
                                      bgColor={
                                        googleIndexCheck ? 'transparent' : AppColors.appBackground
                                      }
                                      borderRadius={'xl'}
                                      border={`1px solid rgba(45, 188, 250, 0.5)`}
                                      onClick={() => {
                                        setGoogleIndexCheck((prev) => !prev);
                                        setCheckedSingleItems([
                                          googleIndexCheck,
                                          false,
                                          domainAgeCheck,
                                        ]);
                                        console.log('checked items', checkedSingleItems);
                                      }}
                                    >
                                      Google Index
                                    </Button>
                                    <Button
                                      rightIcon={
                                        <Icon
                                          as={iconHelper.checkIcon}
                                          height={'20px'}
                                          width={'20px'}
                                          color={AppColors.secondary2}
                                        />
                                      }
                                      height={'60px'}
                                      bgColor={
                                        domainAgeCheck ? 'transparent' : AppColors.appBackground
                                      }
                                      borderRadius={'xl'}
                                      border={`1px solid rgba(45, 188, 250, 0.5)`}
                                      onClick={() => {
                                        setDomainAgeCheck((prev) => !prev);
                                        setCheckedSingleItems([
                                          googleIndexCheck,
                                          false,
                                          domainAgeCheck,
                                        ]);
                                        console.log('checked items', checkedSingleItems);
                                      }}
                                    >
                                      Domain Age
                                    </Button>
                                  </Flex>
                                </Flex>
                                <Flex alignItems={'center'}>
                                  <Button
                                    variant={'solid'}
                                    height={'60px'}
                                    borderRadius={'xl'}
                                    alignSelf={'flex-end'}
                                    isDisabled={!isValid}
                                    bgColor={'rgba(255, 136, 0, 0.1)'}
                                    color={AppColors.ctaColor}
                                    onClick={() => {
                                      handleConfirm();
                                    }}
                                  >
                                    {'+ Add'}
                                  </Button>
                                </Flex>
                              </Flex>
                            </TabPanel>
                            <TabPanel>
                              <Flex
                                flexDir={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                w={'full'}
                              >
                                <AppInput<MultiBacklinkCheckerData>
                                  w={'50%'}
                                  control={multiBackLinkControl}
                                  name="urls"
                                  label="URLs"
                                  error={multiErrors.urls}
                                  placeHolder={'Urls'}
                                  textArea={true}
                                  variant={'blue'}
                                />
                                <Flex align={'center'} gap={4}>
                                  <Button
                                    rightIcon={
                                      <Icon
                                        as={iconHelper.checkIcon}
                                        height={'20px'}
                                        width={'20px'}
                                        color={AppColors.secondary2}
                                      />
                                    }
                                    mb={'40px'}
                                    height={'60px'}
                                    borderRadius={'xl'}
                                    bgColor={multiIndexCheck ? 'transparent' : AppColors.appBorder}
                                    onClick={() => {
                                      setMultiIndexCheck((prev) => !prev);
                                      setCheckedMultiItems([multiIndexCheck, false, multiAgeCheck]);
                                    }}
                                    border={`1px solid rgba(45, 188, 250, 0.5)`}
                                  >
                                    Google Index
                                  </Button>
                                  <Button
                                    rightIcon={
                                      <Icon
                                        as={iconHelper.checkIcon}
                                        height={'20px'}
                                        width={'20px'}
                                        color={AppColors.secondary2}
                                      />
                                    }
                                    height={'60px'}
                                    mb={'40px'}
                                    borderRadius={'xl'}
                                    bgColor={
                                      multiAgeCheck ? 'transparent' : AppColors.appBackground
                                    }
                                    onClick={() => {
                                      setMultiAgeCheck((prev) => !prev);
                                      setCheckedMultiItems([multiIndexCheck, false, multiAgeCheck]);
                                    }}
                                    border={`1px solid rgba(45, 188, 250, 0.5)`}
                                  >
                                    Domain Age
                                  </Button>
                                </Flex>
                                <Flex alignItems={'center'}>
                                  <Button
                                    variant={'solid'}
                                    bgColor={'rgba(255, 136, 0, 0.1)'}
                                    height={'60px'}
                                    alignSelf={'flex-end'}
                                    isDisabled={!isMultiValid}
                                    color={AppColors.ctaColor}
                                    mb={'40px'}
                                    onClick={() => {
                                      handleMultiFormConfirm();
                                    }}
                                  >
                                    {'Validate and Add'}
                                  </Button>
                                </Flex>
                              </Flex>
                            </TabPanel>
                            <TabPanel>
                              <Flex
                                flexDir={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                w={'full'}
                              >
                                <Flex flexDir={'row'} w={'50%'}>
                                  <AppCsvFileUploadReader<BacklinkCSVImport>
                                    onFileParsed={handleImportConfirm}
                                  >
                                    <Button
                                      leftIcon={<Icon as={iconHelper.uploadIcon} />}
                                      height={'60px'}
                                      borderRadius={'xl'}
                                      bgColor={'rgba(255, 136, 0, 0.1)'}
                                      color={AppColors.ctaColor}
                                    >
                                      Upload
                                    </Button>
                                  </AppCsvFileUploadReader>
                                </Flex>
                                <Flex gap={3} py={4} align={'center'}>
                                  <Button
                                    rightIcon={
                                      <Icon
                                        as={iconHelper.checkIcon}
                                        height={'20px'}
                                        width={'20px'}
                                        color={AppColors.secondary2}
                                      />
                                    }
                                    height={'60px'}
                                    bgColor={
                                      csvIndexCheck ? 'transparent' : AppColors.appBackground
                                    }
                                    borderRadius={'xl'}
                                    border={`1px solid ${AppColors.appBackground}`}
                                    onClick={() => {
                                      setCsvIndexCheck((prev) => !prev);
                                      setCheckedCsvItems([csvIndexCheck, false, csvAgeCheck]);
                                    }}
                                  >
                                    Google Index
                                  </Button>
                                  <Button
                                    rightIcon={
                                      <Icon
                                        as={iconHelper.checkIcon}
                                        height={'20px'}
                                        width={'20px'}
                                        color={AppColors.secondary2}
                                      />
                                    }
                                    height={'60px'}
                                    bgColor={csvAgeCheck ? 'transparent' : AppColors.appBackground}
                                    borderRadius={'xl'}
                                    border={`1px solid ${AppColors.appBackground}`}
                                    onClick={() => {
                                      setCsvAgeCheck((prev) => !prev);
                                      setCheckedCsvItems([csvIndexCheck, false, csvAgeCheck]);
                                    }}
                                  >
                                    Domain Age
                                  </Button>
                                </Flex>
                                <Flex alignItems={'center'} ml={4}>
                                  <Link href={'/templates/import-template.csv'} target="_blank">
                                    <Button
                                      height={'60px'}
                                      bgColor={'rgba(255, 136, 0, 0.1)'}
                                      color={AppColors.ctaColor}
                                      variant={'solid'}
                                      borderRadius={'xl'}
                                      alignSelf={'flex-end'}
                                      fontWeight={'600'}
                                      leftIcon={<Icon as={iconHelper.downloadIcon} />}
                                    >
                                      Download Template
                                    </Button>
                                  </Link>
                                </Flex>
                              </Flex>
                            </TabPanel>
                          </TabPanels>
                        </Tabs>
                      </Flex>
                    </Can>

                    <Can permission={'GoogleChecker'} permissionLevel="Read">
                      <Flex
                        w={'full'}
                        // bg={useColorModeValue('white', 'gray.800')}
                        rounded={'2xl'}
                        p={4}
                        flexDir={'column'}
                        flex={1}
                      >
                        <AppDataTable
                          data={urlList?.data || []}
                          noDataMessage={`No url's added, start by adding some!`}
                          columns={tableColumns}
                          selectableRows={true}
                          searchBar={false}
                          onSortByColumnClick={handleSortByColumnClick}
                          onRowSelected={handleSelectedRows}
                        />
                        <AppPaginator
                          pagination={pagination}
                          onPageChange={handleOnPageChange}
                        ></AppPaginator>
                      </Flex>
                    </Can>
                  </Flex>
                </form>
              </Card>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}
