import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { SubscriptionsResponse, UpdateSubscriptionRequest } from '@backlinkit/shared';

export const paddleApi = createApi({
  reducerPath: 'paddleApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    // createCheckoutSession: build.mutation<StripeSessionResponse, StripeCheckoutSessionRequest>({
    //   query: (body) => ({
    //     url: `/stripe/create-checkout-session`,
    //     method: 'POST',
    //     body,
    //   }),
    //   transformResponse: (response: StripeSessionResponse) => {
    //     return response;
    //   },
    // }),
    // createPortalSession: build.mutation<StripeSessionResponse, StripePortalSessionRequest>({
    //   query: (body) => ({
    //     url: `/stripe/create-portal-session`,
    //     method: 'POST',
    //     body,
    //   }),
    //   transformResponse: (response: StripeSessionResponse) => {
    //     return response;
    //   },
    // }),
    fetchCustomerSubscriptions: build.query<SubscriptionsResponse, any>({
      query: () => ({
        url: `/paddle/subscriptions`,
        method: 'GET',
      }),
      transformResponse: (response: SubscriptionsResponse) => {
        return response;
      },
    }),
    updateSubscription: build.mutation<any, UpdateSubscriptionRequest>({
      query: (body) => ({
        url: `/paddle/update-subscription`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useFetchCustomerSubscriptionsQuery,
  useLazyFetchCustomerSubscriptionsQuery,
  useUpdateSubscriptionMutation,
} = paddleApi;
