import { Tooltip, Box, Icon } from '@chakra-ui/react';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { BaseBacklinkCell } from './base-backlink-cell';
import { BsGlobeAmericas } from 'react-icons/bs';

type IpCellProps = BaseBacklinkCell & {};

const IpCell: React.FC<IpCellProps> = ({ item }) => {
  if (!item.backlinkMeta) {
    return <></>;
  }

  const handleDomainAgeCellClick = async () => {
    if (item?.backlinkMeta?.ip)
      window.open(`https://www.whois.com/whois/${item?.backlinkMeta?.ip}`, 'blank');
  };

  return (
    <Tooltip
      label={
        item?.backlinkMeta?.ipCountry && item.backlinkMeta.ipCountry !== 'not found'
          ? item?.backlinkMeta?.ip
          : `${item?.backlinkMeta?.ip} - Country not found`
      }
    >
      <Box cursor="pointer" onClick={handleDomainAgeCellClick} width={'25px'} height={'25px !important'}>
        {item?.backlinkMeta?.ipCountry && item?.backlinkMeta?.ipCountry !== 'not found' ? (
          <span style={{ height: '25px !important', width: '25px !important' }}>
            <ReactCountryFlag
              countryCode={item?.backlinkMeta?.ipCountry}
              style={{
                height: 'auto',
                minHeight: '25px',
                width: '25px !important',
                borderRadius: '100%',
                objectFit: 'cover',
              }}
              svg
            />
          </span>
        ) : (
          <Icon as={BsGlobeAmericas} boxSize={'25px'} color={'gray'} />
        )}
      </Box>
    </Tooltip>
  );
};

export default IpCell;
