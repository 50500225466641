import { useState } from 'react';
import { Flex, Text, Image, Box } from '@chakra-ui/react';
import {
  AppButton,
  AppInput,
  AppColors,
  BugCreateForm,
  AppImageUploader,
} from '@backlinkit/shared';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { useForm } from 'react-hook-form';
import { ChangeEvent } from 'react';

const bugFormDefaultValues: BugCreateForm = {
  name: '',
  description: '',
};

const bugFormDataSchema = yup.object({
  name: yup.string().required('Field is required'),
  description: yup.string().required('Field is required'),
});

type BugFormProps<T> = {
  form?: BugCreateForm;
} & BaseFormProps<T>;

const BugForm: React.FC<BugFormProps<BugCreateForm>> = ({ form, onSubmit }) => {
  const [image, setImage] = useState<string>();
  const {
    control: bugControl,
    formState: { isValid, errors },
    setValue,
    getValues,
    handleSubmit,
  } = useForm<BugCreateForm>({
    defaultValues: form || bugFormDefaultValues,
    resolver: yupResolver(bugFormDataSchema),
    mode: 'onChange',
  });

  const getBase64 = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const currentFile = e.target.files![0];
    const base64: string = await getBase64(currentFile);
    const newImage: string = base64;
    setImage(newImage);
  };

  const handleFormSave = () => {
    setValue('screenshot', image);
    const formData = getValues();
    onSubmit(formData);
  };
  return (
    <Flex p={4} flexDir={'column'} gap={'10px'} w={'full'}>
      <AppInput<BugCreateForm>
        control={bugControl}
        name={'name'}
        error={errors.name}
        label={'Name'}
        variant="grayed"
      />
      <AppInput<BugCreateForm>
        control={bugControl}
        name={'description'}
        error={errors.name}
        label={'Description'}
        textArea
        variant="grayed"
      />
      <Flex
        pos={'relative'}
        bgColor={AppColors.appBackground}
        borderRadius={'15px'}
        justify={'center'}
        align={'center'}
        height={'80px'}
        mt={'10px'}
        borderStyle={'dashed'}
        borderColor={'gray.300'}
        borderWidth={'2px'}
      >
        <Text>Select an image</Text>
        <AppImageUploader<BugCreateForm>
          name="screenshot"
          control={bugControl}
          label="Screenshot"
          error={undefined}
          inputType="file"
          onChange={handleChange}
          style={{
            position: 'absolute',
            opacity: 0,
            top: 0,
            left: 0,
          }}
        />
      </Flex>
      {!image ? (
        <></>
      ) : (
        <Box>
          <Text>Screenshot:</Text>
          <Flex flexWrap={'wrap'} w={'100%'} gap={4}>
            <Image src={image} width={'120px'} height={'120px'} objectFit={'contain'} />
          </Flex>
        </Box>
      )}
      <AppButton
        w={'full'}
        mt={8}
        disabled={!isValid}
        onClick={() => handleFormSave()}
        bgColor={AppColors.ctaColor}
        color={'white'}
        height={'55px'}
      >
        Save
      </AppButton>
    </Flex>
  );
};

export default BugForm;
