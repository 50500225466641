import {
  Flex,
  useToast,
  useColorModeValue,
  Text,
  TagLabel,
  Tag,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import TagsActionsMenu from '../../components/app/app-menus/tags-actions-menu';
import { useAppSelector } from '../../store/store';
import { selectUser } from '../../store/slices/authentication.slice';
import {
  useDeleteBacklinkReportMutation,
  useFetchBacklinkReportsByOptionsQuery,
  useResendBacklinkReportMutation,
} from '../../store/api/backlinkReportApi';
import { ChevronDownIcon, DeleteIcon, ViewIcon } from '@chakra-ui/icons';
import {
  AppButton,
  AppColors,
  AppConfirm,
  AppDataTable,
  AppLoader,
  AppPaginator,
  AppText,
  BacklinkReport,
  CellClickEvent,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  useDataTable,
  useDialog,
  useLoading,
  usePanel,
} from '@backlinkit/shared';
import { IoSend } from 'react-icons/io5';
import LinkReportPanel from '../../components/link-report-panel/link-report-panel';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { Can } from '../../providers/permissions-provider';

const LinkReportsPage: React.FC = () => {
  const panel = usePanel();
  const { setLoading } = useLoading();
  const title = 'External Link Reports';

  const user = useAppSelector(selectUser);
  const [resendExternalReport] = useResendBacklinkReportMutation();
  const [deleteReportTrigger] = useDeleteBacklinkReportMutation();

  const {
    handleOnPageChange,
    handleSortByColumnClick,
    handleSearchChange,
    nextPaginationQueryParams,
    pagination,
    setPagination,
  } = useDataTable<BacklinkReport>({});

  const {
    data: backlinkReports,
    refetch: refetchbacklinkReports,
    isLoading,
  } = useFetchBacklinkReportsByOptionsQuery(
    {
      userId: user?.id?.toString() ?? '',
      pageNumber: nextPaginationQueryParams.page,
      pageSize: nextPaginationQueryParams.pageSize,
      searchParam: nextPaginationQueryParams.searchList,
      sortParams: nextPaginationQueryParams.sortList,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [deleteBacklinkReportTrigger] = useDeleteBacklinkReportMutation();

  const handleResend = async (id: string) => {
    try {
      setLoading(true);
      await resendExternalReport(id ?? '');
      toast({
        title: 'Report resent',
        description: `We've resent your report to the registered email`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    } catch (error) {
      toast({
        title: `Report resend issue`,
        description: `We've run into an error resending your report. Error = ${error}`,
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (backlinkReports?.pagination) {
      console.log('dashboardDomains?.pagination ', backlinkReports?.pagination);
      setPagination(backlinkReports?.pagination);
    }
  }, [backlinkReports]);

  const toast = useToast();
  const dialog = useDialog();

  const handleViewBacklinksPanel = async (item: BacklinkReport) => {
    panel({
      title: 'External Report',
      size: 'full',
      render: () => {
        return <LinkReportPanel item={item} />;
      },
    });
  };

  const handleReportDelete = async (report: BacklinkReport) => {
    try {
      await deleteBacklinkReportTrigger(report.id);
      toast({
        title: 'Report Deleted',
        description: "We've deleted the Backlink Report you selected",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Report Delete Error',
        description:
          "We've run into a problem deleting the selected backlink report. Contact support for help",
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
    }
  };

  const formatDate = (item: BacklinkReport): string => {
    const date = new Date(item.expiryDate);
    const stringDate = date.toISOString().split('T')[0].split('-');
    return `${stringDate[0]}.${stringDate[1]}.${stringDate[2]}`;
  };

  const renderDefaultActions = (item: BacklinkReport) => {
    return (
      <Menu>
        <MenuButton
          bgColor={'transparent'}
          border={`1px solid ${AppColors.appBorder}`}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius={'full'}
          mr={4}
          p={4}
        >
          Actions
        </MenuButton>
        <MenuList py={4} borderRadius={'2xl'}>
          <Can permission={'ExternalReports'} permissionLevel="Read">
            <MenuItem
              onClick={() => {
                handleViewBacklinksPanel(item);
              }}
              _hover={{
                bgColor: 'transparent',
              }}
            >
              <Flex
                width={'100%'}
                align={'center'}
                justify={'space-between'}
                p={1}
                borderRadius={'md'}
                _hover={{ bgColor: AppColors.appBackground }}
              >
                <Flex p={1} borderRadius={'md'} bgColor={'rgba(0, 0, 0, 0.1)'}>
                  <ViewIcon />
                </Flex>
                <AppText>View Report</AppText>
              </Flex>
            </MenuItem>
          </Can>
          <Can permission={'ExternalReports'} permissionLevel="Write">
            <MenuItem
              onClick={() => {
                handleResend(item.id);
              }}
              isDisabled={!item.otpUsedCount}
              _hover={{
                bgColor: 'transparent',
              }}
            >
              <Flex
                width={'100%'}
                align={'center'}
                justify={'space-between'}
                _hover={{ bgColor: AppColors.appBackground }}
                p={1}
                borderRadius={'md'}
              >
                <Flex borderRadius={'md'} bgColor={'rgba(0, 0, 0, 0.1)'} p={1}>
                  <IoSend />
                </Flex>
                <AppText>Share Report</AppText>
              </Flex>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleReportDelete(item);
              }}
              _hover={{ bgColor: 'transparent' }}
            >
              <Flex
                width={'100%'}
                align={'center'}
                justify={'space-between'}
                p={1}
                borderRadius={'md'}
                _hover={{ bgColor: AppColors.appBackground }}
              >
                <Flex borderRadius={'md'} p={1} bgColor={'rgba(0, 0, 0, 0.1)'}>
                  <RiDeleteBin5Line />
                </Flex>
                <AppText>Delete Report</AppText>
              </Flex>
            </MenuItem>
          </Can>
        </MenuList>
      </Menu>
    );
  };

  const reportColumns: ColumnDefinitionType<BacklinkReport>[] = [
    {
      key: 'custom',
      header: 'Domain Name',
      headerSortable: false,
      columnSearchable: false,
      render: (item) => {
        return <AppText>{item.domain?.name}</AppText>;
      },
    },
    {
      key: 'expiryDate',
      header: 'Expiry Date',
      headerSortable: true,
      columnSearchable: false,
      render: (item) => {
        return <AppText color={AppColors.textColor}>{formatDate(item)}</AppText>;
      },
    },
    {
      key: 'emailAddress',
      header: 'Email Address',
      headerSortable: false,
      columnSearchable: true,
    },

    {
      key: 'custom',
      header: 'Tag',
      headerSortable: false,
      columnSearchable: false,
      render: (item) => {
        return item.tag ? (
          <Flex width={'100%'} justify={'center'} pr={'50%'}>
            <AppText>{item.tag?.name}</AppText>
          </Flex>
        ) : (
          <></>
        );
      },
    },
    {
      key: 'custom',
      header: 'Backlinks',
      headerSortable: false,
      columnSearchable: false,
      render: (item) => {
        return (
          // <Box>
          //   <Tooltip
          //     label={
          //       <Flex flexDirection={'column'}>
          //         {item.backlinkIds.map((id) => (
          //           <AppText p="2" key={id}>
          //             {getBacklinkUrl(id)}
          //           </AppText>
          //         ))}
          //       </Flex>
          //     }
          //   >
          //     <span>

          //     </span>
          //   </Tooltip>
          // </Box>
          <Flex
            justify={'center'}
            width={'100%'}
            cursor={'pointer'}
            borderRadius="full"
            onClick={() => handleViewBacklinksPanel(item)}
          >
            <AppText>{`#${item.backlinks && item.backlinks.length}`}</AppText>
          </Flex>
        );
      },
    },
    {
      key: 'otpUsedCount',
      header: 'Otp Used Count',
      headerSortable: true,
      columnSearchable: false,
      render: (item) => {
        return (
          <Flex width={'100%'} justify={'center'}>
            <AppText>{item.otpUsedCount}</AppText>
          </Flex>
        );
      },
    },
    {
      key: 'custom',
      header: 'Actions',
      headerSortable: false,
      columnSearchable: false,
      render: (item) => {
        return renderDefaultActions(item);
      },
    },
  ];

  const [tableColumns, setTableColumns] =
    useState<ColumnDefinitionType<BacklinkReport>[]>(reportColumns);
  const [selectedReports, setSelectedReports] = useState<BacklinkReport[]>([]);

  const handleTableCellClick = async (clickEvent: CellClickEvent<BacklinkReport>) => {};

  const handleTableRowSelectedChange = (selection: BacklinkReport[]) => {
    setSelectedReports(selection);
  };

  const handleDeleteBacklinkClicked = () => {
    dialog({
      title: 'Delete',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message={`Are you sure you want to delete the ${selectedReports.length} selected External Link reports?`}
            onConfirm={async () => {
              onSubmit();

              await handleDelete();
            }}
            onCancel={() => {
              onCancel();
            }}
          ></AppConfirm>
        );
      },
    });
  };

  const handleDelete = async () => {
    try {
      const deletePromises = selectedReports.map((report) => {
        if (report && report.id) deleteBacklinkReportTrigger(report.id).unwrap();
      });

      await Promise.all(deletePromises);
    } catch (e) {
      toast({
        title: 'Report Delete error.',
        description:
          "We've run into a problem deleting the selected reports, Contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    toast({
      title: 'Selected External Reports deleted.',
      description: "We've deleted the external reports you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });

    refetchbacklinkReports();
  };

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Flex
      flex={1}
      direction="column"
      border={`1px solid ${AppColors.secondary2}`}
      m={6}
      borderRadius={'2xl'}
    >
      {isLoading && <AppLoader />}
      <Flex
        justifyContent={'space-between'}
        align={'center'}
        borderTopRadius={'2xl'}
        bgColor={AppColors.secondary2}
        p={8}
      >
        <Text color={'white'} fontWeight="bold" fontSize="2xl" mb="4px">
          {title}
        </Text>
        <Flex alignItems={'center'}>
          <Can permission={'ExternalReports'} permissionLevel="Write">
            <TagsActionsMenu
              hasBorder
              isDisabled={selectedReports.length === 0}
              onDeleteTags={handleDeleteBacklinkClicked}
              darkTheme
            />
          </Can>
        </Flex>
      </Flex>

      <Flex p={6} flex={1}>
        <Flex
          w={'full'}
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          padding={'4'}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={backlinkReports?.data || []}
            noDataMessage={'No external link reports added'}
            columns={tableColumns}
            selectableRows
            onRowSelected={handleTableRowSelectedChange}
            onTableCellClick={handleTableCellClick}
            onSearchInputChange={handleSearchChange}
            onSortByColumnClick={handleSortByColumnClick}
          />
          <AppPaginator pagination={pagination} onPageChange={handleOnPageChange}></AppPaginator>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LinkReportsPage;
