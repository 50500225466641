import { Box, Icon, Tag, TagLabel, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { BaseBacklinkCell } from './base-backlink-cell';
import { BiNotepad } from 'react-icons/bi';
import { AppText } from '@backlinkit/shared';

type LinkNotesCellProps = BaseBacklinkCell & {};

const LinkNotesCell: React.FC<LinkNotesCellProps> = ({ item }) => {
  if (item && item.notes && item.notes.length > 0) {
    return (
      <>
        {
          <Box justifyContent={'center'}>
            <Tooltip label={<AppText key={`link-note-${item.id}`}>{item.notes}</AppText>}>
              <span>
                <Icon as={BiNotepad} boxSize={'25px'} />
              </span>
            </Tooltip>
          </Box>
        }
      </>
    );
  } else {
    return (
      <Tag borderRadius="full">
        <TagLabel>-</TagLabel>
      </Tag>
    );
  }
};

export default LinkNotesCell;
