import React, { useState } from 'react';
import { Flex, Link, Spinner, Stack, useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { AppInput, AppButton, useLoading, AppText } from '@backlinkit/shared';
import { useNavigate } from 'react-router-dom';
import { AppColors } from '@backlinkit/shared';
import { RoutesList } from '../../router/router';
import { useRequestPasswordResetMutation } from '../../store/api/authenticationApi';

export type LoginFormData = {
  email: string;
  password: string;
};

export const loginFormDataDefaultValues: LoginFormData = {
  email: '',
  password: '',
};

const loginFormDataSchema = yup.object({
  email: yup.string().required('Field is required'),
  password: yup.string().required('Field is required'),
});

type LoginFormProps<T> = BaseFormProps<T>;

const LoginForm: React.FC<LoginFormProps<LoginFormData>> = ({ isLoading, onSubmit }) => {
  const navigate = useNavigate();
  const [isReset, setIsReset] = useState<string>('flex');
  const { setLoading } = useLoading();
  const toast = useToast();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isValid, errors },
  } = useForm<LoginFormData>({
    defaultValues: loginFormDataDefaultValues,
    resolver: yupResolver(loginFormDataSchema),
    mode: 'all',
  });
  const formValues = getValues();

  const [requestPasswordReset] = useRequestPasswordResetMutation();

  const handleFormSubmit = (formData: LoginFormData) => {
    onSubmit(formData);
  };

  const handleIsReset = () => {
    if (isReset === 'flex') {
      setIsReset('none');
    } else {
      setIsReset('flex');
    }
  };

  const handleSendEmail = async (email: string) => {
    try {
      setLoading(true);
      await requestPasswordReset(email);
      setLoading(false);
      toast({
        title: 'Password reset request',
        status: 'success',
        description: `We've sent you an email, please follow the link to reset your password.`,
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      setLoading(false);
      toast({
        title: 'Password reset request issue',
        description:
          "We've run into an issue sending your reset password email, contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Stack spacing={4} display={isReset} maxW={'555px'} w={'100%'}>
        <AppInput<LoginFormData>
          control={control}
          name="email"
          placeHolder="Email"
          error={errors.email}
          variant="default"
          label="Email"
        />
        <AppInput<LoginFormData>
          inputType="password"
          control={control}
          placeHolder="Password"
          error={errors.password}
          name="password"
          display={isReset}
          variant="default"
          label="Password"
        />
        <Stack spacing={4}>
          <Stack
            direction={{ base: 'column', sm: 'row' }}
            align={{ base: 'start', sm: 'center' }}
            justify={'end'}
          >
            <Link color={AppColors.ctaColor} onClick={() => handleIsReset()}>
              Forgot password?
            </Link>
          </Stack>

          <AppButton
            onClick={handleSubmit(handleFormSubmit)}
            fontFamily={'heading'}
            mt={8}
            height={'50px'}
            w={'full'}
            bgColor={AppColors.secondary2}
            color={'white'}
            _hover={{
              bgGradient: 'linear(to-r, #00a4ff,#037DFC)',
              boxShadow: 'xl',
            }}
            display={isReset}
            fontSize={'16px'}
          >
            {isLoading ? (
              <Flex gap={'10px'} align={'center'}>
                <Spinner /> Logging in
              </Flex>
            ) : (
              'Sign in'
            )}
          </AppButton>
          {/* <AppButton
            onClick={() => navigate(RoutesList.Registration)}
            fontFamily={'heading'}
            bg={'gray.200'}
            color={'gray.800'}
          >
            Sign up
          </AppButton> */}
          <Flex align={'center'} gap={3} width={'100%'} justify={'center'} fontSize={'16px'}>
            <AppText>Don't have an account?</AppText>
            <AppText
              color={AppColors.secondary2}
              onClick={() => navigate(RoutesList.Registration)}
              fontWeight={'600'}
              _hover={{
                cursor: 'pointer'
              }}
            >
              Sign Up
            </AppText>
          </Flex>
        </Stack>
      </Stack>
      <Stack spacing={4} display={isReset === 'flex' ? 'none' : 'flex'}>
        <AppInput<LoginFormData>
          control={control}
          name="email"
          placeHolder="Email"
          error={errors.email}
          label={'Email'}
        />
        <AppButton
          bgColor={AppColors.secondary}
          color={'white'}
          onClick={() => handleSendEmail(formValues.email)}
        >
          Send Email
        </AppButton>
        <Flex w={'100%'} justify={'center'}>
          <Link onClick={() => setIsReset('flex')} color={AppColors.secondary}>
            Back to Login
          </Link>
        </Flex>
      </Stack>
    </>
  );
};

export default LoginForm;
