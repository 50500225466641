import {
  Image,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  Flex,
  useMediaQuery,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import UserRegistrationForm, { RegistrationData } from '../../components/forms/registration-form';
import { useUserRegistrationMutation } from '../../store/api/authenticationApi';
import { AppColors, AppText, useLoading } from '@backlinkit/shared';
import { useState } from 'react';
import { useFetchOrganizationSourceTypeByIdQuery } from '../../store/api/organizationSourceTypeApi';

export default function RegistrationInvite() {
  const { sourceId } = useParams();
  const toast = useToast();
  const { setLoading } = useLoading();
  const [showError, setShowError] = useState<boolean>(false);

  const [smallDevice] = useMediaQuery('(max-width: 1400px)');

  const [userRegistration, { data, isLoading, error, isSuccess, isError }] =
    useUserRegistrationMutation();

  const { data: sourceType, isFetching } = useFetchOrganizationSourceTypeByIdQuery(sourceId ?? '', {
    skip: !sourceId,
  });

  const signUp = async (registrationData: RegistrationData) => {
    if (registrationData.termsAndConditions === true) {
      setShowError(false);
      try {
        setLoading(true);
        await userRegistration({
          accountName: registrationData.accountName,
          email: registrationData.email,
          password: registrationData.password,
          organizationSourceId: sourceId ? sourceId : null,
          acceptMarketing: registrationData.keepUpdated,
          acceptTerms: registrationData.termsAndConditions,
        });
        toast({
          title: 'Account created, verification required!',
          description: 'Please go action the email with instructions to verify your account.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        setLoading(false);
      } catch (e) {
        setLoading(false);
        toast({
          title: 'Account creation error',
          status: 'error',
          description:
            "We've run into an error creating your account, you might have an account with us already, Contact support for help",
          duration: 9000,
          isClosable: true,
        });
      }
    } else {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 10000);
    }
  };

  const getSourceText = () => {
    if (sourceType) {
      return sourceType.description;
    } else {
      return '';
    }
  };

  return (
    <Flex
      h={'100vh'}
      align={'center'}
      justify={'center'}
      position={'relative'}
      overflow={'auto'}
      bgColor={AppColors.secondary2}
    >
      <Flex w={'100%'} align={'center'} justify={'space-evenly'} height={'100%'} p={8} gap={'80px'}>
        {!smallDevice && <Image src={'./sign-up-image.png'} maxH={'620px'} maxW={'650px'} />}
        <Flex
          bgColor={'white'}
          flexDir={'column'}
          justify={'center'}
          align={'center'}
          borderRadius={'30px'}
          p={{ base: 4, sm: 6, md: 8 }}
          gap={8}
          height={'100%'}
          maxW={'800px'}
          w={'100%'}
        >
          <AppText fontWeight={'600'} variant={'h2'}>
            Create An Account
          </AppText>
          <Flex as={'form'} width={'100%'} maxW={'555px'}>
            <UserRegistrationForm onSubmit={signUp}></UserRegistrationForm>

            {showError && (
              <Alert mt={4} rounded={'md'} status="error">
                <AlertIcon />
                <AlertTitle>
                  Please read through & accept the terms & conditions to continue creating your
                  account.
                </AlertTitle>
              </Alert>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
