import React from 'react';
import DomainLayout from '../../components/layouts/domain-layout';
import { Flex, useToast } from '@chakra-ui/react';
import {
  useCreateBatchMutation,
  useFetchBatchesByDomainQuery,
  useLazyFetchBatchesByDomainQuery,
} from '../../store/api/backlinkBatchesApi';
import { selectDomain } from '../../store/slices/domain.slice';
import BatchCard from './backlink-batch-card';
import {
  AppButton,
  AppColors,
  AppLoader,
  AppText,
  useDialog,
  useLoading,
} from '@backlinkit/shared';
import BacklinkBatchForm, {
  BacklinkBatchFormData,
} from '../../components/forms/backlink-batch-form';
import { useAppSelector } from '../../store/store';
import { useParams } from 'react-router-dom';
import { Can } from '../../providers/permissions-provider';

const Batches: React.FC = () => {
  const dialog = useDialog();
  const toast = useToast();
  const { setLoading } = useLoading();
  const domain = useAppSelector(selectDomain);
  const [createBatchTrigger] = useCreateBatchMutation();
  const [getBatchesByDomain] = useLazyFetchBatchesByDomainQuery();
  const batchId = useParams();

  const {
    data: batches,
    refetch: refetchBatches,
    isLoading,
  } = useFetchBatchesByDomainQuery(domain?.id ?? '', {
    refetchOnMountOrArgChange: true,
    skip: !domain,
  });

  const handleAddDialog = () => {
    dialog({
      title: 'Create Batch',
      size: '2xl',
      showCancel: true,
      render: (onSubmit) => {
        return (
          <BacklinkBatchForm
            onSubmit={(data) => {
              handleSave(data);
              onSubmit();
            }}
          ></BacklinkBatchForm>
        );
      },
    });
  };

  const handleSave = async (formData: BacklinkBatchFormData) => {
    try {
      setLoading(true);

      await createBatchTrigger({
        domainId: domain?.id ?? '',
        name: formData.name,
        price: formData.price,
        currency: formData.currency,
        notes: formData.notes,
        batchTypeId: formData.batchTypeId,
        nicheTypeId: formData.nicheTypeId,
        tagIds: formData.tagIds,
      });

      toast({
        title: 'Batch Saved',
        description: "We've saved your batch successfully!",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      refetchBatches();
      if (domain) {
        getBatchesByDomain(domain?.id);
      }

      setLoading(false);
    } catch (error) {
      toast({
        title: 'Batch error.',
        description: "We've run into a problem saving your batch, Contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      setLoading(false);
    }
  };

  return (
    <DomainLayout>
      {isLoading && <AppLoader />}
      <Flex px={6} direction="column" w={'100%'}>
        <Flex w={'100%'} justify={'space-between'} align={'center'} px={4} mt={4}>
          <AppText fontWeight="bold" fontSize={'28px'}>
            Batches
          </AppText>

          <Can permission={'Batches'} permissionLevel="Write">
            <AppButton
              loadingText={'Adding Link'}
              onClick={handleAddDialog}
              borderRadius="full"
              height={'35px !important'}
              px={4}
              bgColor={'rgba(255, 136, 0, 0.1)'}
            >
              <AppText color={AppColors.ctaColor} fontSize={'14px'} fontWeight={'400'}>
                + Add Batch
              </AppText>
            </AppButton>
          </Can>
        </Flex>
        {domain && (
          <Flex mt={4} flexDirection={'column'} p={4}>
            {batches?.map((batch) => (
              <BatchCard
                key={`batch-card-${batch.id}`}
                batch={batch}
                domain={domain}
                refetchBatches={refetchBatches}
                showLinks={batch.id === batchId.id}
              />
            ))}
          </Flex>
        )}
      </Flex>
    </DomainLayout>
  );
};

export default Batches;
