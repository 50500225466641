import { Box, Card, Flex, Progress, Text, Image, BoxProps } from '@chakra-ui/react';
import crown from '../../assets/crown.svg';
import whiteCrown from '../../assets/white-crown.svg';
import { AppColors } from '@backlinkit/shared';

export type SubscriptionProps = {
  used: number;
  total: number;
  theme?: 'dark' | 'light';
} & BoxProps;

export const Subscription: React.FC<SubscriptionProps> = ({
  used,
  total,
  theme = 'light',
  ...props
}) => {
  const textColorPrimary = theme === 'dark' ? 'white' : AppColors.textColor;
  const textColorSecondary = theme === 'dark' ? 'white' : 'gray.400';
  return (
    <Flex
      flexDir={'column'}
      bg={theme === 'dark' ? AppColors.secondary2 : 'white'}
      px={6}
      rounded={'2xl'}
      h="100%"
      w={'100%'}
      justify={'start'}
      {...props}
    >
      <Flex align={'center'} justify={'start'} gap={5} height={'100%'}>
        <Flex
          boxSize={'60px'}
          borderRadius={'full'}
          justify={'center'}
          align={'center'}
          bgColor={theme === 'dark' ? 'transparent' : 'rgba(255, 136, 0, 0.2)'}
        >
          <Image
            src={theme === 'dark' ? whiteCrown : crown}
            boxSize={'35px'}
            objectFit={'contain'}
          />
        </Flex>
        <Flex flexDir={'column'}>
          <Text
            color={textColorPrimary}
            fontWeight="600"
            fontSize="28px"
            mt="10px"
            className="poppins-text"
          >
            Your Subscription
          </Text>
          <Text
            color={textColorSecondary}
            fontSize="16px"
            maxW={{ base: '100%', xl: '80%', '3xl': '60%' }}
            className="poppins-text"
          >
            Amount of links used
          </Text>
        </Flex>
      </Flex>
      <Box w="100%" height={'100%'}>
        <Flex w="100%" justify="space-between" mb="10px">
          <Text color={textColorSecondary} fontSize="sm" maxW="40%">
            {used}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" maxW="40%">
            {total}
          </Text>
        </Flex>
        <Progress
          alignItems="start"
          value={(used / total) * 100}
          minW="100%"
          borderRadius={'full'}
          color={theme === 'light' ? 'blue' : 'white'}
          bgColor={theme === 'dark' ? 'rgba(255, 255, 255, 0.2))' : 'rgba(3, 125, 252, 0.08)'}
        />
      </Box>
    </Flex>
  );
};
