import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  BoxProps,
  Button,
  Flex,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { AppText } from '../app-text';
import { AppColors } from '../../theme';

export type AppTabsProps = {
  titles: string[];
  tabs: ReactNode[];
  panelHeight?: string;
} & BoxProps;

const AppTabs: React.FC<AppTabsProps> = ({ titles, tabs, panelHeight = '50vh' }) => {
  return (
    <Tabs>
      <TabList
        borderBottom={'none'}
        borderTopRadius={'2xl'}
        bgColor={AppColors.secondary2}
        p={4}
        minH={'105px'}
      >
        <Flex width={'100%'} align={'center'} gap={4}>
          {titles.map((title, index) => (
            <Tab
              key={`${title}-${index}`}
              as={Button}
              minW={'150px'}
              height={'50px'}
              _selected={{
                bgColor: AppColors.ctaColor,
                border: 'none',
                fontWeight: '500',
              }}
              _hover={{
                bgColor: AppColors.ctaColor,
                color: 'white',
                transform: 'translateY(-2px)',
                transition: '0.3s all',
              }}
              bgColor={'transparent'}
              borderRadius={'full'}
              border={'1px solid rgba(255, 255, 255, 0.5)'}
              color={'white'}
              fontWeight={'400'}
            >
              <AppText fontWeight={'600'}>{title}</AppText>
            </Tab>
          ))}
        </Flex>
      </TabList>
      <TabPanels h={panelHeight} overflow={'auto'}>
        {tabs.map((tab, index) => (
          <TabPanel key={index}>{tab}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default AppTabs;
