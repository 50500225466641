import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { RoleDto } from '@backlinkit/shared';

const baseUrl = '/role/portal';

export const roleApi = createApi({
  reducerPath: 'roleApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchRoles: build.query<RoleDto[], any>({
      query: () => ({
        url: baseUrl,
        method: 'GET',
      }),
      transformResponse: (response: RoleDto[]) => {
        return response;
      },
    }),
  }),
});

export const { useFetchRolesQuery } = roleApi;
