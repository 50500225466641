import React, { useState, useEffect } from 'react';
import { Box, Flex, FormControl, Icon, Stack, Tooltip } from '@chakra-ui/react';
import { BaseFormProps } from './base-forms';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AppAlertItem,
  AppButton,
  AppColors,
  AppInput,
  SelectListFilter,
  SelectOption,
  useDialog,
} from '@backlinkit/shared';
import { useAppSelector } from '../../store/store';
import { selectOrganizationTags } from '../../store/slices/tag.slice';
import { RiSettings5Fill } from 'react-icons/ri';
import TagManagement from '../dialog-management/tag-management';
import { useClientDialog } from '../../providers/client-dialog/use-client-dialog';
import { iconHelper } from '../../utils/iconHelper';

export type BacklinkReportFormData = {
  emailAddress: string;
  tagId: string;
  notes?: string;
};

const BacklinkReportFormDefaultValues: BacklinkReportFormData = {
  emailAddress: '',
  tagId: '',
  notes: '',
};

const BacklinkReportFormDataSchema = yup.object({
  emailAddress: yup.string().required('Field is required'),
  tagId: yup.string(),
  notes: yup.string(),
});

type BacklinkReportFormProps<T> = {
  form?: BacklinkReportFormData;
} & BaseFormProps<T>;

const ExternalReportForm: React.FC<BacklinkReportFormProps<BacklinkReportFormData>> = ({
  form,
  onSubmit,
}) => {
  const clientDialog = useClientDialog();
  const [warning, setWarning] = useState<boolean>(false);
  const {
    control: backlinkReportControl,
    formState: { isValid, errors },
    setValue,
    getValues,
    handleSubmit,
  } = useForm<BacklinkReportFormData>({
    defaultValues: form || BacklinkReportFormDefaultValues,
    resolver: yupResolver(BacklinkReportFormDataSchema),
    mode: 'all',
  });
  const tags = useAppSelector(selectOrganizationTags);
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (tags) {
      const selectors = tags.map((x) => {
        return { label: x.name, value: x.id } as SelectOption;
      });
      setSelectOptions(selectors);
    }
  }, [tags]);

  const handleSelect = (option: SelectOption) => {
    setValue('tagId', option.value);
  };

  const handleTagManagement = () => {
    clientDialog({
      title: 'Tag Management',
      position: 3,
      size: '4xl',
      render: (onSubmit) => {
        return <TagManagement />;
      },
    });
  };

  const handleFormSubmit = () => {
    const formData = getValues();
    if (!formData.tagId || formData.tagId.length < 1) {
      setWarning(true);
    } else {
      onSubmit && onSubmit(formData);
    }
  };

  return (
    <form>
      <Stack spacing={4}>
        <AppInput<BacklinkReportFormData>
          name="emailAddress"
          control={backlinkReportControl}
          label="Email"
          error={errors.emailAddress}
          variant={'grayed'}
        />
        <AppInput<BacklinkReportFormData>
          name="notes"
          control={backlinkReportControl}
          label="Notes"
          textArea={true}
          error={errors.notes}
          variant={'grayed'}
        />
        <FormControl>Tag:</FormControl>
        <Flex w={'100%'} gap={'10px'}>
          <SelectListFilter
            name="tag"
            w={'100%'}
            options={selectOptions}
            onSelectionChange={(value: SelectOption[]) => {
              handleSelect(value[0]);
            }}
            isInModal
            isMulti={false}
            showClear={false}
          />
          <Tooltip shouldWrapChildren label={'Manage Tags'} aria-label="manageTags">
            <AppButton
              px={3}
              bgColor={AppColors.appBackground}
              onClick={handleTagManagement}
              borderRadius={'xl'}
            >
              <Icon as={iconHelper.settingsIcon} boxSize={'20px'} color={'gray.500'} />
            </AppButton>
          </Tooltip>
        </Flex>
        <AppButton isDisabled={!isValid} onClick={handleFormSubmit} bgColor={AppColors.ctaColor} color={'white'}>
          Save
        </AppButton>
        {warning && (
          <Box mt={'10px'}>
            <AppAlertItem
              item={{
                name: 'Tag',
                nameLabel: undefined,
                alertType: 'warning',
                alertMessage: 'Field is required',
                alertMessageLabel: undefined,
                alertNote: undefined,
                alertNoteLabel: undefined,
                entityId: undefined,
                alertKey: undefined,
              }}
            />
          </Box>
        )}
      </Stack>
    </form>
  );
};

export default ExternalReportForm;
