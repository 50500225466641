import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import { Subscription } from './subscription';
import Domains from '../../components/domains/domains';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/authentication.slice';
import { selectDomains } from '../../store/slices/domain.slice';
import { useEffect, useState } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import { AppButton, AppColors, getSumOfNumbers } from '@backlinkit/shared';
import usePayments from '../../hooks/usePayments';
import { useLazyFetchDomainsByOrgIdQuery } from '../../store/api/domainApi';
import { ProfileCard } from './profile-card';
import { Can } from '../../providers/permissions-provider';

export type DomainStats = {
  domainCount: number;
  linkCount: number;
  errorCount: number;
};

const Dashboard: React.FC = () => {
  const user = useSelector(selectUser);
  const domains = useSelector(selectDomains);
  const [getDomainsByOrgId] = useLazyFetchDomainsByOrgIdQuery();
  const { handlePlanSelect } = usePayments();
  const [smallScreen] = useMediaQuery('(max-width: 1400px)');

  const [domainStats, setDomainStats] = useState<DomainStats>();
  // const { data: faqs } = useFetchFeaturedFaqsQuery({ refetchOnMountArgChange: true });
  useEffect(() => {
    if (user && user.organizationId) {
      getDomainsByOrgId(user.organizationId);
    }
  }, [user]);

  useEffect(() => {
    if (domains) {
      const backLinkCountList = domains.map((x) => x.backlinkCount);
      const errorCountList = domains.map((x) => x.backlinkErrorCount);
      const domainStatsData = {
        domainCount: domains.length,
        linkCount: getSumOfNumbers(backLinkCountList),
        errorCount: getSumOfNumbers(errorCountList),
      };

      setDomainStats(domainStatsData);
    }
  }, [domains]);

  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });

  return (
    <Box p={'20px'} w="100%">
      <Flex flexDir={'column'} h="100%" w="100%" justify={'space-between'} gap={6}>
        <Flex
          width={'100%'}
          align={'center'}
          justify={'space-between'}
          h={'100%'}
          maxH={{ md: 'auto', lg: '280px' }}
          gap={6}
          flexDir={{ sm: 'column', lg: 'row' }}
        >
          <ProfileCard
            organization={user?.organization}
            maxW={{ sm: '100%', lg: '50%', xl: '35%' }}
            h={'100%'}
            links={domainStats ? domainStats.linkCount : 0}
            domains={domainStats ? domainStats.domainCount : 0}
            errors={domainStats ? domainStats.errorCount : 0}
          />
          <Flex
            flexDir={'column'}
            maxW={{ sm: '100%', lg: '50%', xl: '65%' }}
            width={'100%'}
            justify={'center'}
            bgColor={'white'}
            p={4}
            borderRadius={'3xl'}
            height={'100%'}
          >
            <Subscription
              h={'100%'}
              used={domainStats ? domainStats.linkCount : 0}
              total={user?.organization?.product?.linkLimit ?? 0}
            />
            <Can permission={'OrganizationBilling'} permissionLevel="Write">
              <AppButton
                borderRadius={'full'}
                size={'md'}
                bgColor={AppColors.ctaColor}
                color={'white'}
                // isDisabled={true}
                onClick={() => {
                  handlePlanSelect();
                }}
              >
                Plans
              </AppButton>
            </Can>
          </Flex>
        </Flex>
        <Flex h="100%" maxH={'80%'} flexDir={'column'} bg={'white'} rounded={'3xl'}>
          {/* <Can permission={'OrganizationBilling'} permissionLevel="Read"> */}
          <Domains />
          {/* </Can> */}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Dashboard;
