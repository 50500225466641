import { useEffect, useState } from 'react';
import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import {
  useFetchUsersByOrganizationIdQuery,
  useUserCreateMutation,
  useUserDeleteMutation,
  useUserUpdateMutation,
} from '../../../store/api/userApi';
import { selectUser } from '../../../store/slices/authentication.slice';
import UserCreateForm, { UserCreateFormData } from '../../forms/user-create-form';
import UserEditForm, { UserEditFormData } from '../../forms/edit-user-form';
import {
  AppColors,
  AppDataTable,
  AppText,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  OrganizationUser,
  PageChangeEvent,
  Pagination,
  SearchParam,
  SortParam,
  TableSearchEventProps,
  searchingColumnsToSearchParam,
  sortingColumnsToSortingArray,
  useDialog,
} from '@backlinkit/shared';
import { ChevronDownIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import { Can } from '../../../providers/permissions-provider';

const useDataTable = <T extends {}>() => {
  const [currentSortList, setSortList] = useState<SortParam[]>([]);
  const [searchList, setSearchList] = useState<SearchParam>();
  const [selected, setSelected] = useState<OrganizationUser[]>();
  const [pagination, setPagination] = useState<Pagination>();
  const [currentPage, setCurrentPage] = useState<number | undefined>();
  const [currentPageSize, setPageSize] = useState<number | undefined>();

  const handleUserSearchChange = (e: TableSearchEventProps<T>) => {
    const searchObjects = searchingColumnsToSearchParam(e);
    setSearchList(searchObjects);
  };

  const handleSortByColumnClick = (columns: ColumnDefinitionType<T>[]) => {
    const columnSortQueryString = sortingColumnsToSortingArray(columns);
    setSortList(columnSortQueryString);
  };

  const handleOnPageChange = (pageChangeEvent: PageChangeEvent) => {
    console.log(pageChangeEvent);
    setCurrentPage(pageChangeEvent.currentPage);
    setPageSize(pageChangeEvent.currentPageSize);
  };

  const nextPaginationQueryParams = {
    page: currentPage,
    pageSize: currentPageSize,
    sortList: currentSortList,
    searchList: searchList,
  };

  return {
    handleUserSearchChange,
    handleSortByColumnClick,
    handleOnPageChange,
    pagination,
    setPagination,
    nextPaginationQueryParams,
    selected,
  };
};

const UsersTable: React.FC = () => {
  const user = useSelector(selectUser);
  const [saveUserTrigger] = useUserCreateMutation();
  const [editUserTrigger] = useUserUpdateMutation();
  const [deleteUserTrigger] = useUserDeleteMutation();

  const dialog = useDialog();
  const toast = useToast();

  const { data: users, refetch: refetchUsers } = useFetchUsersByOrganizationIdQuery(
    user?.organizationId ?? '',
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const renderDefaultTableActions = (item: OrganizationUser) => {
    return (
      <Can permission={'OrganizationUsers'} permissionLevel="Write">
        <Menu>
          <MenuButton
            bgColor={'white'}
            color={'gray.700'}
            border={`1px solid ${AppColors.appBorder}`}
            as={Button}
            rightIcon={<ChevronDownIcon color={'gray.700'} boxSize={'15px'} />}
            borderRadius="full"
            minH={'40px'}
            fontWeight={'400'}
          >
            Actions
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => {
                handleEditDialog(item);
              }}
              _hover={{
                bgColor: 'transparent',
              }}
            >
              <Flex
                align={'center'}
                w={'100%'}
                gap={4}
                p={1}
                borderRadius={'md'}
                _hover={{
                  bgColor: AppColors.appBackground,
                }}
              >
                <Flex p={1} bgColor={'rgba(0, 0, 0, 0.2)'} borderRadius={'md'}>
                  <SettingsIcon />
                </Flex>
                Edit
              </Flex>
            </MenuItem>
            {user?.id !== item.id && (
              <MenuItem
                onClick={() => {
                  handleDeleteUser(item);
                }}
                _hover={{
                  bgColor: 'transparent',
                }}
              >
                <Flex
                  align={'center'}
                  gap={4}
                  p={1}
                  width={'100%'}
                  _hover={{
                    bgColor: AppColors.appBackground,
                  }}
                  borderRadius={'md'}
                >
                  <Flex p={1} bgColor={'rgba(0, 0, 0, 0.2)'} borderRadius={'md'}>
                    <DeleteIcon />
                  </Flex>
                  Delete
                </Flex>
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Can>
    );
  };

  const renderRole = (item: OrganizationUser) => {
    return (
      <Flex
        borderRadius="full"
        bg={AppColors.primary}
        color={'white'}
        p={2}
        justify={'center'}
        align={'center'}
        maxW={'100px'}
      >
        <AppText color={'white'} fontSize={'14px'}>
          {item.role}
        </AppText>
      </Flex>
    );
  };

  const userColumns: ColumnDefinitionType<OrganizationUser>[] = [
    {
      type: ColumnSpecialTypes.date,
      key: 'dateCreated',
      header: 'Date Added',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'role',
      header: 'Role',
      headerSortable: true,
      columnSearchable: true,
      render: (item) => renderRole(item),
    },
    {
      key: 'email',
      header: 'e-mail',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'Actions',
      render: renderDefaultTableActions,
    },
  ];

  const [tableColumns, setTableColumns] =
    useState<ColumnDefinitionType<OrganizationUser>[]>(userColumns);

  const handleEditDialog = (selectedUser: OrganizationUser) => {
    const isOwner = user?.organization.createdUserId === selectedUser.id;
    const UserForm: UserEditFormData = {
      id: selectedUser.id.toString(),
      email: selectedUser.email,
      roleId: selectedUser.roleId,
      organizationId: user!.organizationId,
    };
    dialog({
      title: 'Edit User',
      showCancel: true,
      render: (onSubmit) => {
        return (
          <UserEditForm
            isOwner={isOwner}
            form={UserForm}
            onSubmit={async (formData) => {
              onSubmit();
              await upsertUser(formData);
            }}
          ></UserEditForm>
        );
      },
    });
  };

  const handleAddDialog = () => {
    dialog({
      title: 'Create User',
      showCancel: true,
      render: (onSubmit) => {
        return (
          <UserCreateForm
            onSubmit={async (formData) => {
              onSubmit();
              await upsertUser(formData);
            }}
          ></UserCreateForm>
        );
      },
    });
  };

  const upsertUser = async (formData: any) => {
    let edittedUser = formData.id ? true : false;
    try {
      if (!edittedUser) {
        const createFormData = formData as UserCreateFormData;
        await saveUserTrigger({
          email: createFormData.email,
          password: createFormData.password,
          roleId: createFormData.roleId,
          organizationId: user!.organizationId,
        }).unwrap();
      } else {
        const editFormData = formData as UserEditFormData;
        await editUserTrigger({
          id: editFormData.id ?? '',
          email: editFormData.email,
          roleId: editFormData.roleId,
          organizationId: user!.organizationId,
        }).unwrap();
        edittedUser = true;
      }

      toast({
        title: `User ${!edittedUser ? 'added' : 'updated'}`,
        description: `We've ${
          !edittedUser ? 'added' : 'updated'
        } your user for you, come back soon to monitor the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      refetchUsers();
    } catch (err) {
      if (edittedUser) {
        toast({
          title: `User updating. error= ${err}`,
          description: `We've run into a problem updating your user, Contact support for help`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: `User already exists in the system.`,
          description: `Please change the email address & try again`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    }
    refetchUsers();
  };

  const handleDeleteUser = async (users: OrganizationUser) => {
    try {
      const deletePromise = deleteUserTrigger(users.id).unwrap();
      await deletePromise;
    } catch (err) {
      toast({
        title: 'User Delete Error',
        description: `We've run into a problem deleting the selected user. Contact support for help`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }

    toast({
      title: 'User deleted',
      description: `We've deleted the user you selected`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });

    refetchUsers();
  };

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  useEffect(() => {
    console.log('users in table', users);
  }, [users]);

  return (
    <>
      <Flex justifyContent={'space-between'} m={4}>
        <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
          {'Users'}
        </Text>
        <Flex alignItems={'center'}>
          <Can permission={'OrganizationBilling'} permissionLevel="Write">
            <Button
              onClick={handleAddDialog}
              size="md"
              height={'50px'}
              borderRadius={'full'}
              bgColor={'white'}
              border={'1px solid rgba(255, 136, 0, 0.5)'}
              color={AppColors.ctaColor}
              fontWeight={'500'}
            >
              + Add User
            </Button>
          </Can>
        </Flex>
      </Flex>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        rounded={'2xl'}
        m={4}
        flexDir={'column'}
        flex={1}
      >
        <AppDataTable
          data={users || []}
          noDataMessage={'No users added, start by adding some!'}
          columns={tableColumns}
          selectableRows={false}
        />
      </Flex>
    </>
  );
};

export default UsersTable;
