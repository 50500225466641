import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AppButton,
  AppColors,
  AppConfirm,
  AppText,
  LoadingComponent,
  SelectListFilter,
  SelectOption,
  useDialog,
} from '@backlinkit/shared';
import { BaseFormProps } from './base-forms';
import { Flex, FormLabel, Progress, Stack } from '@chakra-ui/react';
import { useFetchAllOrganizationBudgetTypesQuery } from '../../store/api/organizationBudgetTypeApi';
import { useFetchAllOrganizationLinkBuildingStategiesTypesQuery } from '../../store/api/organizationLinkBuilidingStrategiesTypeApi';
import { useFetchAllOrganizationSEOExperienceTypesQuery } from '../../store/api/organizationSEOExperienceTypeApi';
import { useFetchAllOrganizationTypesQuery } from '../../store/api/organizationTypeApi';
import { useFetchAllOrganizationNewFeatureTypesQuery } from '../../store/api/organizationNewFeatureApi';
import { useFetchAllOrganizationLinkEstimationTypesQuery } from '../../store/api/organizationLinkEstimationTypeApi';
import { useClientDialog } from '../../providers/client-dialog/use-client-dialog';

export type OrganizationDetailFormData = {
  organizationBudgetTypeId?: string;
  organizationLinkBuildingStratergiesTypeId?: string;
  organizationSEOExperienceTypeId?: string;
  organizationTypeId?: string;
  organizationNewFeatureTypeId?: string;
  organizationLinkEstimationTypeId?: string;
};

export const OrganizationDetailFormValues: OrganizationDetailFormData = {
  organizationBudgetTypeId: undefined,
  organizationLinkBuildingStratergiesTypeId: undefined,
  organizationSEOExperienceTypeId: undefined,
  organizationTypeId: undefined,
  organizationNewFeatureTypeId: undefined,
  organizationLinkEstimationTypeId: undefined,
};

export const OrganizationDetailDataSchema = yup.object({
  organizationBudgetTypeId: yup.string(),
  organizationLinkBuildingStratergiesTypeId: yup.string(),
  organizationSEOExperienceTypeId: yup.string(),
  organizationTypeId: yup.string().required('Field is required'),
  organizationNewFeatureTypeId: yup.string(),
  organizationLinkEstimationTypeId: yup.string(),
});

type OrganizationDetailFormProps<T> = {
  form?: OrganizationDetailFormData;
} & BaseFormProps<T>;

const OrganizationDetailForm: React.FC<OrganizationDetailFormProps<OrganizationDetailFormData>> = ({
  form,
  onSubmit,
}) => {
  const clientDialog = useClientDialog();

  const {
    formState: { isValid },
    setValue,
    getValues,
  } = useForm<OrganizationDetailFormData>({
    defaultValues: form || OrganizationDetailFormValues,
    resolver: yupResolver(OrganizationDetailDataSchema),
    mode: 'onChange',
  });

  const [organizationBudgetTypeOptions, setOrganizationBudgetTypeOptions] =
    useState<SelectOption[]>();
  const [
    organizationLinkBuildingStategiesTypeOptions,
    setOrganizationLinkBuildingStategiesTypeOptions,
  ] = useState<SelectOption[]>();
  const [organizationTypeOptions, setOrganizationTypeOptions] = useState<SelectOption[]>();
  const [organizationSEOExperienceTypeOptions, setOrganizationSEOExperienceTypeOptions] =
    useState<SelectOption[]>();
  const [organizationNewFeatureTypeOptions, setOrganizationNewFeatureTypeOptions] =
    useState<SelectOption[]>();
  const [organizationLinkEstimationTypeOptions, setOrganizationLinkEstimationTypeOptions] =
    useState<SelectOption[]>();

  const { data: organizationBudgetTypes, isFetching: isFetchingOrganizationBudgetTypes } =
    useFetchAllOrganizationBudgetTypesQuery({
      refetchOnMountArgChange: true,
    });
  const {
    data: organizationLinkBuildingStategiesTypes,
    isFetching: isFetchingOrganizationLinkBuildingStategiesTypes,
  } = useFetchAllOrganizationLinkBuildingStategiesTypesQuery({
    refetchOnMountArgChange: true,
  });
  const { data: organizationTypes, isFetching: isFetchingOrganizationTypes } =
    useFetchAllOrganizationTypesQuery({
      refetchOnMountArgChange: true,
    });
  const {
    data: organizationSEOExperienceTypes,
    isFetching: isFetchingOrganizationSEOExperienceTypes,
  } = useFetchAllOrganizationSEOExperienceTypesQuery({
    refetchOnMountArgChange: true,
  });
  const { data: organizationNewFeatureTypes, isFetching: isFetchingOrganizationNewFeatureTypes } =
    useFetchAllOrganizationNewFeatureTypesQuery({
      refetchOnMountArgChange: true,
    });

  const {
    data: organizationLinkEstimationTypes,
    isFetching: isFetchingOrganizationLinkEstimationTypes,
  } = useFetchAllOrganizationLinkEstimationTypesQuery({
    refetchOnMountArgChange: true,
  });

  useEffect(() => {
    if (organizationBudgetTypes) {
      const selectors: SelectOption[] = [];
      organizationBudgetTypes?.map((x) => {
        selectors.push({
          label: x.name,
          value: x.id,
        });
      });
      setOrganizationBudgetTypeOptions(selectors);
    }
  }, [organizationBudgetTypes]);

  useEffect(() => {
    if (organizationLinkBuildingStategiesTypes) {
      const selectors: SelectOption[] = [];
      organizationLinkBuildingStategiesTypes?.map((x) => {
        selectors.push({
          label: x.name,
          value: x.id,
        });
      });
      setOrganizationLinkBuildingStategiesTypeOptions(selectors);
    }
  }, [organizationLinkBuildingStategiesTypes]);

  useEffect(() => {
    if (organizationTypes) {
      const selectors: SelectOption[] = [];
      organizationTypes?.map((x) => {
        selectors.push({
          label: x.name,
          value: x.id,
        });
      });
      setOrganizationTypeOptions(selectors);
    }
  }, [organizationTypes]);

  useEffect(() => {
    if (organizationSEOExperienceTypes) {
      const selectors: SelectOption[] = [];
      organizationSEOExperienceTypes?.map((x) => {
        selectors.push({
          label: x.name,
          value: x.id,
        });
      });
      setOrganizationSEOExperienceTypeOptions(selectors);
    }
  }, [organizationSEOExperienceTypes]);

  useEffect(() => {
    if (organizationNewFeatureTypes) {
      const selectors: SelectOption[] = [];
      organizationNewFeatureTypes?.map((x) => {
        selectors.push({
          label: x.name,
          value: x.id,
        });
      });
      setOrganizationNewFeatureTypeOptions(selectors);
    }
  }, [organizationNewFeatureTypes]);

  useEffect(() => {
    if (organizationLinkEstimationTypes) {
      const selectors: SelectOption[] = [];
      organizationLinkEstimationTypes?.map((x) => {
        selectors.push({
          label: x.name,
          value: x.id,
        });
      });
      setOrganizationLinkEstimationTypeOptions(selectors);
    }
  }, [organizationLinkEstimationTypes]);

  const handleFormSubmit = () => {
    clientDialog({
      title: 'Almost there!',
      render: (onCancel) => {
        return (
          <AppConfirm
            message={`Your input helps us create a more tailored experience just for you. Are you happy with your selection?`}
            confirmText="Yes, let's start"
            cancelText="Edit my selections"
            onConfirm={async () => {
              onCancel();

              const formData = getValues();

              const details: OrganizationDetailFormData = {
                organizationBudgetTypeId: formData.organizationBudgetTypeId,
                organizationLinkBuildingStratergiesTypeId:
                  formData.organizationLinkBuildingStratergiesTypeId,
                organizationSEOExperienceTypeId: formData.organizationSEOExperienceTypeId,
                organizationTypeId: formData.organizationTypeId,
              };
              onSubmit(details);
            }}
            onCancel={() => {
              onCancel();
            }}
          ></AppConfirm>
        );
      },
    });
  };

  return (
    <Flex width={'full'}>
      {(isFetchingOrganizationBudgetTypes ||
        isFetchingOrganizationLinkBuildingStategiesTypes ||
        isFetchingOrganizationSEOExperienceTypes ||
        isFetchingOrganizationTypes ||
        isFetchingOrganizationNewFeatureTypes ||
        isFetchingOrganizationLinkEstimationTypes) && <LoadingComponent />}
      <Stack spacing={4} width={'full'}>
        <Progress colorScheme="green" size="sm" value={80} />
        <FormLabel p={0} m={0}>
          What is the primary purpose of your account?
        </FormLabel>

        <SelectListFilter
          name="organizationType"
          options={organizationTypeOptions}
          isInModal
          showClear={false}
          onSelectionChange={(items: SelectOption[]) => {
            setValue('organizationTypeId', items[0].value, { shouldValidate: true });
          }}
        />
        <FormLabel m={0} p={0}>
          Which of the following link types best suits your link building strategy?
        </FormLabel>
        <SelectListFilter
          name="organizationLinkBuildingStratergiesTypeId"
          options={organizationLinkBuildingStategiesTypeOptions}
          isInModal
          showClear={false}
          onSelectionChange={(items: SelectOption[]) => {
            setValue('organizationLinkBuildingStratergiesTypeId', items[0].value);
          }}
        />
        <FormLabel m={0} p={0}>
          How many years of experience do you have in acquiring backlinks?
        </FormLabel>
        <SelectListFilter
          name="organizationSEOExperienceTypeId"
          options={organizationSEOExperienceTypeOptions}
          isInModal
          showClear={false}
          onSelectionChange={(items: SelectOption[]) => {
            setValue('organizationSEOExperienceTypeId', items[0].value);
          }}
        />
        <FormLabel m={0} p={0}>
          How many editorial, contextual, or sponsored backlinks do you typically acquire each month
          for you or your clients?
        </FormLabel>
        <SelectListFilter
          name="organizationLinkEstimationTypeId"
          options={organizationLinkEstimationTypeOptions}
          isInModal
          showClear={false}
          onSelectionChange={(items: SelectOption[]) => {
            setValue('organizationLinkEstimationTypeId', items[0].value);
          }}
        />
        <FormLabel m={0} p={0}>
          What is your average monthly spend on acquiring backlinks for you or your clients?
        </FormLabel>
        <SelectListFilter
          name="organizationBudgetTypeId"
          options={organizationBudgetTypeOptions}
          isInModal
          showClear={false}
          onSelectionChange={(items: SelectOption[]) => {
            setValue('organizationBudgetTypeId', items[0].value);
          }}
        />
        <FormLabel m={0} p={0}>
          Which of the following link monitoring features would be a 'game changer' for you?
        </FormLabel>
        <SelectListFilter
          name="organizationNewFeatureTypeId"
          options={organizationNewFeatureTypeOptions}
          isInModal
          showClear={false}
          onSelectionChange={(items: SelectOption[]) => {
            setValue('organizationNewFeatureTypeId', items[0].value);
          }}
        />
        <AppButton
          mt={2}
          variant={'solid'}
          bgColor={AppColors.secondary}
          color={'white'}
          onClick={handleFormSubmit}
          isDisabled={!isValid}
        >
          Save
        </AppButton>
      </Stack>
    </Flex>
  );
};

export default OrganizationDetailForm;
