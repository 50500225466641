import { Flex, Stack, Text, Box, Icon, useBreakpointValue, SimpleGrid } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { AppButton, ProductDto, AppColors } from '@backlinkit/shared';

export interface BannerProps {
  products: ProductDto[];
  organizationProductId?: string;
  onPlanSelect: (data: ProductDto) => void;
}

export const AppPriceBannerCards: React.FC<BannerProps> = ({
  products,
  organizationProductId,
  onPlanSelect,
}) => {
  const cardGap = useBreakpointValue({
    base: 8,
    lg: 2,
    xl: 8,
  });

  const getColor = (x: ProductDto): string => (x.id === organizationProductId ? 'white' : 'black');

  return (
    <SimpleGrid columns={1} gap={cardGap} w={'100%'}>
      {products.map((product) => {
        return (
          <Flex
            key={product.id}
            justify={'space-between'}
            flexDir={{ base: 'column', lg: 'row' }}
            pos={'relative'}
            boxShadow={'xl'}
            gap={{ base: 3, lg: 8 }}
            w={'100%'}
            flex={1}
            direction={'row'}
            borderRadius={'2xl'}
            border={
              product.id === organizationProductId
                ? `1.5px solid ${AppColors.secondary2}`
                : `1px solid ${AppColors.appBorder}`
            }
            bgColor={product.id === organizationProductId ? AppColors.secondary2 : 'white'}
            p={6}
          >
            <Flex
              flexDir={'column'}
              align={'start'}
              bgColor={product.id === organizationProductId ? `${AppColors.secondary2}` : `white`}
              pos={'relative'}
              pb={4}
              gap={4}
              w={{ base: '100%', lg: '35%' }}
            >
              <Flex gap={3} align={'center'}>
                <Box
                  height={'35px'}
                  width={'3px'}
                  bgColor={product.id === organizationProductId ? 'white' : AppColors.ctaColor}
                />
                <Text
                  fontWeight={'600'}
                  textAlign={'center'}
                  fontSize={{ base: '18px', lg: '24px' }}
                  color={product.id === organizationProductId ? 'white' : AppColors.ctaColor}
                >
                  {product.name}
                </Text>
              </Flex>
              <Flex align={'center'} justify={'center'} pos={'relative'} gap={2}>
                {product.price > 0 && (
                  <Text
                    pos={'absolute'}
                    color={product.id === organizationProductId ? 'white' : 'black'}
                    left={0}
                    top={0}
                  >
                    $
                  </Text>
                )}
                <Text
                  textAlign={'center'}
                  fontSize={'2xl'}
                  fontWeight={'700'}
                  color={getColor(product)}
                  marginLeft={'15px'}
                >
                  {`${product.price}/`}
                </Text>
                <Text fontWeight={'500'} color={getColor(product)} mt={2}>
                  {'Monthly'}
                </Text>
              </Flex>
              <Box width={'100%'}>
                <AppButton
                  borderRadius={'full'}
                  borderColor={`${AppColors.secondary2} !important`}
                  _hover={{
                    transform: 'translateY(-2px)',
                    boxShadow: 'lg',
                  }}
                  color={'white'}
                  bgColor={
                    product.id === organizationProductId ? AppColors.ctaColor : AppColors.secondary2
                  }
                  onClick={() => onPlanSelect(product)}
                >
                  Subscribe
                </AppButton>
              </Box>
            </Flex>
            <Box w={'2px'} height={'50%'} bgColor={AppColors.appBackground} />
            <Stack spacing={3} w={{ base: '100%', lg: '65%' }}>
              <SimpleGrid spacing={3} columns={{ base: 1, xl: 2 }}>
                <Flex align={'center'} gap={3}>
                  <Icon
                    as={CheckCircleIcon}
                    color={product.id === organizationProductId ? 'white' : AppColors.secondary2}
                  />
                  <Text color={getColor(product)}>{`${product.linkLimit} links`}</Text>
                </Flex>
                <Flex align={'center'} gap={3}>
                  <Icon
                    as={CheckCircleIcon}
                    color={product.id === organizationProductId ? 'white' : AppColors.secondary2}
                  />
                  <Text color={getColor(product)}>{`${product.userLimit} User Seats`}</Text>
                </Flex>
                <Flex align={'center'} gap={3}>
                  <Icon
                    as={CheckCircleIcon}
                    color={product.id === organizationProductId ? 'white' : AppColors.secondary2}
                  />
                  <Text color={getColor(product)}>{`${product.domainLimit} Domains`}</Text>
                </Flex>
                <Flex align={'center'} gap={3}>
                  <Icon
                    as={CheckCircleIcon}
                    color={product.id === organizationProductId ? 'white' : AppColors.secondary2}
                  />
                  <Text color={getColor(product)}>{`${product.freeCredits} Free Credits`}</Text>
                </Flex>
              </SimpleGrid>
            </Stack>
          </Flex>
        );
      })}
    </SimpleGrid>
  );
};
