import { Flex } from '@chakra-ui/react';
import DomainLayout from '../../components/layouts/domain-layout';
import {
  AppPaginator,
  BackLink,
  BackLinkListItem,
  BacklinkFilter,
  BacklinkFormData,
  ColumnDefinitionType,
  DomainViewModel,
  PageChangeEvent,
  SearchParam,
} from '@backlinkit/shared';
import useBacklinks from '../../hooks/useBacklinks';
import { useLocation } from 'react-router-dom';
import { selectDomain, selectDomains } from '../../store/slices/domain.slice';
import { useAppSelector } from '../../store/store';
import AppBacklinksTable from '../../components/app/app-backlinks-table-component/backlink-performance-table';
import { useEffect } from 'react';

const Links: React.FC = () => {
  const { state } = useLocation();
  const domain = useAppSelector(selectDomain);
  const {
    domainBacklinks,
    tempBacklinks,
    tempGoogleData,
    menuItems,
    addBacklink,
    editBacklink,
    handleSearchChange,
    handleSortByColumnClick,
    handleFiltersChange,
    handleBacklinksRescrape,
    handleBacklinkGoogleRescrapeProcess,
    handleBackButton,
    refreshBacklinks,
    pagination,
    handleOnPageChange,
    handleBacklinkExternalReport,
    handleDeleteBacklinkClicked,
  } = useBacklinks({ filter: { hasErrors: state && state.hasErrors ? true : undefined } });

  const handleAddLinks = async (
    backlinkFormsData: BacklinkFormData[],
    currentDomain: DomainViewModel,
    tagIds: string[],
    batchPrice?: number,
    batch?: string | undefined
  ) => {
    await addBacklink(backlinkFormsData, currentDomain, tagIds, batchPrice, batch);
  };

  const handleEditLinks = async (
    currentBacklink: BackLink,
    backlinkForm: BacklinkFormData,
    currentDomain: DomainViewModel,
    tagIds: string[],
    isNoteEdit?: boolean | undefined,
    isPriceEdit?: boolean | undefined
  ) => {
    await editBacklink(
      currentBacklink,
      backlinkForm,
      currentDomain,
      tagIds,
      isNoteEdit,
      isPriceEdit
    );
  };

  useEffect(() => {
    console.log('this is the domain', domain)
  }, [domain])

  return (
    <DomainLayout>
      <Flex px={6} flex={1} direction="column" width={'100%'}>
        <AppBacklinksTable
          currentDomain={domain as DomainViewModel}
          title={'Links'}
          parentUrl={domain?.url || ''}
          data={domainBacklinks?.data || []}
          tempBacklinkCount={tempBacklinks && tempBacklinks.length}
          tempGoogleDataCount={tempGoogleData && tempGoogleData.length}
          additionalBacklinkColumns={[]}
          additionalMenuItems={menuItems}
          isBatchCreateDisabled={false}
          onAddClick={(
            backlinkFormsData: BacklinkFormData[],
            currentDomain: DomainViewModel,
            tagIds: string[],
            batchPrice?: number | undefined,
            batch?: string | undefined
          ) => handleAddLinks(backlinkFormsData, currentDomain, tagIds, batchPrice, batch)}
          onEditBacklink={(
            currentBacklink: BackLink,
            backlinkForm: BacklinkFormData,
            currentDomain: DomainViewModel,
            tags: string[],
            isNoteEdit?: boolean | undefined,
            isPriceEdit?: boolean | undefined
          ) =>
            handleEditLinks(
              currentBacklink,
              backlinkForm,
              currentDomain,
              tags,
              isNoteEdit,
              isPriceEdit
            )
          }
          onSearchChange={(searchParams: SearchParam) => handleSearchChange(searchParams)}
          onSortByColumnClick={(columns: ColumnDefinitionType<BackLinkListItem>[]) =>
            handleSortByColumnClick(columns)
          }
          onFiltersChange={(params: BacklinkFilter) => handleFiltersChange(params)}
          onSelectedBacklinksRescrape={(selection: BackLinkListItem[]) =>
            handleBacklinksRescrape(selection)
          }
          onSelectedBacklinksDelete={(selection: BackLinkListItem[]) =>
            handleDeleteBacklinkClicked(selection)
          }
          onSelectedBacklinksExternalReport={(selection: BackLinkListItem[]) =>
            handleBacklinkExternalReport(selection)
          }
          onSelectedBacklinksGoogleRescrape={(selection: BackLinkListItem[]) =>
            handleBacklinkGoogleRescrapeProcess(selection)
          }
          onBackButtonClick={() => handleBackButton()}
          refetch={() => refreshBacklinks()}
        >
          <AppPaginator
            pagination={pagination}
            onPageChange={(event: PageChangeEvent) => handleOnPageChange(event)}
          ></AppPaginator>
        </AppBacklinksTable>
      </Flex>
    </DomainLayout>
  );
};

export default Links;
