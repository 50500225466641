import { Flex } from '@chakra-ui/react';
import { ReactNode, createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../store/slices/authentication.slice';
import { isArray } from 'lodash';

export const FeaturePermissions = {
  OrganizationUsers: '20f89b67-4af5-4039-a2df-7d29246275ed',
  OrganizationCompetitors: '7b5a3e14-fef3-46d7-8b08-184caaa5c54e',
  OrganizationEflBlacklist: '881fbc8a-f3eb-4ddc-937e-46ad741494d6',
  OrganizationBilling: '8a365d78-d1ae-4074-b58d-dfb22dc9d9b4',
  OrganizationTags: '4B0EACB5-0CCB-4D5C-B3FD-EDD3D723056B',
  Domains: 'ae12aca1-dd50-4f76-be8e-8a676dd009d2',
  Backlinks: 'd61f4f05-2f6d-40fc-8b34-0959f1cfe8a9',
  Batches: '82c8ea8d-8c22-46ef-876a-df8d3b8378a5',
  BacklinkChecker: '00af8ce9-6882-45fd-8541-692903bbdf88',
  ExternalReports: '08582ade-4656-489b-9b88-a8f05ab889a1',
  GoogleChecker: 'F8BAFC67-9172-4B8F-9FB0-12C7CE582F49',
};

export const PermissionLevel = {
  Read: 'read',
  Write: 'write',
};

export type FeaturePermissionKeys = keyof typeof FeaturePermissions;
export type PermissionLevelKeys = keyof typeof PermissionLevel;

export type CombinedPermissionKeys = FeaturePermissionKeys;

interface PermissionsContextProps {
  can: (
    permission: CombinedPermissionKeys | CombinedPermissionKeys[],
    permissionlevel: PermissionLevelKeys
  ) => boolean;
}

interface PermissionsContextProviderProps {
  children: ReactNode | ReactNode[];
}

const PermissionsContext = createContext<PermissionsContextProps | undefined>(undefined);

export function PermissionsContextProvider({ children }: PermissionsContextProviderProps) {
  const user = useSelector(selectUser);
  // const features = useSelector(selectFeatures);

  const checkPermission = (
    permission: CombinedPermissionKeys,
    permissionLevel: PermissionLevelKeys
  ) => {
    let permissionKey = '';

    if (permission in FeaturePermissions) {
      permissionKey =
        FeaturePermissions[permission as keyof typeof FeaturePermissions].toLowerCase();
    }

    // //Auth guard toggle check, if guard is on we process the permission levels, if not we allow it to show as default
    // const feature = features?.find((x) => x.id.toLowerCase() === permissionKey);

    if (user?.role?.roleFeatures) {
      const hasPermission = user?.role?.roleFeatures.find(
        (x) => x.featureId.toLowerCase() === permissionKey
      );

      if (hasPermission) {
        if (permissionLevel === 'Read') {
          return hasPermission?.roleFeaturePermission?.read ?? false;
        } else if (permissionLevel === 'Write') {
          return hasPermission.roleFeaturePermission?.write ?? false;
        }
      }
    }

    return false;
  };

  const can = (
    permission: CombinedPermissionKeys | CombinedPermissionKeys[],
    permissionLevel: PermissionLevelKeys
  ) => {
    if (isArray(permission)) {
      return permission.some((x) => checkPermission(x, permissionLevel));
    } else {
      return checkPermission(permission, permissionLevel);
    }
  };

  return (
    <PermissionsContext.Provider value={{ can }}>
      <Flex height={'100%'} flexDirection="column" id="permission-child-container">
        {children}
      </Flex>
    </PermissionsContext.Provider>
  );
}

export function usePermissions() {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error('usePermissions must be used within PermissionsContextProvider');
  }
  return context;
}

export type CanProps = {
  key?: string;
  permission?: CombinedPermissionKeys | CombinedPermissionKeys[];
  permissionLevel: PermissionLevelKeys;
};

export const Can: React.FC<React.PropsWithChildren<CanProps>> = ({
  key,
  permission,
  permissionLevel,
  children,
}) => {
  const { can } = usePermissions();

  const canShow = useMemo(() => {
    if (permission) {
      return can(permission, permissionLevel);
    } else return false;
  }, [permission, permissionLevel, key, can]);

  if (!permission) {
    return <></>;
  }

  return canShow ? <>{children}</> : <></>;
};
