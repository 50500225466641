import {
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';

import type { ClientDialogModalOptions } from './client-dialog';
import { AppText, AppColors } from '@backlinkit/shared';
import { IoCloseSharp } from 'react-icons/io5';

export const ClientDialogServiceContext = React.createContext<(options: ClientDialogModalOptions) => void>(
  () => {
    console.error('Please ensure you register the dialog provider!');
    throw new Error('Please ensure you register the dialog provider!');
  }
);

export const ClientDialogServiceProvider = ({ children }: any) => {
  const [dialogState, setDialogState] = React.useState<ClientDialogModalOptions | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const openConfirmation = (options: ClientDialogModalOptions) => {
    setDialogState(options);
    onOpen();
  };

  const handleClose = () => {
    if (dialogState?.blocking) {
      return;
    }
    setDialogState(null);
    onClose();
  };

  const handleSubmit = () => {
    setDialogState(null);
    onClose();
  };

  return (
    <>
      <ClientDialogServiceContext.Provider value={openConfirmation} children={children} />
      {Boolean(dialogState) && (
        <>
          <Modal
            isOpen={isOpen}
            size={dialogState?.size}
            onClose={onClose}
            blockScrollOnMount={false}
          >
            {dialogState?.showOverlay ? (
              <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
            ) : (
              <ModalOverlay />
            )}
            <ModalContent p={dialogState?.centeredHeading ? 0 : 4} maxWidth={dialogState?.maxWidth} borderRadius={'2xl'}>
              {dialogState?.title && (
                <ModalHeader
                  borderBottom={`1px solid ${AppColors.appBorder}`}
                  display={'flex'}
                  width={'100%'}
                  alignItems={'center'}
                  justifyContent={dialogState.centeredHeading ? 'center' : 'start'}
                  position={'relative'}
                  bgColor={dialogState.centeredHeading ? AppColors.appBackground : 'transparent'}
                  borderTopRadius={'2xl'}
                  px={1}
                  pr={dialogState.showCancel ? 4 : 2}
                >
                  <AppText fontSize={'24px'} fontWeight={'500'} width={'90%'}>
                    {dialogState?.title}
                  </AppText>
                  {dialogState?.showCancel && (
                    <Flex
                      bgColor={'rgba(255, 0, 0, 0.1)'}
                      boxSize={'35px'}
                      justify={'center'}
                      align={'center'}
                      borderRadius={'full'}
                      _hover={{ cursor: 'pointer' }}
                      onClick={onClose}
                      position={'absolute'}
                      top={'15px'}
                      right={'15px'}
                    >
                      <Icon color={'red'} as={IoCloseSharp} />
                    </Flex>
                  )}
                </ModalHeader>
              )}

              <ModalBody p={4}>
                {dialogState?.render ? (
                  dialogState?.render(handleSubmit, handleClose)
                ) : (
                  <AppText>No Render Method</AppText>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
};
