import React from 'react';
import { Box, Button, Flex, Icon, IconButton, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { ChevronDownIcon, DeleteIcon, RepeatIcon } from '@chakra-ui/icons';
import { BiExport } from 'react-icons/bi';
import { HiClipboardList } from 'react-icons/hi';
import { AppButtonAs, AppButton, AppColors, AppText } from '@backlinkit/shared';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { iconHelper } from '../../../utils/iconHelper';
import { Can } from '../../../providers/permissions-provider';

export interface BacklinkActionsMenuProps {
  isDisabled: boolean;
  onRefreshBacklinks?: () => void;
  onReProcessBacklinksGoogle?: () => void;
  onDeleteBacklinks?: () => void;
  onExportBacklinks?: () => void;
  onCreateBacklinkExternalReport?: () => void;
}

const BacklinkActionsMenu: React.FC<BacklinkActionsMenuProps> = ({
  isDisabled,
  onDeleteBacklinks,
  onRefreshBacklinks,
  onReProcessBacklinksGoogle,
  onExportBacklinks,
  onCreateBacklinkExternalReport,
}) => {
  const handleBacklinksRefresh = () => {
    onRefreshBacklinks && onRefreshBacklinks();
  };

  const handleReProcessBacklinksGoogle = () => {
    onReProcessBacklinksGoogle && onReProcessBacklinksGoogle();
  };

  const handleBacklinksDelete = () => {
    onDeleteBacklinks && onDeleteBacklinks();
  };

  const handleBacklinksExport = () => {
    onExportBacklinks && onExportBacklinks();
  };

  const handleBacklinksExternalReport = () => {
    onCreateBacklinkExternalReport && onCreateBacklinkExternalReport();
  };

  return (
    <Menu>
      <MenuButton
        mr={4}
        as={AppButtonAs}
        rightIcon={<ChevronDownIcon color={AppColors.secondary2} />}
        borderRadius={'full'}
        variant={'ghost'}
        bgColor={'rgba(3, 125, 252, 0.1) !important'}
        borderColor={'transparent !important'}
      >
        <Box fontSize={'14px'} fontWeight={'500'} color={AppColors.secondary2}>
          Actions
        </Box>
      </MenuButton>
      <MenuList zIndex={4} p={0} borderRadius={'2xl'}>
        <Flex flexDirection={'column'} gap={2}>
          <Flex
            borderTopRadius={'2xl'}
            bgColor={AppColors.appBackground}
            align={'center'}
            width={'100%'}
            p={4}
          >
            <AppText>Actions</AppText>
          </Flex>
          <Flex flexDir={'column'} p={4} gap={2}>
            <Can permission={'ExternalReports'} permissionLevel="Write">
              <Flex w={'100%'} align={'center'} gap={4} onClick={handleBacklinksExternalReport}>
                <IconButton
                  aria-label="create-external-report"
                  _hover={{ bgColor: AppColors.appBackground }}
                  bgColor={AppColors.appBackground}
                  boxSize={'30px !important'}
                  px={0}
                  borderRadius={'md'}
                >
                  <Icon as={iconHelper.copyIcon} boxSize={'20px'} />
                </IconButton>
                <AppText>Create External Report</AppText>
              </Flex>
            </Can>

            <Can permission={'Backlinks'} permissionLevel="Read">
              <Flex w={'100%'} align={'center'} gap={4} onClick={handleBacklinksExport}>
                <IconButton
                  aria-label="create-external-report"
                  _hover={{ bgColor: AppColors.appBackground }}
                  bgColor={AppColors.appBackground}
                  boxSize={'30px !important'}
                  px={0}
                  borderRadius={'md'}
                >
                  <Icon as={iconHelper.exportIcon} boxSize={'20px'} />
                </IconButton>
                <AppText>Export</AppText>
              </Flex>
            </Can>

            <Can permission={'Backlinks'} permissionLevel="Write">
              <Flex w={'100%'} align={'center'} gap={4} onClick={handleBacklinksRefresh}>
                <IconButton
                  aria-label="create-external-report"
                  _hover={{ bgColor: AppColors.appBackground }}
                  bgColor={AppColors.appBackground}
                  boxSize={'30px !important'}
                  px={0}
                  borderRadius={'md'}
                >
                  <Icon as={RepeatIcon} boxSize={'20px'} />
                </IconButton>
                <AppText>Reprocess</AppText>
              </Flex>

              <Flex w={'100%'} align={'center'} gap={4} onClick={handleReProcessBacklinksGoogle}>
                <IconButton
                  aria-label="create-external-report"
                  _hover={{ bgColor: AppColors.appBackground }}
                  bgColor={AppColors.appBackground}
                  boxSize={'30px !important'}
                  px={0}
                  borderRadius={'md'}
                >
                  <Icon as={RepeatIcon} boxSize={'20px'} />
                </IconButton>
                <AppText>Reprocess Google Checks</AppText>
              </Flex>

              <Box width={'100%'} height={'1px'} bgColor={AppColors.appBackground} my={3} />
              <Button
                isDisabled={isDisabled}
                onClick={handleBacklinksDelete}
                leftIcon={<Icon as={iconHelper.deleteIcon} />}
                bgColor={'rgba(255, 0, 0, 0.1)'}
                color={'red'}
                borderRadius={'xl !important'}
              >
                Delete
              </Button>
            </Can>
          </Flex>
        </Flex>
      </MenuList>
    </Menu>
  );
};

export default BacklinkActionsMenu;
