import { useState } from 'react';
import {
  Flex,
  FormLabel,
  List,
  ListItem,
  Input,
  Button,
  Icon,
} from '@chakra-ui/react';
import { AppButton, AppColors, AppInput, AppText } from '@backlinkit/shared';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { useForm } from 'react-hook-form';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { iconHelper } from '../../utils/iconHelper';

export interface InviteUserForm {
  displayName: string;
  emails: string[];
  referrerMessage: string;
}

const userInviteFormDefaultValues: InviteUserForm = {
  displayName: '',
  emails: [],
  referrerMessage: '',
};

const userInviteFormDataSchema = yup.object({
  displayName: yup.string().required('Field is required'),
  referrerMessage: yup.string().required('Field is required'),
});

type BugFormProps<T> = {
  form?: InviteUserForm;
} & BaseFormProps<T>;

const UserInviteForm: React.FC<BugFormProps<InviteUserForm>> = ({ form, onSubmit }) => {
  const [listItem, setListItem] = useState<string>('');
  const [listItems, setListItems] = useState<string[]>([]);

  const {
    control: control,
    formState: { isValid, errors },
    setValue,
    getValues,
    handleSubmit,
  } = useForm<InviteUserForm>({
    defaultValues: form || userInviteFormDefaultValues,
    resolver: yupResolver(userInviteFormDataSchema),
    mode: 'onChange',
  });

  const handleFormSave = () => {
    const formData = getValues();
    onSubmit({
      displayName: formData.displayName,
      emails: listItems,
      referrerMessage: formData.referrerMessage,
    });
  };

  const handleAddItem = (item: string) => {
    if (listItem) {
      setListItems((listItems) => {
        return [...listItems, listItem];
      });
    }
    setListItem('');
  };

  const handleDeleteItem = (item: string) => {
    const newList = listItems.filter((x) => x !== item);
    setListItems(newList);
  };

  return (
    <Flex p={4} flexDir={'column'} gap={'10px'} w={'full'}>
      <Flex
        flexDirection={'column'}
        bg={'rgba(3, 125, 252, 0.12)'}
        color={'#222222'}
        rounded={'2xl'}
        p={4}
      >
        <AppText fontWeight={'bold'}>Please note:</AppText>
        <AppText mt={2}>
          For every user that accepts your invite! We will add 100 credits to your google checker
          account!
        </AppText>
      </Flex>
      <AppInput<InviteUserForm>
        mt={8}
        control={control}
        name={'displayName'}
        error={errors.displayName}
        label={'Email From Name'}
        variant="grayed"
      />
      <AppInput<InviteUserForm>
        mt={8}
        control={control}
        name={'referrerMessage'}
        error={errors.referrerMessage}
        label={'Referral Message:'}
        textArea
        variant="grayed"
      />
      <FormLabel>Email addresses</FormLabel>
      <Flex w={'full'} gap={3}>
        <Input
          value={listItem}
          onChange={(e) => setListItem(e.target.value)}
          name="list"
          placeholder="Email Address"
          borderRadius={'2xl'}
          height={'60px'}
          border={`1px solid ${AppColors.appBorder}`}
        />
        <Button
          onClick={() => handleAddItem(listItem)}
          bgColor={'rgba(3, 125, 252, 0.12)'}
          color={AppColors.secondary2}
          borderRadius={'2xl'}
          fontWeight={'600'}
          height={'60px'}
        >
          + Add
        </Button>
      </Flex>
      <List spacing={3} my={6}>
        {listItems.map((x) => {
          return (
            <Flex
              w={'full'}
              justify={'space-between'}
              bgColor={'rgba(235, 235, 235, 0.5)'}
              p={2}
              px={3}
              borderRadius={'2xl'}
              align={'center'}
              gap={3}
              key={x}
            >
              <Flex gap={3}>
                <ListItem>{x}</ListItem>
              </Flex>
              <Button
                borderRadius={'2xl'}
                onClick={() => handleDeleteItem(x)}
                color={'red'}
                bgColor={'rgba(255, 0, 0, 0.1)'}
                fontWeight={'400'}
                display={'flex'}
                alignItems={'center'}
                gap={4}
              >
                Delete
                <Icon boxSize={'20px'} color={'red'} as={iconHelper.deleteIcon} />
              </Button>
            </Flex>
          );
        })}
      </List>

      <AppButton
        w={'full'}
        disabled={!isValid}
        onClick={() => handleFormSave()}
        bgColor={AppColors.ctaColor}
        color={'white'}
        height={'60px'}
      >
        Save
      </AppButton>
    </Flex>
  );
};

export default UserInviteForm;
