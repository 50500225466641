import { Box, Flex, Tag, TagLabel, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { AppColors, AppText } from '@backlinkit/shared';
import { BacklinkTag } from '@backlinkit/shared/lib/models/backlinkTag';

type LinkTagsCellProps = { backlinkTags?: BacklinkTag[] };

const LinkTagsCell: React.FC<LinkTagsCellProps> = ({ backlinkTags }) => {
  if (backlinkTags && backlinkTags.length > 0) {
    return (
      <>
        {
          <Box justifyContent={'center'} px={'16px'} py={'8px'} display={'flex'}>
            <Tooltip
              label={
                <Flex flexDirection={'column'}>
                  {backlinkTags &&
                    backlinkTags.length > 0 &&
                    backlinkTags.map((backlinkTag, index) => (
                      <AppText key={`link-tag-${index}-${backlinkTag?.tag?.id}`}>
                        {backlinkTag?.tag?.name}
                      </AppText>
                    ))}
                </Flex>
              }
            >
              {backlinkTags && backlinkTags.length > 0 && (
                <Flex
                  justify={'center'}
                  align={'center'}
                  borderRadius="full"
                  width={'min-content'}
                  bgColor={AppColors.appBackground}
                  color={'black'}
                  px={'16px'}
                  py={'8px'}
                >
                  <AppText>{backlinkTags[0].tag?.name}</AppText>
                </Flex>
              )}
            </Tooltip>
          </Box>
        }
      </>
    );
  } else {
    return (
      <Flex width={'100%'} align={'center'} justify={'center'}>
        <Flex
          align={'center'}
          justify={'center'}
          borderRadius="full"
          py={'8px'}
          px={'16px'}
          maxW={'35px'}
          bgColor={AppColors.appBackground}
        >
          <AppText color={'black'}>-</AppText>
        </Flex>
      </Flex>
    );
  }
};

export default LinkTagsCell;
