import {
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { FaAnchor } from 'react-icons/fa';
import { HiOutlineClipboardCopy } from 'react-icons/hi';
import {
  AppCardList,
  AppColors,
  AppText,
  BackLinkListItem,
  CardListItem,
  LinkStatus,
  LinkType,
  useDialog,
} from '@backlinkit/shared';
import { BaseBacklinkCell } from './base-backlink-cell';
import { isHTML } from '../../../../utils/htmlHelpers';
import DOMPurify from 'dompurify';

type AnchorCellProps = BaseBacklinkCell & {};

const AnchorCell: React.FC<AnchorCellProps> = ({ item }) => {
  const toast = useToast();
  const dialog = useDialog();

  const handleCopyToClipboardClick = (value: string) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        toast({
          title: 'Copied to clipboard',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((e) => {
        toast({
          title: 'Failed to copy to clipboard',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const handleAdditionalAnchorsClick = (item: BackLinkListItem) => {
    const list: CardListItem[] = [];
    if (item.backlinkMeta?.additionalDomainAnchors) {
      const alertItem: CardListItem[] = item.backlinkMeta?.additionalDomainAnchors?.map(
        (anchor) => {
          return {
            name: `${anchor?.anchorText && `${anchor?.anchorText}`}`,
            nameLabel: 'Anchor',
            landing: `${anchor.anchorRef}`,
            handleNavigate: () => window.open(`${anchor.anchorRef}`, '_blank'),
            landingLabel: 'Landing',
            message: anchor.linkType,
            messageLabel: 'Link Type',
            htmlLabel: 'Link Context',
            note: anchor.anchorRelValue,
            noteLabel: 'Link Rel',
            renderHtml:
              item.backlinkMeta?.linkType === LinkType.Redirect
                ? item.backlinkMeta?.pageDescription || ''
                : anchor?.anchorContext,
            renderHtmlPopover: item.backlinkMeta?.linkType === LinkType.Image,
          };
        }
      );
      list.push(...alertItem);
    }
    dialog({
      title: `Anchor Snippet View`,
      size: '2xl',
      render: (onSubmit) => {
        return (
          <>
            <AppCardList
              items={[
                {
                  name: item.url,
                  nameLabel: 'Page Url',
                  message: item.backlinkMeta?.pageTitle,
                  messageLabel: 'Page Title',
                  highLightItem: true,
                },
              ]}
            ></AppCardList>
            <AppCardList items={list}></AppCardList>
          </>
        );
      },
    });
  };

  const hasAdditionalAnchors = () => {
    return (
      item?.backlinkMeta?.additionalDomainAnchors &&
      item?.backlinkMeta?.additionalDomainAnchors.length > 0
    );
  };

  const getAnchorText = (): string => {
    if (item.expectedAnchor) {
      if (item?.backlinkMeta?.linkStatus === LinkStatus.Ok) {
        return item.expectedAnchor;
      } else {
        return 'Not Found';
      }
    } else if (item?.backlinkMeta?.linkType !== LinkType.Image) {
      return item?.anchor ?? '';
    } else {
      return '';
    }
  };

  return (
    <Flex flexDir={'row'} justifyContent={'start'} alignItems={'center'}>
      {hasAdditionalAnchors() && (
        <Tooltip label={'Anchor Snippet View'}>
          <Flex
            justify={'center'}
            align={'center'}
            bg={AppColors.primary}
            color={'white'}
            aria-label="column visibility button"
            borderRadius={'lg'}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
            }}
            h={'25px'}
            maxW={'25px'}
            width={'25px'}
            mr={'10px'}
            onClick={() => {
              handleAdditionalAnchorsClick(item);
            }}
          >
            <Icon as={FaAnchor} boxSize={'15px'} color={'white'} p={0} />
          </Flex>
        </Tooltip>
      )}
      {item.anchor && (
        <Popover trigger="hover">
          <PopoverTrigger>
            <Flex>
              <AppText
                maxW={'200px'}
                isTruncated
                _hover={{
                  cursor: 'pointer',
                }}
              >
                {/* {item.expectedAnchor
                  ? item?.backlinkMeta?.linkStatus === LinkStatus.Ok
                    ? item.expectedAnchor
                    : 'Not Found'
                  : item?.backlinkMeta?.linkType !== LinkType.Image && item?.anchor} */}

                {isHTML(getAnchorText() ?? '') ? 'View Details' : getAnchorText()}
              </AppText>
            </Flex>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <Flex alignItems={'center'}>
                {(item?.anchor || item.expectedAnchor) &&
                  item?.backlinkMeta?.linkType !== LinkType.Image && (
                    <Tooltip label={'Copy to clipboard'}>
                      <IconButton
                        bg={AppColors.secondary}
                        color={'white'}
                        aria-label="column visibility button"
                        isRound={false}
                        _hover={{
                          transform: 'translateY(-2px)',
                          boxShadow: 'lg',
                        }}
                        size="xs"
                        mr={hasAdditionalAnchors() ? '5px' : '15px'}
                        onClick={() => {
                          handleCopyToClipboardClick(
                            item.expectedAnchor ? item.expectedAnchor : item?.anchor ?? ''
                          );
                        }}
                      >
                        <Icon as={HiOutlineClipboardCopy} size="1rem" color={'white'} />
                      </IconButton>
                    </Tooltip>
                  )}

                <AppText
                  ml={2}
                  whiteSpace={'normal'}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(getAnchorText()),
                  }}
                >
                  {/* {isHTML(getAnchorText() ?? '') ? '' : getAnchorText()} */}
                </AppText>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
      {!item.anchor && item?.backlinkMeta?.linkType !== LinkType.Redirect && (
        <AppText ml={2} whiteSpace={'normal'}>
          Not Found
        </AppText>
      )}
    </Flex>
  );
};

export default AnchorCell;
