import React from 'react';
import {
  Flex,
  Box,
  Stack,
  Image,
  Text,
  useToast,
  Container,
  SimpleGrid,
  Heading,
  useBreakpointValue,
  useMediaQuery,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RoutesList } from '../../router/router';
import { useUserAuthenticationMutation } from '../../store/api/authenticationApi';
import { setUserAuth, logout } from '../../store/slices/authentication.slice';
import LoginForm, { LoginFormData } from '../../components/forms/login-form';
import { HttpError, AppColors, AppText } from '@backlinkit/shared';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [smallDevice] = useMediaQuery('(max-width: 1400px)');

  const [userAuthentication, { data, isLoading, error, isSuccess, isError }] =
    useUserAuthenticationMutation();

  const dispatch = useDispatch();

  const signIn = async (loginFormData: LoginFormData) => {
    try {
      await dispatch(logout());

      const res = await userAuthentication({
        username: loginFormData.email,
        password: loginFormData.password,
      }).unwrap();

      dispatch(setUserAuth(res));

      if (res && res.user && res.user.organization && res.user.organization.subscriptionIsActive) {
        navigate(RoutesList.Dashboard);
      } else if (
        res &&
        res.user &&
        res.user.organization &&
        res.user.organization.createdUserId === res.user.id
      ) {
        navigate(RoutesList.Products);
      } else {
        toast({
          title: 'Your trail has ended!',
          description:
            'The owner of this organization will need to subscribe to one of our products.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }

      // if (organizationRole === OrganizationRoleType.ADMIN) {
      //   navigate(RoutesList.Dashboard);
      // } else {
      //   navigate(RoutesList.Batches);
      // }
    } catch (errResponse) {
      console.error(errResponse);
      const httpError: HttpError = (errResponse as any).data as HttpError;

      toast({
        title: 'Login Failed',
        description: httpError?.error?.message || 'Login failed.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const goToUserRegistration = () => {
    navigate(`${RoutesList.Registration}`);
  };

  return (
    <Flex
      h={'100vh'}
      align={'center'}
      justify={'center'}
      bgColor={AppColors.secondary2}
      px={'33px'}
    >
      <Flex
        flexDir={'row-reverse'}
        w={'100%'}
        justify={'space-evenly'}
        height={'100%'}
        align={'center'}
      >
        {!smallDevice && <Image maxH={'640px'} maxW={'760px'} src={'./login-image.png'} />}
        <Flex
          flexDir={'column'}
          bg={'white'}
          justify={'center'}
          align={'center'}
          borderRadius={'40px'}
          p={{ base: 4, sm: 6, md: 8 }}
          gap={8}
          w={'100%'}
          maxW={'650px'}
          height={`calc(100vh - 10%)`}
          my={{ base: 4, sm: 6, md: 8 }}
        >
          <AppText variant={'h2'} fontWeight={'semibold'}>
            Sign In
          </AppText>
          <Flex
            as={'form'}
            mt={10}
            width={'100%'}
            flexDir={'column'}
            justify={'center'}
            align={'center'}
          >
            <LoginForm isLoading={isLoading} onSubmit={signIn}></LoginForm>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Login;
