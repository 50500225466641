import {
  Flex,
  useToast,
  useColorModeValue,
  Text,
  Menu,
  Button,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TagsActionsMenu from '../../components/app/app-menus/tags-actions-menu';
import OrganizationTagCreateForm, {
  OrganizationTagFormData,
} from '../../components/forms/organization-tag-create-form';
import { ChevronDownIcon, SettingsIcon } from '@chakra-ui/icons';
import DomainLayout from '../../components/layouts/domain-layout';
import {
  useCreateTagMutation,
  useDeleteTagMutation,
  useEditTagMutation,
  useFetchTagsByOptionsQuery,
  useLazyFetchTagsByOrganizationIdQuery,
  useTagBulkCreateMutation,
} from '../../store/api/tagApi';
import { selectUser } from '../../store/slices/authentication.slice';
import {
  AppButton,
  AppColors,
  AppConfirm,
  AppDataTable,
  AppLoader,
  AppPaginator,
  CellClickEvent,
  ColumnDefinitionType,
  OrganizationTag,
  OrganizationTagBulkCreateRequest,
  OrganizationTagEditForm,
  useDataTable,
  useDialog,
  useLoading,
} from '@backlinkit/shared';
import BulkTagsForm from '../../components/forms/csv-tags-form';
import { Can } from '../../providers/permissions-provider';

const OrganizationTagsPage: React.FC = () => {
  const { setLoading } = useLoading();
  const title = 'Tags';
  const user = useSelector(selectUser);

  const {
    handleOnPageChange,
    handleSortByColumnClick,
    handleTableRowsSelectedChange,
    handleSearchChange,
    nextPaginationQueryParams,
    pagination,
    selected,
    setPagination,
  } = useDataTable<OrganizationTag>({});

  const {
    data: OrganizationTags,
    isLoading,
    refetch: refetchTags,
  } = useFetchTagsByOptionsQuery(
    {
      organizationId: user?.organizationId?.toString() ?? '',
      pageNumber: nextPaginationQueryParams.page,
      pageSize: nextPaginationQueryParams.pageSize,
      searchParam: nextPaginationQueryParams.searchList,
      sortParams: nextPaginationQueryParams.sortList,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (OrganizationTags?.pagination) {
      setPagination(OrganizationTags?.pagination);
    }
  }, [OrganizationTags]);

  const [getAllTagsByOrganizationId] = useLazyFetchTagsByOrganizationIdQuery();

  const [deleteOrganizationTagTrigger, { isLoading: isDeletingOrganizationTag }] =
    useDeleteTagMutation();

  const [saveOrganizationTagTrigger, { isLoading: isAddOrganizationTagSaving }] =
    useCreateTagMutation();

  const [editOrganizationTagTrigger, { isLoading: isEditOrganizationTagkSaving }] =
    useEditTagMutation();

  const [postTagsBulkCreate] = useTagBulkCreateMutation();

  const toast = useToast();
  const dialog = useDialog();

  const handleEditDialog = (OrganizationTag: OrganizationTag) => {
    const OrganizationTagForm: OrganizationTagEditForm = {
      id: OrganizationTag.id.toString(),
      name: OrganizationTag.name || '',
      description: OrganizationTag.description || '',
      organizationId: user?.organizationId ?? '',
    };
    dialog({
      title: 'Edit Domain Tag',
      render: (onSubmit) => {
        return (
          <OrganizationTagCreateForm
            form={OrganizationTagForm}
            onSubmit={async (formData) => {
              onSubmit();
              await upsertTag(formData);
            }}
          ></OrganizationTagCreateForm>
        );
      },
    });
  };

  const renderDefaultTableActions = (item: OrganizationTag) => {
    return (
      <Can permission={'OrganizationTags'} permissionLevel="Write">
        <Menu>
          <MenuButton
            color={'white'}
            size="sm"
            bgColor={AppColors.outlineColor}
            as={Button}
            rightIcon={<ChevronDownIcon />}
            borderRadius="full"
          >
            Actions
          </MenuButton>
          <MenuList>
            <MenuItem
              _hover={{
                bgColor: 'transparent',
              }}
              onClick={() => {
                handleEditDialog(item);
              }}
            >
              <Flex
                w={'100%'}
                align={'center'}
                gap={4}
                p={2}
                borderRadius={'md'}
                _hover={{
                  bgColor: AppColors.appBackground,
                }}
              >
                <Flex p={2} bgColor={'rgba(0, 0, 0, 0.2)'} borderRadius={'md'}>
                  <SettingsIcon />
                </Flex>
                Edit
              </Flex>
            </MenuItem>
          </MenuList>
        </Menu>
      </Can>
    );
  };
  const tagColumns: ColumnDefinitionType<OrganizationTag>[] = [
    {
      key: 'name',
      header: 'Tag Name',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'description',
      header: 'Description',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'Actions',
      render: renderDefaultTableActions,
    },
  ];

  const [tableColumns, setTableColumns] =
    useState<ColumnDefinitionType<OrganizationTag>[]>(tagColumns);

  const handleAddDialog = () => {
    dialog({
      title: 'Create Tag',
      size: 'lg',
      showCancel: true,
      render: (onSubmit) => {
        return (
          <OrganizationTagCreateForm
            onSubmit={async (formData) => {
              onSubmit();
              await upsertTag(formData);
            }}
          ></OrganizationTagCreateForm>
        );
      },
    });
  };

  const handleBulkTagSave = async (tagsFormData: OrganizationTagFormData[]) => {
    setLoading(true);
    try {
      const tagsForSaving: OrganizationTag[] = [];
      for (const tagFormData of tagsFormData) {
        const newTag = {
          name: tagFormData.name,
          description: tagFormData.description,
        } as OrganizationTag;
        tagsForSaving.push(newTag);
      }
      const bulkTags = {
        organizationId: user?.organizationId,
        tags: tagsForSaving,
      } as OrganizationTagBulkCreateRequest;
      await postTagsBulkCreate(bulkTags).unwrap();
    } catch (e) {
      toast({
        title: 'Tag saving error',
        description:
          "We've run into a problem saving the tags you created, contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    refetchTags();
    setLoading(false);
  };

  const handleBulkAdd = () => {
    dialog({
      title: 'Tags Bulk Create',
      size: 'xl',
      centeredHeading: true,
      showCancel: true,
      render: (onSubmit) => {
        return (
          <BulkTagsForm
            onSubmit={async (fileData) => {
              onSubmit();
              await handleBulkTagSave(fileData);
            }}
          />
        );
      },
    });
  };

  const upsertTag = async (formData: OrganizationTagFormData) => {
    let edittedTag = formData.id ? true : false;

    try {
      setLoading(true);

      if (!edittedTag) {
        await saveOrganizationTagTrigger({
          name: formData.name,
          description: formData.description ?? '',
          organizationId: user?.organizationId ?? '',
        }).unwrap();
      } else {
        await editOrganizationTagTrigger({
          id: formData.id ?? '',
          name: formData.name,
          description: formData.description ?? '',
          organizationId: user?.organizationId ?? '',
        }).unwrap();
        edittedTag = true;
      }

      setLoading(false);
      toast({
        title: `Tag ${!edittedTag ? 'added' : 'updated'}.`,
        description: `We've  ${
          !edittedTag ? 'added' : 'updated'
        }. your tag for you, come back soon to monitor the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      refetchTags();

      if (user) {
        getAllTagsByOrganizationId(user.organizationId);
      }
    } catch (error) {
      toast({
        title: `Tag  ${!edittedTag ? 'add' : 'update'}. error.=`,
        description: `We've run into a problem  ${
          !edittedTag ? 'adding' : 'updating'
        }. your tag, Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      setLoading(false);
    }
  };

  const handleTableCellClick = async (clickEvent: CellClickEvent<OrganizationTag>) => {};

  const handleDeleteBacklinkClicked = () => {
    dialog({
      title: 'Delete',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message={`Are you sure you want to delete the ${selected.length} selected Tags?`}
            onConfirm={async () => {
              onSubmit();

              await handleDeleteTags(selected);
            }}
            onCancel={() => {
              onCancel();
            }}
          ></AppConfirm>
        );
      },
    });
  };

  const handleDeleteTags = async (tags: OrganizationTag[]) => {
    try {
      const deletePromises = selected.map((tag) => deleteOrganizationTagTrigger(tag.id).unwrap());

      await Promise.all(deletePromises);
    } catch (e) {
      toast({
        title: 'Tag Delete error.',
        description:
          "We've run into a problem deleting the selected tags, Contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    toast({
      title: 'Selected Tags deleted.',
      description: "We've deleted the tags you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });

    refetchTags();

    if (user) {
      getAllTagsByOrganizationId(user.organizationId);
    }
  };

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return (
    // <DomainLayout>
    <Flex
      flex={1}
      direction="column"
      border={`1px solid ${AppColors.secondary2}`}
      m={6}
      borderRadius={'2xl'}
    >
      {isLoading && <AppLoader />}

      <Flex
        justifyContent={'space-between'}
        align={'center'}
        borderTopRadius={'2xl'}
        bgColor={AppColors.secondary2}
        p={4}
      >
        <Text color={'white'} fontWeight="bold" fontSize="2xl" mb="4px">
          {title}
        </Text>
        <Flex alignItems={'center'}>
          <Can permission={'OrganizationTags'} permissionLevel="Write">
            <AppButton
              onClick={handleBulkAdd}
              variant={'ghost'}
              mr={2}
              color={'white'}
              borderColor={'white'}
              border={`1px solid white`}
              borderRadius="full"
              alignSelf={'flex-end'}
            >
              Bulk Add
            </AppButton>
            <AppButton
              onClick={handleAddDialog}
              variant={'ghost'}
              mr={2}
              color={'white'}
              borderColor={'white'}
              border={`1px solid white`}
              borderRadius="full"
              alignSelf={'flex-end'}
            >
              Add Tag
            </AppButton>
            <TagsActionsMenu
              hasBorder
              isDisabled={selected.length === 0}
              onDeleteTags={handleDeleteBacklinkClicked}
              darkTheme
            />
          </Can>
        </Flex>
      </Flex>

      <Flex p={6} flex={1}>
        <Flex
          w={'full'}
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          padding={'4'}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={OrganizationTags?.data || []}
            noDataMessage={'No tags added, start by adding some!'}
            columns={tableColumns}
            selectableRows={true}
            onRowSelected={handleTableRowsSelectedChange}
            onTableCellClick={handleTableCellClick}
            onSearchInputChange={handleSearchChange}
            onSortByColumnClick={handleSortByColumnClick}
          />
          <AppPaginator pagination={pagination} onPageChange={handleOnPageChange}></AppPaginator>
        </Flex>
      </Flex>
    </Flex>
    // </DomainLayout>
  );
};

export default OrganizationTagsPage;
