import React from 'react';
import {
  AppColors,
  BacklinkScraperValueDifference,
  RobotsIndexStatus,
  useDialog,
  usePanel,
} from '@backlinkit/shared';
import { AppText } from '@backlinkit/shared';
import {
  Flex,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tooltip,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import DOMPurify from 'dompurify';

type DifferenceCountCellProps = {
  backlinkUrl: string;
  scraperDifference?: BacklinkScraperValueDifference;
  onErrorsCleared?: () => void;
  onClick?: () => void;
  icon?: IconType;
  color?: string;
};

export const DifferenceCountCell: React.FC<DifferenceCountCellProps> = ({
  backlinkUrl,
  scraperDifference,
  onClick,
  icon,
  color,
}) => {
  const dialog = useDialog();

  const handleDifferencesDialog = () => {
    dialog({
      title: `${backlinkUrl} Stats`,
      centeredHeading: true,
      showCancel: true,
      size: '2xl',
      render: (onSubmit) => {
        return (
          <Flex>
            <TableContainer maxW={'100%'}>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Value</Th>
                    <Th>Differences</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      <AppText fontWeight={'bold'} isTruncated>
                        Rel Status
                      </AppText>
                    </Td>
                    <Td>
                      <Flex
                        direction={'column'}
                        bgColor={AppColors.appBackground}
                        p={4}
                        borderRadius={'2xl'}
                        gap={2}
                        maxW={'300px'}
                      >
                        <Flex direction={'row'}>
                          <AppText
                            fontWeight={scraperDifference?.relStatus?.isEqual ? '400' : 'bold'}
                          >
                            Current: {scraperDifference?.relStatus?.currentValue}
                          </AppText>
                        </Flex>
                        <Flex direction={'row'}>
                          <AppText>Previous: {scraperDifference?.relStatus?.previousValue}</AppText>
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <AppText fontWeight={'bold'} isTruncated>
                        Rel Value
                      </AppText>
                    </Td>
                    <Td>
                      <Flex
                        direction={'column'}
                        bgColor={AppColors.appBackground}
                        p={4}
                        gap={2}
                        borderRadius={'2xl'}
                      >
                        <Flex direction={'row'}>
                          <AppText
                            fontWeight={scraperDifference?.relValue?.isEqual ? '400' : 'bold'}
                          >
                            Current: {scraperDifference?.relValue?.currentValue}
                          </AppText>
                        </Flex>
                        <Flex direction={'row'}>
                          <AppText>Previous: {scraperDifference?.relValue?.previousValue}</AppText>
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <AppText fontWeight={'bold'} isTruncated>
                        Anchor Context Info
                      </AppText>
                    </Td>
                    <Td>
                      <Flex
                        direction={'column'}
                        bgColor={AppColors.appBackground}
                        p={4}
                        borderRadius={'2xl'}
                        gap={2}
                      >
                        <Flex direction={'row'} maxW={'100%'}>
                          <AppText
                            fontWeight={
                              scraperDifference?.anchorContextInfo?.isEqual ? '400' : 'bold'
                            }
                          >
                            Current:
                            <AppText
                              whiteSpace={'normal'}
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  scraperDifference?.anchorContextInfo?.currentValue ?? ''
                                ),
                              }}
                            ></AppText>
                          </AppText>
                        </Flex>
                        <Flex direction={'row'}>
                          <AppText>
                            Previous:{' '}
                            <AppText
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  scraperDifference?.anchorContextInfo?.previousValue ?? ''
                                ),
                              }}
                              whiteSpace={'normal'}
                            ></AppText>
                          </AppText>
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <AppText fontWeight={'bold'} isTruncated>
                        Robots Meta Tag
                      </AppText>
                    </Td>
                    <Td>
                      <Flex
                        direction={'column'}
                        bgColor={AppColors.appBackground}
                        p={4}
                        borderRadius={'2xl'}
                        gap={2}
                        maxW={'300px'}
                      >
                        <Flex direction={'row'}>
                          <AppText
                            fontWeight={
                              scraperDifference?.robotsIndexStatus?.isEqual ? '400' : 'bold'
                            }
                          >
                            Current:
                            {scraperDifference?.robotsIndexStatus?.currentValue ===
                              RobotsIndexStatus.NoIndex && (
                              <Tooltip label="NoIndex (set by robots meta tag)">
                                <Tag ml={1} borderRadius="full" colorScheme={'orange'}>
                                  <TagLabel>{'NI'}</TagLabel>
                                </Tag>
                              </Tooltip>
                            )}
                            {scraperDifference?.robotsIndexStatus?.currentValue ===
                              RobotsIndexStatus.follow && (
                              <Tooltip label="Indexed (set by robots meta tag)">
                                <Tag ml={1} borderRadius="full" colorScheme={'orange'}>
                                  <TagLabel>{'FL'}</TagLabel>
                                </Tag>
                              </Tooltip>
                            )}
                            {scraperDifference?.robotsIndexStatus?.currentValue ===
                              RobotsIndexStatus.Nofollow && (
                              <Tooltip label="NoFollow (set by robots meta tag)">
                                <Tag ml={1} borderRadius="full" colorScheme={'orange'}>
                                  <TagLabel>{'NF'}</TagLabel>
                                </Tag>
                              </Tooltip>
                            )}
                            {scraperDifference?.robotsIndexStatus?.currentValue ===
                              RobotsIndexStatus.Indexed && (
                              <Tooltip label="Indexed (set by robots meta tag)">
                                <Tag ml={1} borderRadius="full" colorScheme={'orange'}>
                                  <TagLabel>{'IN'}</TagLabel>
                                </Tag>
                              </Tooltip>
                            )}
                            {scraperDifference?.robotsIndexStatus?.currentValue ===
                              RobotsIndexStatus.Unset && (
                              <Tooltip label="Unset (set by robots meta tag)">
                                <Tag ml={1} borderRadius="full" colorScheme={'orange'}>
                                  <TagLabel>{'Unset'}</TagLabel>
                                </Tag>
                              </Tooltip>
                            )}
                          </AppText>
                        </Flex>
                        <Flex direction={'row'}>
                          <AppText>
                            Previous:
                            {scraperDifference?.robotsIndexStatus?.previousValue ===
                              RobotsIndexStatus.follow && (
                              <Tooltip label="Indexed (set by robots meta tag)">
                                <Tag ml={1} borderRadius="full" colorScheme={'orange'}>
                                  <TagLabel>{'FL'}</TagLabel>
                                </Tag>
                              </Tooltip>
                            )}
                            {scraperDifference?.robotsIndexStatus?.previousValue ===
                              RobotsIndexStatus.NoIndex && (
                              <Tooltip label="NoIndex (set by robots meta tag)">
                                <Tag ml={1} borderRadius="full" colorScheme={'orange'}>
                                  <TagLabel>{'NI'}</TagLabel>
                                </Tag>
                              </Tooltip>
                            )}
                            {scraperDifference?.robotsIndexStatus?.previousValue ===
                              RobotsIndexStatus.Nofollow && (
                              <Tooltip label="NoFollow (set by robots meta tag)">
                                <Tag ml={1} borderRadius="full" colorScheme={'orange'}>
                                  <TagLabel>{'NF'}</TagLabel>
                                </Tag>
                              </Tooltip>
                            )}
                            {scraperDifference?.robotsIndexStatus?.previousValue ===
                              RobotsIndexStatus.Indexed && (
                              <Tooltip label="Indexed (set by robots meta tag)">
                                <Tag ml={1} borderRadius="full" colorScheme={'orange'}>
                                  <TagLabel>{'IN'}</TagLabel>
                                </Tag>
                              </Tooltip>
                            )}
                            {scraperDifference?.robotsIndexStatus?.previousValue ===
                              RobotsIndexStatus.Unset && (
                              <Tooltip label="Unset (set by robots meta tag)">
                                <Tag ml={1} borderRadius="full" colorScheme={'orange'}>
                                  <TagLabel>{'Unset'}</TagLabel>
                                </Tag>
                              </Tooltip>
                            )}
                          </AppText>
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <AppText fontWeight={'bold'} isTruncated>
                        Anchor
                      </AppText>
                    </Td>
                    <Td>
                      <Flex
                        direction={'column'}
                        bgColor={AppColors.appBackground}
                        p={4}
                        borderRadius={'2xl'}
                        gap={2}
                        maxW={'300px'}
                      >
                        <Flex direction={'row'}>
                          <AppText fontWeight={scraperDifference?.anchor?.isEqual ? '400' : 'bold'}>
                            Current: {scraperDifference?.anchor?.currentValue}
                          </AppText>
                        </Flex>
                        <Flex direction={'row'}>
                          <AppText>Previous: {scraperDifference?.anchor?.previousValue}</AppText>
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <AppText fontWeight={'bold'} isTruncated>
                        Landing
                      </AppText>
                    </Td>
                    <Td>
                      <Flex
                        direction={'column'}
                        bgColor={AppColors.appBackground}
                        p={4}
                        borderRadius={'2xl'}
                        gap={2}
                        maxW={'300px'}
                      >
                        <Flex direction={'row'}>
                          <AppText
                            fontWeight={scraperDifference?.landingPage?.isEqual ? '400' : 'bold'}
                          >
                            Current: {scraperDifference?.landingPage?.currentValue}
                          </AppText>
                        </Flex>
                        <Flex direction={'row'}>
                          <AppText>
                            Previous: {scraperDifference?.landingPage?.previousValue}
                          </AppText>
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <AppText fontWeight={'bold'} isTruncated>
                        Google Page Index
                      </AppText>
                    </Td>
                    <Td>
                      <Flex
                        direction={'column'}
                        bgColor={AppColors.appBackground}
                        p={4}
                        borderRadius={'2xl'}
                        gap={2}
                        maxW={'300px'}
                      >
                        <Flex direction={'row'}>
                          <AppText
                            fontWeight={scraperDifference?.hasPageIndex?.isEqual ? '400' : 'bold'}
                          >
                            Current: {scraperDifference?.hasPageIndex?.currentValue}
                          </AppText>
                        </Flex>
                        <Flex direction={'row'}>
                          <AppText>
                            Previous: {scraperDifference?.hasPageIndex?.previousValue}
                          </AppText>
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <AppText fontWeight={'bold'} isTruncated>
                        Google Domain Index
                      </AppText>
                    </Td>
                    <Td>
                      <Flex
                        direction={'column'}
                        bgColor={AppColors.appBackground}
                        p={4}
                        borderRadius={'2xl'}
                        gap={2}
                        maxW={'300px'}
                      >
                        <Flex direction={'row'}>
                          <AppText
                            fontWeight={scraperDifference?.hasDomainIndex?.isEqual ? '400' : 'bold'}
                          >
                            Current: {scraperDifference?.hasDomainIndex?.currentValue}
                          </AppText>
                        </Flex>
                        <Flex direction={'row'}>
                          <AppText>
                            Previous: {scraperDifference?.hasDomainIndex?.previousValue}
                          </AppText>
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                  {/* <Tr>
                    <Td>
                      <AppText fontWeight={'bold'} isTruncated>
                        Google Cache Date
                      </AppText>
                    </Td>
                    <Td>
                      <Flex direction={'column'}>
                        <Flex direction={'row'}>
                          <AppText
                            fontWeight={
                              scraperDifference?.cacheCreatedDate?.isEqual ? '400' : 'bold'
                            }
                          >
                            Current: {scraperDifference?.cacheCreatedDate?.currentValue}
                          </AppText>
                        </Flex>
                        <Flex direction={'row'}>
                          <AppText>
                            Previous: {scraperDifference?.cacheCreatedDate?.previousValue}
                          </AppText>
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr> */}
                  {/* <Tr alignItems={'center'}>
                    <Td>
                      <AppButton size={'sm'} onClick={onClick}>
                        Save Changes
                      </AppButton>
                    </Td>
                    <Td>
                      <Icon w={'22px'} height={'22px'} as={icon} color={color} />
                    </Td>
                  </Tr> */}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
        );
      },
    });
  };

  const getLabelText = (differenceCount: number) => {
    if (differenceCount < 9) {
      return `0${differenceCount}`;
    }
    if (differenceCount > 9) {
      return `${differenceCount}`;
    }
    return '-';
  };

  return (
    <Flex>
      {scraperDifference && (
        <Tooltip label="New Link Differences">
          <Flex
            borderRadius="full"
            bgColor={AppColors.appBackground}
            cursor={'pointer'}
            boxSize={'30px'}
            onClick={() => handleDifferencesDialog()}
            align={'center'}
            justify={'center'}
          >
            <AppText fontSize={'16px'}>
              {scraperDifference.differenceCount
                ? getLabelText(scraperDifference.differenceCount)
                : '-'}
            </AppText>
          </Flex>
        </Tooltip>
      )}
    </Flex>
  );
};

export default DifferenceCountCell;
