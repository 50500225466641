import React from 'react';
import {
  AlertListItem,
  AppAlertList,
  BackLinkListItem,
  ScrapeError,
  ScrapeErrorTypes,
  useDialog,
} from '@backlinkit/shared';
import { BaseBacklinkCell } from './base-backlink-cell';
import { format } from 'date-fns';
import { useEditBacklinkAlertsMutation } from '../../../../store/api/backlinksApi';
import { Flex, Tag, TagLabel } from '@chakra-ui/react';
import { usePermissions } from '../../../../providers/permissions-provider';

type ErrorCountCellProps = {
  onErrorsCleared?: () => void;
} & BaseBacklinkCell & {};

export const ErrorCountCell: React.FC<ErrorCountCellProps> = ({ item, onErrorsCleared }) => {
  const dialog = useDialog();
  const [editBacklinkAlertsTrigger] = useEditBacklinkAlertsMutation();
  const { can } = usePermissions();

  const hasErrors = item.errors?.some((error) => error.errorType === ScrapeErrorTypes.error);
  const hasWarnings = item.errors?.some((error) => error.errorType === ScrapeErrorTypes.warning);
  const hasInfo = item.errors?.some((error) => error.errorType === ScrapeErrorTypes.info);

  let alertColor = 'grey';

  if (hasErrors) {
    alertColor = 'red';
  } else if (hasWarnings) {
    alertColor = 'orange';
  } else if (hasInfo) {
    alertColor = 'blue';
  }

  const clearEntityAlerts = async (id: string, errors: ScrapeError[], items: AlertListItem[]) => {
    const newErrors: ScrapeError[] = [];

    items.forEach((x) => {
      const currentError = errors.find(
        (error) =>
          error.errorType === x.alertType &&
          error.errorMessage === x.alertMessage &&
          error.key === x.alertKey
      );

      if (currentError) {
        newErrors.push(currentError);
      }
    });

    await editBacklinkAlertsTrigger({
      id: id,
      errors: newErrors,
    }).unwrap();

    onErrorsCleared && onErrorsCleared();
  };

  const handleErrorListDialog = (item: BackLinkListItem) => {
    const errorList: AlertListItem[] = [];

    if (item.errors) {
      const errors: AlertListItem[] = item.errors.map((error) => {
        return {
          name: item.url,
          alertType: error.errorType,
          alertMessage: error.errorMessage,
          alertNoteLabel: 'Created',
          alertNote: error.dateCreated ? format(new Date(error.dateCreated), 'dd MMM yyyy') : '',
          entityId: item.id,
          alertKey: error.key,
        };
      });
      errorList.push(...errors);
    }

    dialog({
      title: `${item.url} Alerts`,
      size: '2xl',
      showCancel: true,
      render: (onSubmit) => {
        return (
          <AppAlertList
            onSaveClearedAlerts={(alerts) => {
              if (can('Backlinks', 'Write')) {
                clearEntityAlerts(item.id, item.errors ? item.errors : [], alerts);
                onSubmit();
              }
            }}
            items={errorList}
            showName={false}
            showType={true}
            itemsClearable={false}
          ></AppAlertList>
        );
      },
    });
  };

  return (
    <Flex justify={'center'} align={'center'}>
      <Tag borderRadius="full" colorScheme={alertColor} boxSize={'30px'}>
        <TagLabel cursor={'pointer'} onClick={() => handleErrorListDialog(item)} ml={'2px'}>
          {item?.errorCount > 0 ? item?.errorCount : '-'}
        </TagLabel>
      </Tag>
    </Flex>
  );
};
