import { Stack } from '@chakra-ui/react';
import { OrganizationCompetitorForm, AppButton, AppInput, AppColors } from '@backlinkit/shared';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '../../store/store';
import { selectUser } from '../../store/slices/authentication.slice';

interface OrganizationCompetitorFormData {
  id?: string;
  competitorName: string;
  competitorDomain: string;
  organizationId: string;
}

const defaultValues: OrganizationCompetitorFormData = {
  competitorDomain: '',
  competitorName: '',
  organizationId: '',
};

const competitorDataSchema = yup.object({
  competitorName: yup.string().required('Field is required'),
  competitorDomain: yup.string().required('Field is required'),
  organizationId: yup.string().required('Field is required'),
});

type CompetitorForm<T> = {
  form?: OrganizationCompetitorForm;
} & BaseFormProps<T>;

export const CompetitorForm: React.FC<CompetitorForm<OrganizationCompetitorFormData>> = ({
  form,
  onSubmit,
}) => {
  const user = useAppSelector(selectUser);
  const {
    control,
    formState: { isValid, errors },
    getValues,
  } = useForm<OrganizationCompetitorFormData>({
    defaultValues: form || defaultValues,
    resolver: yupResolver(competitorDataSchema),
    mode: 'onChange',
  });

  const handleFormSubmit = () => {
    const formData = getValues();
    const submitData = {
      id: formData.id ?? undefined,
      competitorDomain: formData.competitorDomain,
      competitorName: formData.competitorName,
      organizationId: user?.organizationId ?? '',
    };
    onSubmit(submitData);
  };

  return (
    <Stack spacing={4} borderRadius={'2xl'}>
      <AppInput<OrganizationCompetitorForm>
        name="competitorName"
        control={control}
        error={errors.competitorName}
        label="Competitor Name"
        variant='grayed'
      />
      <AppInput<OrganizationCompetitorForm>
        name="competitorDomain"
        control={control}
        error={errors.competitorDomain}
        label="Competitor Domain"
        variant='grayed'
      />
      <AppButton onClick={handleFormSubmit} bgColor={AppColors.ctaColor} color={'white'}>Save</AppButton>
    </Stack>
  );
};
