import React, { useEffect, useState } from 'react';
import { FlexProps, Flex } from '@chakra-ui/react';
import { RangeDatepicker } from 'chakra-dayzed-datepicker';
import { AppColors } from '@backlinkit/shared';

export interface DateRange {
  startDate?: Date | undefined;
  endDate?: Date | undefined;
}

export type DateRangeFilterProps = {
  placeholder?: string;
  dates?: Date[];
  onSelectedDatesChange?: (range: DateRange) => void;
} & FlexProps;

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  onSelectedDatesChange,
  dates = [],
  placeholder,
}) => {
  const [selectedDates, setSelectedDates] = useState<Date[]>(dates);

  useEffect(() => {
    setSelectedDates(dates);
  }, [dates]);

  const onDateChange = (dates: Date[]) => {
    setSelectedDates(dates);
    console.log(dates);
    const startDate = dates.length > 0 ? dates[0] : undefined;
    const endDate = dates.length > 1 ? dates[1] : undefined;
    onSelectedDatesChange && onSelectedDatesChange({ startDate: startDate, endDate: endDate });
  };

  return (
    <Flex>
      <RangeDatepicker
        selectedDates={selectedDates}
        onDateChange={onDateChange}
        configs={{
          dateFormat: 'dd MMM yyyy',
        }}
        propsConfigs={{
          dateNavBtnProps: {
            colorScheme: AppColors.primary,
          },
          dayOfMonthBtnProps: {
            defaultBtnProps: {
              _hover: {
                background: AppColors.secondary,
                color: AppColors.secondary2,
                fontWeight: '600',
              },
              color: 'black',
              borderRadius: ' md',
            },
            selectedBtnProps: {
              backgroundColor: '#037DFC',
              color: 'white',
            },
            isInRangeBtnProps: {
              backgroundColor: '#037DFC',
              color: 'black',
              borderColor: AppColors.secondary2,
            },
            todayBtnProps: {
              backgroundColor: AppColors.secondary2,
              color: 'white',
              _hover: {
                backgroundColor: AppColors.secondary,
                color: 'black',
                fontWeight: '500',
              },
            },
          },
          inputProps: {
            size: 'md',
            placeholder: placeholder || 'Select Date Range',
            variant: 'grayed',
          },
          popoverCompProps: {
            popoverBodyProps: {
              p: '1px',
              borderWidth: 2,
            },
            popoverContentProps: {
              background: AppColors.innerContentColor,
              color: AppColors.primary,
            },
          },
        }}
      />
    </Flex>
  );
};

export default DateRangeFilter;
