import React, { useState } from 'react';
import {
  Box,
  Divider,
  Flex,
  Icon,
  IconButton,
  Image,
  Popover,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import {
  AppText,
  AppColors,
  AppCard,
  useLoading,
  DomainViewModel,
  BacklinkBatchViewModel,
  useDialog,
  AppConfirm,
} from '@backlinkit/shared';
import StatusIndicator from '../../components/status-indicator/status-indicator';
import BacklinkBatchLinks from './backlink-batch-links';
import BatchTagCell from '../../components/backlink-batch/batch-tag-cell';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import BacklinkBatchForm, {
  BacklinkBatchFormData,
} from '../../components/forms/backlink-batch-form';
import { useDeleteBatchMutation, useUpdateBatchMutation } from '../../store/api/backlinkBatchesApi';
import NoteFormComponent from '../../components/forms/note-form';
import { FaChevronDown } from 'react-icons/fa';
import { FaChevronUp } from 'react-icons/fa';
import noteIcon from '../../assets/note-icon.svg';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { iconHelper } from '../../utils/iconHelper';
import { Can, usePermissions } from '../../providers/permissions-provider';

export type BatchCardProps = {
  batch: BacklinkBatchViewModel;
  domain: DomainViewModel;
  refetchBatches: () => void;
  showLinks?: boolean;
};

const BatchCard: React.FC<BatchCardProps> = ({
  batch,
  domain,
  showLinks = false,
  refetchBatches,
}) => {
  const dialog = useDialog();
  const toast = useToast();
  const { setLoading } = useLoading();
  const { can } = usePermissions();

  const [showBacklinks, setShowBacklinks] = useState<boolean>(showLinks);
  const [updateBatchTrigger] = useUpdateBatchMutation();
  const [deleteBatchTrigger] = useDeleteBatchMutation();

  const handleBatchEdit = () => {
    const tagIds = batch.backlinkBatchTags ? batch.backlinkBatchTags.map((x) => x.tagId ?? '') : [];
    const formValues: BacklinkBatchFormData = {
      id: batch.id,
      domainId: batch.domainId,
      name: batch.name,
      price: batch.price,
      currency: batch.currency,
      notes: batch.notes,
      batchTypeId: batch.batchTypeId,
      nicheTypeId: batch.nicheTypeId,
      tagIds,
    };
    dialog({
      title: `Batch Edit`,
      size: '3xl',
      showCancel: true,
      render: (onSubmit) => {
        return (
          <BacklinkBatchForm
            form={formValues}
            onSubmit={async (formData) => {
              console.log('BatchForm', formData);
              onSubmit();
              setLoading(true);
              await updateBatchTrigger({
                id: batch.id,
                domainId: batch.domainId,
                createdUserId: batch.createdUserId,
                lastModifiedUserId: batch.lastModifiedUserId,
                name: formData.name,
                price: formData.price,
                currency: formData.currency,
                notes: formData.notes,
                batchTypeId: formData.batchTypeId,
                nicheTypeId: formData.nicheTypeId,
                tagIds: formData.tagIds,
                tagChanges: true,
              });
              refetchBatches();
              setLoading(false);
            }}
          />
        );
      },
    });
  };

  const handleNoteEditDialog = () => {
    const tagIds = batch.backlinkBatchTags ? batch.backlinkBatchTags.map((x) => x.tagId ?? '') : [];
    dialog({
      title: `Batch Note Edit`,
      size: 'xl',
      showCancel: true,
      render: (onSubmit) => {
        return (
          <NoteFormComponent
            noteValue={batch.notes}
            onSubmit={async (note) => {
              onSubmit();
              setLoading(true);
              await updateBatchTrigger({
                id: batch.id,
                domainId: batch.domainId,
                createdUserId: batch.createdUserId,
                lastModifiedUserId: batch.lastModifiedUserId,
                name: batch.name,
                price: batch.price,
                currency: batch.currency,
                notes: note,
                batchTypeId: batch.batchTypeId,
                nicheTypeId: batch.nicheTypeId,
                tagIds: tagIds,
                tagChanges: false,
              });
              refetchBatches();
              setLoading(false);
            }}
          />
        );
      },
    });
  };

  const handleDelete = async () => {
    dialog({
      title: 'Delete Batch',
      centeredHeading: true,
      showCancel: true,
      render: (onCancel) => {
        return (
          <AppConfirm
            message={`You are about to delete batch ${batch.name}, are you sure?`}
            confirmText="Yes"
            cancelText="Cancel"
            onConfirm={async () => {
              onCancel();
              handleConfirmDelete();
            }}
            onCancel={() => {
              onCancel();
            }}
          ></AppConfirm>
        );
      },
    });
  };

  const handleConfirmDelete = async () => {
    try {
      setLoading(true);
      await deleteBatchTrigger(batch.id).unwrap();
      toast({
        title: 'Selected Batch deleted',
        description: "We've deleted the batch you selected",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      refetchBatches();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast({
        title: 'Batch delete error',
        status: 'error',
        description:
          "We've run into an error deleting the batch you selected, Contact support for help",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <AppCard maxW={'100%'} overflow={'auto'}>
      <Flex gap={8} justifyContent={'space-between'} py={2} px={4}>
        <Flex flex={4} gap={1} alignItems={'center'} justify={'space-between'}>
          <AppText fontWeight={'600'} fontSize={'18px'}>
            {batch.name}
          </AppText>
          <AppText
            fontSize={'18px'}
            color={'gray.500'}
            display={'flex'}
            alignItems={'center'}
            gap={2}
          >
            <AppText color={'black'}>Price:</AppText> {batch.currency || ''} {batch.price || 'N/A'}
          </AppText>
          <AppText fontSize={'18px'}>Backlinks: {batch.backlinkCount || 0}</AppText>
          <Box>
            <Flex p={3} borderRadius={'full'} bgColor={'#edf2f7'}>
              <BatchTagCell tags={batch.backlinkBatchTags || []} fontSize="18px" />
            </Flex>
          </Box>
          <Box>
            <Flex
              justify={'center'}
              align={'center'}
              cursor={'pointer'}
              onClick={() => {
                if (can('Batches', 'Write')) {
                  handleNoteEditDialog();
                }
              }}
              bgColor={'rgba(3, 125, 252, 0.2)'}
              boxSize={'50px'}
              borderRadius={'full'}
            >
              <Tooltip label={<AppText fontSize={'18px'}>{batch.notes}</AppText>}>
                <Image src={noteIcon} height={'22px'} />
              </Tooltip>
            </Flex>
          </Box>
          <Box>
            <Popover trigger="hover" placement="top">
              <Flex>
                <Tooltip
                  label={
                    batch.backlinkErrorCount && batch.backlinkErrorCount > 0
                      ? 'There are errors within your batch'
                      : 'There are no errors in you batch'
                  }
                >
                  <span>
                    <StatusIndicator
                      status={
                        batch.backlinkErrorCount && batch.backlinkErrorCount > 0
                          ? AppColors.errorColor
                          : AppColors.successColor
                      }
                      pulse={true}
                    />
                  </span>
                </Tooltip>
              </Flex>
            </Popover>
          </Box>
          <Flex align={'center'} gap={'40px'}>
            <Can permission={'Batches'} permissionLevel="Write">
              <IconButton
                aria-label="batchDelete"
                bgColor={showBacklinks ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 0, 0, 0.1)'}
                borderRadius={'full'}
                onClick={() => handleDelete()}
              >
                <Icon
                  as={iconHelper.deleteIcon}
                  boxSize={'20px'}
                  color={showBacklinks ? 'black' : 'red'}
                />
              </IconButton>
              <IconButton
                aria-label="batchEdit"
                bgColor={AppColors.appBackground}
                color={AppColors.primary}
                borderRadius={'full'}
                onClick={() => handleBatchEdit()}
              >
                <Icon as={iconHelper.secondaryEditIcon} boxSize={'20px'} />
              </IconButton>
            </Can>
            <IconButton
              aria-label="showbacklinks-chevron"
              bgColor={AppColors.appBackground}
              color={AppColors.primary}
              borderRadius={'full'}
              onClick={() => {
                setShowBacklinks(!showBacklinks);
              }}
            >
              <Icon as={showBacklinks ? FaChevronUp : FaChevronDown} boxSize={'20px'} />
            </IconButton>
          </Flex>
        </Flex>
      </Flex>
      {showBacklinks && (
        <Flex flexDirection={'column'}>
          <Divider my={4} />

          <Flex gap={8} px={4}>
            <AppText>
              Batch Type: <strong>{batch.batchType ? batch.batchType.name : 'Not Selected'}</strong>
            </AppText>
            <AppText>
              Niche Type: <strong>{batch.nicheType ? batch.nicheType.name : 'Not Selected'}</strong>
            </AppText>
          </Flex>

          <Box maxW={'100%'} overflow={'auto'}>
            <BacklinkBatchLinks
              batchId={batch.id}
              domain={domain}
              refetchBatches={refetchBatches}
            />
          </Box>
        </Flex>
      )}
    </AppCard>
  );
};

export default BatchCard;
