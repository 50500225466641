import React from 'react';
import { AppButton, AppText } from '@backlinkit/shared';
import { Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/authentication.slice';

type CancelFormProps = {
  onSubmit: () => void;
};

const CancelForm: React.FC<CancelFormProps> = ({ onSubmit }) => {
  const user = useSelector(selectUser);
  return (
    <Flex direction={'column'} gap={6} w={'full'} p={4}>
      <AppText fontSize={'2xl'} fontWeight={'600'}>
        {user?.organization.product?.name}
      </AppText>
      <AppText fontSize={'lg'}>
        {user?.organization.product?.type === 0
          ? 'Are you sure you want to cancel your Subscription?'
          : 'Are you sure you want to close your Checker Credits account?'}
      </AppText>
      <AppText fontSize={'lg'} fontWeight={'600'}>
        Your plan Benefits:
      </AppText>
      <Flex direction={'column'} w={'full'} gap={4}>
        <Flex w={'full'} justify={'space-between'}>
          <AppText fontSize={'lg'} fontWeight={'500'}>
            Links:
          </AppText>
          <AppText fontSize={'lg'}>{user?.organization.product?.linkLimit}</AppText>
        </Flex>
        <Flex direction={'column'} gap={2}>
          <AppText fontSize={'lg'} fontWeight={'500'}>
            Description
          </AppText>
          <AppText fontSize={'lg'}>{user?.organization.product?.description}</AppText>
        </Flex>
      </Flex>
      <AppButton color={'red'} borderRadius={'full'} onClick={() => onSubmit()}>
        Cancel
      </AppButton>
    </Flex>
  );
};

export default CancelForm;
