import React from 'react';
import { Flex, Stack } from '@chakra-ui/react';
import { AppText, AppButton, ProductDto, AppColors } from '@backlinkit/shared';
import { FaShoppingCart } from 'react-icons/fa';
import { CheckIcon } from '@chakra-ui/icons';

type CheckoutCartProps = {
  product: ProductDto;
  onSubmit: (item: ProductDto) => void;
};

const CheckoutCart: React.FC<CheckoutCartProps> = ({ product, onSubmit }) => {
  return (
    <Stack spacing={6} w={'full'}>
      <Flex
        direction={'column'}
        borderBottom={`1px solid ${AppColors.appBorder}`}
        gap={6}
        py={4}
        w={'full'}
      >
        <Flex w={'full'} justify={'space-between'}>
          <AppText fontSize={'2xl'} fontWeight={'bold'} color={AppColors.secondary2}>
            {product.name}
          </AppText>
          <AppText fontWeight={'500'}>${product.price}</AppText>
        </Flex>
        <AppText fontSize={'lg'} fontWeight={'500'}>
          Description
        </AppText>
        <AppText>{product.description}</AppText>
      </Flex>
      <Flex
        direction={'column'}
        borderBottom={`1px solid ${AppColors.appBorder}`}
        gap={3}
        py={4}
        w={'full'}
      >
        <AppText color={AppColors.secondary2} fontWeight={'500'} fontSize={'lg'}>
          Details
        </AppText>
        <Flex gap={3}>
          <CheckIcon color={'green'} />
          <AppText>{product.type === 0 ? 'Subscription' : 'Credits'}</AppText>
        </Flex>
        <Flex gap={3}>
          <CheckIcon color={'green'} />
          <AppText>{product.linkLimit} links</AppText>
        </Flex>
      </Flex>

      <Flex direction={'column'} w={'full'}>
        <Flex
          p={4}
          justify={'space-between'}
          w={'full'}
          borderTop={`1px solid ${AppColors.primary}`}
        >
          <AppText fontSize={'xl'} fontWeight={'600'}>
            Subtotal:
          </AppText>
          <AppText fontSize={'xl'} fontWeight={'600'}>{`$ ${product.price}`}</AppText>
        </Flex>
        <AppButton
          onClick={() => onSubmit(product)}
          bgColor={AppColors.secondary}
          color={'white'}
          borderRadius={'full'}
          gap={3}
        >
          {product.type === 0 ? 'Subscribe' : 'Pay'}
          <FaShoppingCart />
        </AppButton>
      </Flex>
    </Stack>
  );
};

export default CheckoutCart;
