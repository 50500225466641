import { ProductDto, User, useLoading, usePanel } from '@backlinkit/shared';
import { selectUser } from '../store/slices/authentication.slice';
import { useAppSelector } from '../store/store';
import CheckoutCart from '../components/forms/checkout-cart';
import CancelForm from '../components/forms/cancel-plan-form';
import CheckOutForm from '../components/forms/checkout-form';
import { useLazyFetchUserByIdQuery } from '../store/api/userApi';
import { initializePaddle, CheckoutOpenOptions, Paddle } from '@paddle/paddle-js';
import { useEffect, useState } from 'react';
import { useUpdateSubscriptionMutation } from '../store/api/paddleApi';

const paddleToken = process.env.REACT_APP_PADDLE;

const usePayments = () => {
  const user = useAppSelector(selectUser);
  const { setLoading } = useLoading();
  const panel = usePanel();
  // const [createCheckoutSession] = useCreateCheckoutSessionMutation();
  const [updateSubscriptionApi] = useUpdateSubscriptionMutation();
  const [getUserById] = useLazyFetchUserByIdQuery();

  const [paddle, setPaddle] = useState<Paddle>();

  useEffect(() => {
    // if (paddleToken) {
    //   initializePaddle({ token: paddleToken, environment: 'sandbox' }); // TODO CHANGE TO PROD
    // }

    initializePaddle({
      token: paddleToken ?? '',
      environment: 'sandbox',
      // eventCallback(event) {
      //   // switch (event.type) {
      //   //   case value:
      //   //     break;

      //   //   default:
      //   //     break;
      //   // }
      // },
    }).then((paddleInstance: Paddle | undefined) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, []);

  const upgradePackage = async (product: ProductDto) => {
    setLoading(true);
    try {
      // const organizationPaymentForm: OrganizationPaymentForm = {
      //   organizationId: user?.organizationId ?? '',
      //   productId: product.id,
      //   paymentAmount: product.price,
      //   paymentReference: `${product.name}-${user?.organizationId}-${new Date()}`,
      //   paymentStatus: OrganizationPaymentStatusType.INITIATED,
      // };
      // const organizationPayment = await createOrganizationPayment(organizationPaymentForm).unwrap();

      await updateSubscriptionApi({ userId: user?.id ?? '', productId: product.id });

      const checkoutOptions: CheckoutOpenOptions = {
        customer: {
          id: user?.organization.paymentCustomerId,
        } as any,
        items: [{ priceId: product.priceKey, quantity: 1 }],
      };

      paddle?.Checkout.open(checkoutOptions);

      // if (product.type === ProductType.CHECKERCREDITS) {
      //   const response = await createCheckoutSession({
      //     userId: user?.id ?? '',
      //     productId: product.id,
      //     domain: baseUrl ?? '',
      //   }).unwrap();

      //   window.location.replace(response.sessionUrl);
      // } else {
      //   const hasAnActiveSub = user?.organization.subscriptionId;

      //   if (hasAnActiveSub) {
      //     await updateSubscriptionApi({ userId: user?.id ?? '', productId: product.id });
      //     // await getUserById(user?.id);
      //   } else {
      //     const response = await createCheckoutSession({
      //       userId: user?.id ?? '',
      //       productId: product.id,
      //       domain: baseUrl ?? '',
      //     }).unwrap();

      //     window.location.replace(response.sessionUrl);
      //   }
      // }

      // const stripe = await getStripe();
      // setLoading(false);
      // if (stripe) {
      //   const { error } = await stripe.redirectToCheckout({
      //     lineItems: [
      //       {
      //         price: basicPlanId,
      //         quantity: 1,
      //       },
      //     ],
      //     mode: 'subscription',
      //     successUrl: `${baseUrl}/payment/success/${organizationPayment.id}`,
      //     cancelUrl: `${baseUrl}/payment/cancel/${organizationPayment.id}`,
      //     customerEmail: user?.email,
      //     clientReferenceId: user?.id,

      //   });
      //   console.warn(error.message);
      // }
      setLoading(false);
    } catch (catchError) {
      setLoading(false);
      console.warn(catchError);
    }
  };

  const cancelUpdateSubscription = async () => {
    setLoading(true);
    try {
      await updateSubscriptionApi({
        userId: user?.id ?? '',
        productId: user?.organization.productId ?? '',
        cancelPlan: true,
      });
      setLoading(false);
    } catch (catchError) {
      setLoading(false);
      console.warn(catchError);
    }
  };

  const handleCheckout = (item: ProductDto) => {
    panel({
      title: 'Checkout',
      size: 'xl',
      render: (onSubmit) => {
        return (
          <CheckoutCart
            product={item}
            onSubmit={() => {
              upgradePackage(item);
            }}
          />
        );
      },
    });
  };

  const cancelUserPlan = (user: User) => {
    panel({
      title: 'Cancel Plan',
      size: 'xl',
      render: (onSubmit) => {
        return (
          <CancelForm
            onSubmit={() => {
              onSubmit();
              cancelUpdateSubscription();
            }}
          />
        );
      },
    });
  };

  const handlePlanSelect = () => {
    panel({
      title: 'Select a Plan',
      size: 'xl',
      render: (onSubmit) => (
        <CheckOutForm
          onSubmit={(item: ProductDto) => {
            handleCheckout(item);
          }}
          onCancelPlan={() => {
            cancelUserPlan(user!);
          }}
        />
      ),
    });
  };

  return {
    handlePlanSelect,
    upgradePackage,
    updateSubscriptionApi,
  };
};

export default usePayments;
