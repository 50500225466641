import React from 'react';
import { RoutesList } from './router';

interface ProtectedRouteProps {
  route?: RoutesList;
  children: React.ReactNode | React.ReactNode[] | undefined;
}

const AdminRoute = ({ route, children }: ProtectedRouteProps) => {
  // const isUserAdmin = useSelector(isAdmin);

  // return <React.Fragment>{isUserAdmin ? children : null}</React.Fragment>;
  return <React.Fragment>{children}</React.Fragment>;
};
export default AdminRoute;
