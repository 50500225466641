import React, { useEffect, useState } from 'react';
import { Box, Flex, FormLabel } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { AppButton, AppColors, AppInput, SelectListFilter, SelectOption } from '@backlinkit/shared';
import { useFetchRolesQuery } from '../../store/api/roleApi';

export interface UserEditFormData {
  id: string;
  email: string;
  organizationId: string;
  roleId: string;
}

export const UserEditFormDataDefaultValues: UserEditFormData = {
  id: '',
  email: '',
  roleId: '',
  organizationId: '',
};

const UserEditFormDataSchema = yup.object({
  id: yup.string(),
  email: yup.string().required('Field is required'),
  roleId: yup.number().required('Field is required'),
});

type UserEditFormProps<T> = {
  isOwner: boolean;
  form?: UserEditFormData;
} & BaseFormProps<T>;

const UserEditForm: React.FC<UserEditFormProps<UserEditFormData>> = ({
  isOwner,
  form,
  onSubmit,
}) => {
  const [roleList, setRoleList] = useState<SelectOption[]>([]);
  const { data: roles } = useFetchRolesQuery({});

  useEffect(() => {
    if (roles) {
      const selectors: SelectOption[] = roles.map((x) => {
        return {
          label: x.name,
          value: x.id,
        };
      });
      setRoleList(selectors);
    }
  }, [roles]);

  const {
    control: UserCreateControl,
    formState: { isValid, errors },
    setValue,
    handleSubmit,
  } = useForm<UserEditFormData>({
    defaultValues: form || UserEditFormDataDefaultValues,
    resolver: yupResolver(UserEditFormDataSchema),
    mode: 'onChange',
  });

  const [defaultRole, setDefaultRole] = useState<SelectOption>();

  const roleOptions: SelectOption[] = [
    {
      label: 'Admin',
      value: 0,
    },
    {
      label: 'Backlinker',
      value: 1,
    },
  ];

  const getDefaultRoleOption = () => {
    if (roleList && form?.roleId) {
      const defaultValue = roleList.find((x) => x.value === form?.roleId);

      return defaultValue;
    }

    return undefined;
  };

  return (
    <Flex dir="column" w={'full'}>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          width: '100%',
        }}
      >
        <AppInput<UserEditFormData>
          control={UserCreateControl}
          name="email"
          label="email"
          placeHolder="email"
          error={errors.email}
          variant="grayed"
        />
        {!isOwner && (
          <Box flex={1}>
            <FormLabel mb={2} p={0}>
              Role
            </FormLabel>
            <SelectListFilter
              name="role"
              options={roleList}
              isInModal
              showClear={true}
              defaultOption={getDefaultRoleOption()}
              onSelectionChange={(items: SelectOption[]) => {
                if (items) {
                  setValue('roleId', items[0]?.value, { shouldValidate: true });
                }
              }}
              w={'100%'}
            />
          </Box>
        )}

        <AppButton
          w={'full'}
          mt={8}
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
          bgColor={AppColors.ctaColor}
          color={'white'}
        >
          Save
        </AppButton>
      </form>
    </Flex>
  );
};

export default UserEditForm;
