import { AppColors, AppPlayer, AppText } from '@backlinkit/shared';
import {
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { useState } from 'react';

export type TrainingVideo = {
  title: string;
  url: string;
};

export interface TrainginAreaFormProps {
  videos: TrainingVideo[];
}

const TraingingAreaForm: React.FC<TrainginAreaFormProps> = ({ videos }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>();
  return (
    <Flex w={'100%'}>
      <Accordion allowToggle w={'100%'}>
        {videos.map((video, index) => (
          <AccordionItem
            key={`${video.title}-${index}`}
            mt={4}
            border={selectedIndex === index ? `1px solid ${AppColors.iconColorOne}` : 'none'}
            borderRadius={'2xl'}
            onClick={() => {
              setSelectedIndex(index);
            }}
          >
            <AccordionButton minH={'55px'} borderRadius={'2xl'} bgColor={AppColors.appBorder}>
              <Flex w={'100%'} align={'center'} justify={'space-between'}>
                <AppText fontWeight={'500'}>{video.title}</AppText>
                <AccordionIcon color={AppColors.secondary} />
              </Flex>
            </AccordionButton>
            <AccordionPanel>
              <Flex w={'100%'} mr={12} pos={'relative'}>
                <AppPlayer height="100%" width="100%" media={video.url ?? ''} />
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Flex>
  );
};

export default TraingingAreaForm;
