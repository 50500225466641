import { Box, Flex, Tag, TagLabel, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { AppColors, AppText, BacklinkBatchTag } from '@backlinkit/shared';

type BatchTagCellProps = {
  tags: BacklinkBatchTag[];
  fontSize?: string;
};

const BatchTagCell: React.FC<BatchTagCellProps> = ({ tags, fontSize = '16px' }) => {
  // return (
  //   <Tooltip
  //     label={
  //       <Flex direction={'column'}>
  //         {tags &&
  //           tags.length > 0 &&
  //           tags.map((batchTag, index) => (
  //             <AppText key={`batchTag-${index}-${batchTag?.tagId}`}>{batchTag?.tag?.name}</AppText>
  //           ))}
  //       </Flex>
  //     }
  //   >
  //     {tags && tags.length > 0 && (
  //       <Tag borderRadius={'full'} colorScheme="gray">
  //         <TagLabel>{tags[0].tag?.name}</TagLabel>
  //       </Tag>
  //     )}
  //   </Tooltip>
  // );

  if (tags && tags.length > 0) {
    return (
      <>
        {
          <Box justifyContent={'center'}>
            <Tooltip
              p={4}
              backgroundColor={AppColors.appBackground}
              label={
                <Flex flexDirection={'column'}>
                  {tags &&
                    tags.length > 0 &&
                    tags.map((batchTag, index) => (
                      <AppText key={`link-tag-${index}-${batchTag?.tag?.id}`} fontSize={fontSize}>
                        {batchTag?.tag?.name}
                      </AppText>
                    ))}
                </Flex>
              }
            >
              {tags && tags.length > 0 && (
                <Tag borderRadius="full" colorScheme={'gray'}>
                  <TagLabel fontSize={fontSize}>{tags[0].tag?.name}</TagLabel>
                </Tag>
              )}
            </Tooltip>
          </Box>
        }
      </>
    );
  } else {
    return (
      <Tag borderRadius="full" p={2}>
        <TagLabel fontSize={fontSize}>-</TagLabel>
      </Tag>
    );
  }
};

export default BatchTagCell;
