import { Box, Flex, FlexProps, Icon, useMediaQuery } from '@chakra-ui/react';
import { AppColors, AppText, Organization } from '@backlinkit/shared';
import { useEffect } from 'react';
import { iconHelper } from '../../utils/iconHelper';

export interface ProfileCardProps extends FlexProps {
  organization?: Organization;
  domains?: number;
  links?: number;
  errors?: number;
}

export const ProfileCard: React.FC<ProfileCardProps> = ({
  organization,
  domains,
  links,
  errors,
  ...props
}) => {
  useEffect(() => {}, [organization]);
  const [smallScreen] = useMediaQuery('(max-width: 1440px)');
  return (
    <Flex borderRadius={'3xl'} bgColor={AppColors.secondary2} p={'35px'} {...props} width={'100%'}>
      <Flex flexDir={'column'} gap={4} w={'100%'} height={'100%'} justify={'space-between'}>
        <Flex width={'100%'} gap={4}>
          <Flex
            boxSize={'50px'}
            justify={'center'}
            align={'center'}
            bgColor={AppColors.appBackground}
            borderRadius={'full'}
          >
            <AppText fontWeight={'bold'}>{organization?.email[0]}</AppText>
          </Flex>
          <Box>
            <AppText fontSize={{ sm: '22px', xl: '28px' }} color={'white'} fontWeight={'600'}>
              {organization?.name}
            </AppText>
            <AppText fontSize={'16px'} color={'white'} fontWeight={'500'}>
              BacklinkIt
            </AppText>
          </Box>
        </Flex>
        <Flex width={'100%'} gap={4} align={'center'} justify={'space-between'} height={'80px'}>
          <Flex
            borderRadius={'2xl'}
            bgColor={'white'}
            p={smallScreen ? 2 : 3}
            gap={smallScreen ? 0 : 2}
            w={'100%'}
            h={'100%'}
          >
            <Icon as={iconHelper.globeIcon} boxSize={smallScreen ? '15px' : '22px'} />
            <Flex flexDir={'column'} width={'100%'}>
              <AppText
                variant={smallScreen ? 'p1' : 'h4'}
                fontWeight={'500'}
                ml={smallScreen ? 2 : 0}
              >
                {domains}
              </AppText>
              <AppText fontSize={smallScreen ? '14px' : '16px'}>Domains</AppText>
            </Flex>
          </Flex>
          <Flex
            borderRadius={'2xl'}
            bgColor={'white'}
            p={smallScreen ? 2 : 3}
            gap={smallScreen ? 0 : 2}
            w={'100%'}
            h={'100%'}
          >
            <Icon as={iconHelper.linkIcon} boxSize={smallScreen ? '15px' : '22px'} />
            <Flex flexDir={'column'} width={'100%'}>
              <AppText
                variant={smallScreen ? 'p1' : 'h4'}
                fontWeight={'500'}
                ml={smallScreen ? 2 : 0}
              >
                {links}
              </AppText>
              <AppText fontSize={smallScreen ? '14px' : '16px'}>Links</AppText>
            </Flex>
          </Flex>
          <Flex
            borderRadius={'2xl'}
            bgColor={'white'}
            p={smallScreen ? 2 : 3}
            gap={smallScreen ? 0 : 2}
            w={'100%'}
            h={'100%'}
          >
            <Icon as={iconHelper.globeIcon} boxSize={smallScreen ? '15px' : '22px'} />
            <Flex flexDir={'column'} width={'100%'}>
              <AppText
                variant={smallScreen ? 'p1' : 'h4'}
                fontWeight={'500'}
                ml={smallScreen ? 2 : 0}
              >
                {errors}
              </AppText>
              <AppText fontSize={smallScreen ? '14px' : '16px'}>Errors</AppText>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
